import './PageHeader.scss'

import React, { useEffect, useState } from 'react'

import { BikemapLogo } from '../BikemapLogo'
import { Button } from '../Button'
import MainNavigation from './MainNavigation.jsx'
import { translated } from '../_translation'

/**
 * @typedef {Object} URLsConfig
 * @property {string} root
 * @property {string} login
 * @property {string} editor
 * @property {string} premium
 * @property {string} help
 * @property {string} logout
 * @property {string} apps
 * @property {string} regions
 */

/**
 * Page header with branding and main navigation.
 * @param {Object} props
 * @param {URLsConfig} props.urls
 * @param {boolean} [props.minimal] no navigation or links
 * @param {React.ReactNode} [props.children]
 * @param {boolean} [props.loggedIn]
 * @param {import("@bikemap/js/entities").User} [props.user]
 * @param {() => void} [props.onLoginClick]
 * @param {() => void} [props.onLogoutClick]
 * @param {() => void} [props.onCancel]
 * @param {() => void} [props.onHelp]
 * @param {() => void} [props.onNavOpen]
 * @param {() => void} [props.onNavClose]
 * @param {() => React.ComponentType} [props.renderStaffListGroup]
 * @param {() => React.ComponentType} [props.renderNewRouteActions]
 * @param {(HTMLElement) => void} [props.onInit]
 */
const PageHeader = ({
    gettext, urls, minimal, children, loggedIn, user, onLoginClick, onLogoutClick, onCancel, onHelp,
    renderStaffListGroup, onNavOpen, onNavClose, renderNewRouteActions, onInit,
}) => {
    const home = gettext('Home')
    const cancel = gettext('Cancel')
    const search = gettext('Find routes')
    const toggleNavText = gettext('Toggle main navigation')

    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        onInit && onInit()
    }, [])

    const openNav = () => {
        setIsOpen(true)
        onNavOpen()
    }

    const closeNav = () => {
        setIsOpen(false)
        onNavClose()
    }

    return (
        <div className="page-header">
            <a href={urls.root} className="page-header-home-link" title={home} data-testid="header-logo-link">
                <BikemapLogo />
            </a>
            {onCancel && (
                <>
                    <button className="page-header-back-btn" onClick={() => { onCancel() }}>
                        <i className="i-arrow-left i-lg" />
                    </button>
                    <Button className="page-header-cancel-btn" size="sm" onClick={() => { onCancel() }}>
                        {cancel}
                    </Button>
                </>
            )}
            {!minimal && !onCancel && (
                <>
                    <button className="page-header-toggle-btn" onClick={isOpen ? closeNav : openNav}>
                        <i className={isOpen ? 'i-menu-close' : 'i-menu'} title={toggleNavText} />
                    </button>
                    <a
                        href="#"
                        rel="nofollow"
                        className="page-header-search-link js-inject-web-app-url"
                        data-url-key="WEB_APP_DISCOVER_URL"
                        title={search}
                    >
                        <i className="i-search i-lg" />
                    </a>
                    {children && <div className="page-header-content">{children}</div>}
                    <MainNavigation urls={urls} open={isOpen} loggedIn={loggedIn} user={user}
                        onLoginClick={onLoginClick} onClose={closeNav} onHelp={onHelp}
                        renderStaffListGroup={renderStaffListGroup} onLogoutClick={onLogoutClick}
                        renderNewRouteActions={renderNewRouteActions}
                        onAfterOpenNewRouteDropdown={onInit} />
                </>
            )}
        </div>
    )
}

export default translated(PageHeader)
