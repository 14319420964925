const LAZY_LOADING_CLASS = 'lazyload'
const NATIVE_LAZY_LOADING_CLASS = 'native-lazyload'
const LAZY_LOADING_LIBRARY_PATH = 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.3.2/lazysizes.min.js'

/**
 * Initialize lazy loading with or without native support if images to lazy-load are present.
 */
export function initLazyLoading() {
    const lazyLoadImages = document.querySelectorAll('.' + LAZY_LOADING_CLASS)

    if (lazyLoadImages.length) {
        if (isLazyLoadingSupported()) {
            initNativeLazyLoading(lazyLoadImages)
        } else {
            initLazyLoadingLibrary()
        }
    }
}

/** @returns {boolean} */
function isLazyLoadingSupported() {
    return 'loading' in HTMLImageElement.prototype
}

/**
 * @param {NodeList} images Images to lazy-load
 */
function initNativeLazyLoading(images) {
    for (const img of images) {
        if (img.dataset.src) {
            img.src = img.dataset.src
        }
        if (img.dataset.srcset) {
            img.srcset = img.dataset.srcset
        }
        if (img.dataset.src || img.dataset.srcset) {
            img.classList.add(NATIVE_LAZY_LOADING_CLASS)
        }
    }
}

function initLazyLoadingLibrary() {
    // Load library from CDN via script tag
    const script = document.createElement('script')
    script.src = LAZY_LOADING_LIBRARY_PATH
    document.body.appendChild(script)
}
