import { UNIT_COOKIE } from './cookies'

export const LANGUAGE = document.documentElement.lang

const unitCookie = ('; ' + document.cookie).split('; ' + UNIT_COOKIE + '=').pop().split(';').shift()
export const UNIT = /** @type {"metric"|"imperial"} */ (unitCookie || 'metric')

export const WAYS_LAYER_SLUG = 'osm_cycling_routes'

export const WAYS_LAYER_NAME = 'OSM Cycling Routes'

export const HEATMAP_LAYER_SLUG = 'bikemap'

export const DEFAULT_PAGE_SIZE = 20

export const LANGUAGES = [
    { locale: 'en', label: 'English' },
    { locale: 'de', label: 'Deutsch' },
]
