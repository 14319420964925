import React from 'react'

import { AutocompleteField } from '@bikemap/components/AutocompleteField'
import { gettext } from '../services'

const t = {
    PLACEHOLDER: gettext('Search for routes in...'),
}

/**
 * Autocomplete field for searching locations.
 * @param {Object} props
 * @param {string} props.value
 * @param {(value: string) => void} props.onChange
 * @param {() => void} props.onReset
 * @param {(value: *, name?: string) => void} [props.onSelect]
 * @param {import("../../components/form/DropdownOptionItem").DropdownOptionValues[]} [props.options]
 * @param {boolean} [props.loading]
 * @param {string} [props.className]
 */
const SearchField = props => (
    <AutocompleteField {...props} label={t.PLACEHOLDER} name="location" icon="i-search"
        placeholder={t.PLACEHOLDER} autoFocus noLabel />
)

export default SearchField
