import React from 'react'
import { connect } from 'react-redux'

import { SearchField } from '../../components'
import { queryLocation, updateLocation, resetLocation } from './actions'
import { getGeonameOption } from './helpers'
import { WEB_APP_URLS } from '@bikemap/js/settings'
import { getWebAppParams } from '../global/web-app-links'

/**
 * Autocomplete field for searching locations.
 * @param {Object} props
 * @param {string} props.value Current geoname query string
 * @param {(value: string) => void} props.onChange Change handler for input value
 * @param {(suggestion: import("../../utility/network/autocomplete").GeonameSuggestion) => void} props.onSelect Select handler
 * @param {() => void} props.onReset Called when reset button is clicked
 * @param {string} [props.query] Query against the geoname autocomplete endpoint
 * @param {import("../../utility/network/autocomplete").GeonameSuggestion[]} props.suggestions
 */
const ConnectedLocationSearch = ({ value, onChange, onSelect, onReset, suggestions, query }) => {
    const searchFieldProps = {
        value,
        onChange,
        onReset,
        onSelect: geonameId => {
            for (const suggestion of suggestions) {
                if (suggestion.geonameId === geonameId) {
                    return onSelect(suggestion)
                }
            }
        },
        options: suggestions.map(suggestion => ({
            ...getGeonameOption(suggestion, query),
            href: WEB_APP_URLS.WEB_APP_REGION_URL.replace(':id', suggestion.geonameId) + getWebAppParams(),
        })),
        className: 'location-search',
    }

    return <SearchField {...searchFieldProps} />
}

/** @param {{geolocation: import("./reducer").GeolocationState}} state */
const mapStateToProps = ({ geolocation }) => {
    const { geonameQuery, geoname, geonameSuggestions } = geolocation

    return {
        value: geonameQuery || geoname || '',
        suggestions: geonameSuggestions,
        query: geonameQuery,
    }
}

const mapDispatchToProps = dispatch => ({
    onChange: value => dispatch(value ? queryLocation(value) : resetLocation()),
    onSelect: ({ geonameId, lng, lat }) => dispatch(
        updateLocation(geonameId, [lng, lat])
    ),
    onReset: () => dispatch(resetLocation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLocationSearch)
