import { insertFieldError } from './helpers'

/**
 * Returns a handler for the given form, which looks for error messages in the response
 * and adds them to the according fields.
 * @param {HTMLFormElement} form
 * @return {(value: any) => any} the form error handler
 */
export default function createFormErrorHandler(form) {
    return res => {
        // Reset form errors:
        form.querySelectorAll('.form-global-error, .form-field-error')
            .forEach(el => el.remove())
        form.querySelectorAll('.error')
            .forEach(el => el.classList.remove('error'))

        if (!res.success) {
            if (res.errors) {
                res.errors.forEach(error => {
                    const fieldName = error[0]
                    const message = error[1]
                    if (fieldName === '__all__') {
                        const globalError = document.createElement('p')
                        globalError.classList.add('form-global-error')
                        globalError.textContent = message
                        form.prepend(globalError)
                    } else {
                        /** @type {HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement} */
                        const field = form.querySelector('[name*="' + fieldName + '"]')
                        if (field && !field.hidden) {
                            field.classList.add('error')
                            insertFieldError(field, message)
                        }
                    }
                })
            }
        }

        return res
    }
}
