import ApiService from '../ApiService'
import { createPoiCategoryFromResponse } from '../factories'

class PoiCategoryApiService extends ApiService {

    constructor(endpoint) {
        super(endpoint)
        this.list = this.list.bind(this)
    }

    /**
     * Get all POI categories.
     * @returns {Promise<Object>} Index of POI categories
     */
    async list() {
        const res = await this.endpoint.get()
        const categories = {}
        res.forEach(data => {
            categories[data.id] = createPoiCategoryFromResponse(data)
        })
        return categories
    }

}

export default PoiCategoryApiService
