import context from '@bikemap/js/services/context'
import { getApiHeaders } from '@bikemap/js/utility'
import { CLIENT_ID, CLIENT_SECRET, API_BASE_URL } from '@bikemap/js/settings'

const { developmentAccess } = context.api()
const ACCESS_TOKEN_URL = API_BASE_URL + 'v4/oauth2/access_token'

/**
 * @typedef {Object} AccessTokenData
 * @property {string} accessToken
 * @property {number} expiresIn Time to live (TTL) in seconds
 * @property {string} scope
 * @property {string} tokenType
 * @property {string} refreshToken
 */

/**
 * Retrieve auth tokens.
 * @param {string} [refreshToken] Refresh token that should be used
 * @returns {Promise<AccessTokenData>}
 * @throws Error
 */
export async function fetchAccessToken(refreshToken) {
    const body = new FormData

    body.append('client_id', CLIENT_ID)
    body.append('client_secret', CLIENT_SECRET)

    if (refreshToken) {
        // A refresh token should be used to refresh an existing authentication
        body.append('grant_type', 'refresh_token')
        body.append('refresh_token', refreshToken)
    } else if (developmentAccess) {
        // When configured to call the development API with defined user credentials. This is meant for
        // development only.
        body.append('grant_type', 'password')
        body.append('username', developmentAccess.username)
        body.append('password', developmentAccess.password)
    } else {
        // When calling the API on the same host and logged in, we are already authenticated by the cookie.
        // This is the default behavior.
        body.append('grant_type', 'client_credentials')
    }

    const res = await fetch(ACCESS_TOKEN_URL, {
        method: 'POST',
        body,
        headers: getApiHeaders(),
        credentials: 'include',
    })

    if (!res.ok) {
        throw Error(res.statusText)
    }

    const data = await res.json()

    return formatAccessTokenFromResponse(data)
}

/**
 * Format access token data from object received from API endpoint.
 * @param {Object} data
 * @returns {AccessTokenData}
 */
export function formatAccessTokenFromResponse(data) {
    return {
        accessToken: data.access_token,
        expiresIn: data.expires_in,
        scope: data.scope,
        tokenType: data.token_type,
        refreshToken: data.refresh_token,
    }
}
