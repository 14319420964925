import React from 'react'
import { Button } from '../Button'
import { Dropdown, DropdownList, DropdownItem } from '../Dropdown'
import { translated } from '../_translation'

/**
 * Simple dropdown with links to route creation tools.
 * @param {Object} props
 * @param {() => React.ComponentType} [props.renderAdditionalActions]
 * @param {(HTMLElement) => void} [props.onAfterOpen]
 */
const NewRouteDropdown = ({ gettext, renderAdditionalActions, onAfterOpen }) => {
    const btnLabel = gettext('New Route')
    const planner = gettext('Plan a route')
    const upload = gettext('Upload GPX/KML file')

    const dropdownProps = {
        className: 'new-route-dropdown',
        renderTrigger: props => (
            <Button {...props} size="sm" width="narrower" data-testid="header-new-route-trigger">
                <span>{btnLabel}</span>
                <i className="i-caret-down" />
            </Button>
        ),
        renderList: () => (
            <DropdownList>
                {renderAdditionalActions && renderAdditionalActions()}
                <DropdownItem icon="i-directions">
                    <a
                        href="#"
                        rel="nofollow"
                        className="js-inject-web-app-url"
                        data-url-key="WEB_APP_PLAN_URL"
                        data-testid="header-new-route-planner"
                    >{planner}</a>
                </DropdownItem>
                <DropdownItem icon="i-upload">
                    <a
                        href="#"
                        rel="nofollow"
                        className="js-inject-web-app-url"
                        data-url-key="WEB_APP_IMPORT_URL"
                        data-testid="header-new-route-import"
                    >{upload}</a>
                </DropdownItem>
            </DropdownList>
        ),
        onAfterOpen,
    }

    return <Dropdown {...dropdownProps} />
}

export default translated(NewRouteDropdown)
