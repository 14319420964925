import 'core-js/stable'
import 'regenerator-runtime/runtime'

import initGlobal, { initPageContent, initPageFooter, initPageHeader } from '@bikemap/js/integrations/global'

/**
 * This is the most basic JS entry point, containing only global code.
 * Include it on pages without custom functionality.
 */

initGlobal()
initPageHeader()
initPageFooter()
initPageContent()
