import { defaultGet } from './helpers'
import { SEARCH_AUTOCOMPLETE_URL } from '../../settings'

/**
 * @typedef {Object} GeonameSuggestion
 * @property {string} longName Long name of the location
 * @property {string} [bounds] Bounds of the location as CSV
 * @property {number} lng Center lng
 * @property {number} lat Center lat
 * @property {number} geonameId Geoname ID of the location
 * @property {string} [parentLocation] Geoname that contains this location
 */

/**
 * Query the autocomplete endpoint.
 * @param {string} query Query string
 * @returns {Promise<GeonameSuggestion[]>} Array of suggestions
 */
export default async function autocomplete(query) {
    const res = await defaultGet(SEARCH_AUTOCOMPLETE_URL + '?q=' + query + '&type=geonames')
    return res.suggestions ? res.suggestions.map(({ value, data }) => ({
        longName: value,
        bounds: data.bounds,
        lng: data.lng,
        lat: data.lat,
        geonameId: data.geoname_id,
        parentLocation: data.location,
    })) : []
}
