import React from 'react'

import { LanguageField } from '@bikemap/components/LanguageField'
import { LANGUAGE, LANGUAGES } from '@bikemap/js/settings'
import { changeLanguage } from '.'

const LanguageSelect = () => (
    <LanguageField noLabel languages={LANGUAGES} value={LANGUAGE} onChange={changeLanguage} />
)

export default LanguageSelect
