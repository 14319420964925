import React from 'react'
import { render } from 'react-dom'
import { PasswordField } from '@bikemap/components/PasswordField'
import { TranslationProvider } from '@bikemap/components/_translation'
import { addInputHandler, logError } from '@bikemap/js/utility'
import { gettext } from '@bikemap/js/services'

export default class InitPasswordField {

    /** @type {HTMLFormElement} */
    form

    /** @type {string} Custom placeholder (when used in modal) */
    placeholder

    /** @type {object} */
    forbiddenWordsPerField = {}

    /** @type {object} Values of all PasswordField components */
    allPasswords = {}

    /** @type {boolean} Whether HTML required attribute should be rendered */
    required = true

    constructor(form, placeholder = '') {
        this.form = form

        if (placeholder && placeholder.length > 0) {
            this.placeholder = placeholder
        }

        this.onChange = this.onChange.bind(this)
        this.getAllPasswords = this.getAllPasswords.bind(this)
    }

    /**
     * Update object which hold all current passwords.
     * @param {Event} e onChange event of password form field
     * @param {import('@bikemap/components/PasswordField').PasswordReferer} referer
     */
    onChange(e, referer) {
        this.allPasswords = {
            ...this.allPasswords,
            ...{
                [referer]: e.target.value,
            },
        }
    }

    /**
     * Get current values of all password components.
     * Useful for comparing values of two different password fields (reset/reset-repeat).
     * @return {object}
     */
    getAllPasswords() {
        return this.allPasswords
    }

    /**
     * Takes existing forbidden words and attaches email/name words coming from django view.
     * @param {array} currentWords
     * @param {string=} email
     * @param {string=} name
     * @return {array}
     * @private
     */
    _attachForbiddenWordsFromView(currentWords, email = '', name = '') {
        try {
            const emailWords = (email && email.length > 0) ? email.split('@') : []
            const nameWords = (name && name.length > 0) ? name.split(' ') : []
            return [...currentWords, ...emailWords, ...nameWords]
        } catch (e) {
            return currentWords
        }
    }

    /**
     * Combine new forbidden values and return as a flat array.
     * @param {string} groupName Basically a field name (e.g. 'email')
     * @param {array} groupValues New values for this field (e.g. ['John', 'Doe'] for name field)
     * @return {array}
     * @private
     */
    _combineForbiddenWords(groupName, groupValues) {
        try {
            // Set new values for this field
            this.forbiddenWordsPerField = {
                ...this.forbiddenWordsPerField,
                [groupName]: groupValues,
            }
            return [].concat.apply([], Object.values(this.forbiddenWordsPerField).filter(v => !!v))
        } catch (e) {
            // No need to log errors, because these values might not be set yet
            return []
        }
    }

    /**
     * Listen for changes in provided field and re-render PasswordField component with new forbidden words.
     * @param {HTMLInputElement} field
     * @param {string} fieldName
     * @return {void|false}
     */
    addFieldListeners(field, fieldName) {
        if (!(field && fieldName)) {
            logError('Field listeners did not receive required properties in InitPasswordField class')
            return false
        }

        const updateWithFieldValue = () => {
            const value = field.value
            const splitValueAt = (fieldName === 'email' ? '@' : ' ')
            const forbiddenValues = value ? value.split(splitValueAt) : []
            const forbiddenWords = this._combineForbiddenWords(fieldName, forbiddenValues)
            this.render(forbiddenWords)
        }

        updateWithFieldValue() // Current value
        addInputHandler(field, updateWithFieldValue, false) // Future changes
    }

    /**
     * Load password component/s.
     * @param {array=} [forbiddenWords] Words now allowed in password
     */
    render(forbiddenWords = []) {
        if (!this.form) {
            logError('Form not set for InitPasswordField class')
            return false
        }
        const passwordElements = this.form.querySelectorAll('.js-component-password-field')
        if (!passwordElements) {
            return false
        }
        // Loop through all password fields (there are multiple password fields for reset/recover)
        passwordElements.forEach((passwordEl) => {
            const passwordProps = {
                referer: passwordEl.getAttribute('data-referer') || '',
                placeholder: this.placeholder || passwordEl.getAttribute('data-placeholder') || '',
                autocomplete: passwordEl.getAttribute('data-autocomplete') || '',
                autofocus: !!passwordEl.getAttribute('data-autofocus'),
                forbiddenWords: this._attachForbiddenWordsFromView(
                    forbiddenWords,
                    passwordEl.getAttribute('data-email') || '',
                    passwordEl.getAttribute('data-name') || ''
                ),
                onAfterChange: this.onChange,
                getAllPasswords: this.getAllPasswords,
                required: this.required,
            }

            // Load password component
            render(
                (
                    <TranslationProvider translate={gettext}>
                        <PasswordField {...passwordProps} />
                    </TranslationProvider>
                ),
                passwordEl
            )
        })
    }

}
