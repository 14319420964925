import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { logError, Storage, STORAGE_KEY_NATIVE_APP_INSTALL_BANNER_HIDDEN } from '@bikemap/js/utility'
import { addUserInteractionEventListener, removeUserInteractionEventListener } from '@bikemap/js/utility/events'
import { AndroidBanner } from '@bikemap/components/AndroidBanner'
import { TranslationProvider } from '@bikemap/components/_translation'
import { gettext } from '@bikemap/js/services'

/**
 * Will hold BeforeInstallPromptEvent, so it can be triggered when "Install" button is clicked.
 * @link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
 */
let beforeInstallPromptEvent = null

/**
 * Show banner with links to install our Android app.
 * For iOS, this works based on "apple-itunes-app" meta tag.
 * @link https://developer.chrome.com/blog/app-install-banners-native/
 */
export function initNativeAppInstallPrompt() {
    const container = document.getElementById('native-app-banner')
    if (container) {
        window.addEventListener('beforeinstallprompt', (e) => {
            if (e.platforms && e.platforms.includes('play')) {
                // Prevent Chrome 67 and earlier from automatically showing the prompt
                e.preventDefault()

                // Preserve prompt event
                beforeInstallPromptEvent = e

                initBanner(container)
            }
        })
    }
}

/**
 * Show banner on user interaction when the page has been scrolled down already.
 * @param {HTMLElement} container
 */
function initBanner(container) {
    const bannerTrigger = () => {
        if (document.documentElement.scrollTop > 100) {
            showBanner(container)
            removeUserInteractionEventListener(bannerTrigger)
        }
    }

    addUserInteractionEventListener(bannerTrigger)
}

/**
 * Wait for user to respond to install prompt.
 * Wrapped in try/catch because of low browser support.
 * @returns {Promise<boolean>} true if app is installed, false if dismissed
 */
const handleInstallPrompt = () => new Promise((resolve) => {
    try {
        if (beforeInstallPromptEvent) {
            // Show the prompt
            beforeInstallPromptEvent.prompt()

            // Wait for the user to respond to the prompt
            beforeInstallPromptEvent.userChoice.then((choiceResult) => {
                const isAccepted = choiceResult.outcome === 'accepted'
                beforeInstallPromptEvent = null
                resolve(isAccepted)
            })
        } else {
            logError('Native app install prompt not set')
            resolve(false)
        }
    } catch (e) {
        logError('Could not prompt native app install', e)
        resolve(false)
    }
})

/**
 * Show banner if not previously shown.
 * @param {HTMLElement} container
 */
const showBanner = (container) => {
    const wasShown = Storage.getItem(STORAGE_KEY_NATIVE_APP_INSTALL_BANNER_HIDDEN, true)
    if (container && !wasShown) {
        render((
            <TranslationProvider translate={gettext}>
                <AndroidBanner
                    linkUrl={
                        'https://play.google.com/store/apps/details' +
                        '?id=com.toursprung.bikemap' +
                        '&referrer=utm_source%3Dwebsite%26utm_medium%3Dapp_banner%26utm_campaign%3Dapp_banner_android'
                    }
                    onCtaClick={async () => {
                        const isInstallAccepted = await handleInstallPrompt()
                        if (isInstallAccepted) {
                            hideBanner(container)
                        }
                    }}
                    onClose={() => {
                        hideBanner(container)
                    }}
                />
            </TranslationProvider>
        ), container)
    }
}

/**
 * Hide banner and update local storage to prevent showing it again.
 * @param {HTMLElement} container
 */
const hideBanner = (container) => {
    if (container) {
        unmountComponentAtNode(container)
    }
    Storage.setWithTimestamp(STORAGE_KEY_NATIVE_APP_INSTALL_BANNER_HIDDEN, undefined, true)
}
