import styles from './CookieBanner.module.scss'

import React, { useState } from 'react'

import { translated } from '../_translation'
import { MinimalButton } from '../MinimalButton'
import { CheckboxField } from '../CheckboxField'
import { Button } from '../Button'

/**
 * @typedef {'en'|'de'} AvailableLanguages
 */

/**
 * @typedef {Object} CookiePreferences
 * @property {boolean} statistics
 */

/**
 * @param {Object} props
 * @param {AvailableLanguages} props.language
 * @param {(locale: AvailableLanguages) => void} props.onLanguageSelect
 * @param {(settings: CookiePreferences) => void} props.onSubmit
 * @param {CookiePreferences} [props.initialPreferences]
 */
const CookieBanner = ({
    gettext, language, onLanguageSelect, onSubmit, initialPreferences,
}) => {
    const heading = gettext('Privacy Controls')

    const explanationParagraphs = [
        gettext(
            'Bikemap values its users privacy, and therefore request your permission to use Cookies to process personal ' +
            'data like IP-address or browser information in order to personalise the advertisement you see. Cookies are ' +
            "small text files that can be used by websites to make a user's experience more efficient."
        ),
        gettext(
            'This site uses different types of cookies. Some cookies are placed by third party services that appear ' +
            'on our pages. These technologies may access your device and help us to show you more relevant ads and ' +
            'improve your internet experience. We also use it in order to measure results or align our website content.'
        ),
        gettext(
            'According to the GDPR and the EDBP Guidelines, we can store cookies on your device if they are strictly ' +
            'necessary for the operation of this site. You can at any time change or withdraw your consent from the Cookie ' +
            'Declaration on our website by clicking on "Privacy Controls" at the bottom of the page.'
        ),
        gettext(
            'If you have any questions regarding our cookies, you can contact ' +
            '<a href="mailto:privacy@bikemap.net">privacy@bikemap.net</a> stating your consent ID and date.'
        ),
    ]

    const necessaryLabel = gettext('Necessary')
    const requiredAddition = gettext('(required)')
    const statisticsLabel = gettext('Statistics')

    const learnMoreParagraph = gettext(
        'Learn more about who we are, how you can contact us, how we process your data, and detailed information on ' +
        'all our cookies by visiting our <a href="https://www.bikemap.net/en/dataprivacy/">Privacy Policy</a> and our ' +
        '<a href="https://www.bikemap.net/en/cookies/">Cookie Declaration</a>.'
    )

    const saveSelectionLabel = gettext('Save selection')
    const acceptAllLabel = gettext('Accept all')

    const [statistics, setStatistics] = useState(initialPreferences ? initialPreferences.statistics : false)

    const handleSaveSelection = () => {
        onSubmit({ statistics })
    }

    const handleAcceptAll = () => {
        onSubmit({ statistics: true })
    }

    return (
        <div className={styles.root}>
            <h4>{heading}</h4>

            <div className={styles.language}>
                <MinimalButton align onClick={() => { onLanguageSelect('en') }} disabled={language === 'en'}>
                    EN
                </MinimalButton>
                {' | '}
                <MinimalButton align onClick={() => { onLanguageSelect('de') }} disabled={language === 'de'}>
                    DE
                </MinimalButton>
            </div>

            <div className={styles.explanation}>
                {explanationParagraphs.map((paragraph, i) => <p key={i} dangerouslySetInnerHTML={{ __html: paragraph }} />)}
            </div>

            <div className={styles.checkboxes}>
                <CheckboxField name='necessary' onChange={() => {}} value={true} disabled>
                    {necessaryLabel} <span className={styles.requiredAddition}>{requiredAddition}</span>
                </CheckboxField>
                <br />
                <CheckboxField name='statistics' onChange={setStatistics} value={statistics}>
                    {statisticsLabel}
                </CheckboxField>
            </div>

            <p className={styles.learnMoreParagraph} dangerouslySetInnerHTML={{ __html: learnMoreParagraph }} />

            <div className={styles.buttons}>
                <Button
                    variant={statistics ? 'primary' : 'default'}
                    onClick={handleSaveSelection}
                    data-testid="cookie-banner-save-selection-button"
                >
                    {saveSelectionLabel}
                </Button>
                <Button
                    variant={statistics ? 'default' : 'primary'}
                    onClick={handleAcceptAll}
                    data-testid="cookie-banner-accept-all-button"
                >
                    {acceptAllLabel}
                </Button>
            </div>
        </div>
    )
}

export default translated(CookieBanner)
