import React from 'react'
import { formatAnalyticsData } from '../_common/helpers'

import './Button.scss'

/**
 * @param {Object} props
 * @param {"default"|"primary"|"secondary"|"secondary-danger"|"premium"|"ghost"|"google"|"facebook"|"apple"|"success"|"danger"} [props.variant="default"]
 * @param {"sm"|"action"|"lg"} [props.size]
 * @param {boolean} [props.active]
 * @param {boolean} [props.sublime]
 * @param {boolean} [props.inverse]
 * @param {"wider"|"narrower"|"block"} [props.width]
 * @param {boolean} [props.disabled]
 * @param {string} [props.className]
 * @param {React.ReactNode} [props.children]
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick]
 * @param {string} [props.href]
 * @param {Object} [props.analytics] Analytics properties to attach to DOM element
 * @param {string} [props.tooltip]
 * @param {"top-left"|"top-right"|"left"|"right"} [props.tooltipPosition="top-left"]
 * @param {...*} props All other default <a> or <button> props
 */
const Button = ({
    variant,
    size,
    active,
    sublime,
    inverse,
    disabled,
    className,
    children,
    onClick,
    width,
    href,
    analytics,
    tooltip,
    tooltipPosition = 'top-left',
    ...props
}) => {
    let classes = 'btn btn-' + (variant || 'default')
    if (size) classes += ' btn-' + size
    if (active) classes += ' active'
    if (sublime) classes += ' btn-sublime'
    if (inverse) classes += ' btn-inverse'
    if (width) classes += ' btn-' + width
    if (disabled) classes += ' disabled'
    if (className) classes += ' ' + className
    if (tooltip) classes += ' bm-tooltip-' + tooltipPosition

    const elementProps = {
        className: classes,
        onClick: !active ? onClick : undefined,
        disabled,
        children,
        ...(formatAnalyticsData(analytics)),
        ...props,
    }

    if (tooltip) {
        elementProps['data-tooltip'] = tooltip
    }

    return href ? <a href={!disabled ? href : undefined} {...elementProps} /> : <button {...elementProps} />
}

export default Button
