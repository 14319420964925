import './AutocompleteField.scss'

import React, { useState } from 'react'

import { DropdownList, DropdownOptionItem } from '../Dropdown'
import { InputField } from '../InputField'

/**
 * Input showing autocomplete options to select.
 * @param {Object} props
 * @param {string} [props.className] Additional classes
 * @param {import("../DropdownList").DropdownOptionValues[]} [props.options]
 * @param {string} props.value Current value
 * @param {string} props.label
 * @param {string} [props.name] Name of the form element
 * @param {(value: string, name?: string) => void} props.onChange Change handler for the input
 * @param {(value: *, name?: string) => void} [props.onSelect] Called when an option is selected
 * @param {string} [props.icon] Name of an icon to insert into the input
 * @param {string} [props.placeholder] Placeholder for the input
 * @param {boolean} [props.autoFocus] Autofocus attribute
 * @param {() => void} [props.onReset]
 * @param {boolean} [props.loading]
 * @param {boolean} [props.noLabel]
 */
const AutocompleteField = ({
    className, options, value, label, name, onChange, onSelect, icon, placeholder, autoFocus, onReset,
    loading, noLabel,
}) => {
    const [focusedOption, setFocusedOption] = useState(null)

    let classes = 'autocomplete-field'
    if (className) classes += ' ' + className
    if (loading) classes += ' loading-spinner'

    const handleClick = value => {
        onSelect(value, name)
    }

    const selectFirstOption = () => {
        if (options[0]) {
            const { value, href } = options[0]
            if (onSelect) {
                onSelect(value)
            } else {
                location.href = href
            }
        }
    }

    /**
     * @param {React.KeyboardEvent} e
     */
    const handleKeyDown = e => {
        const focusOption = index => {
            setFocusedOption(index)
            e.preventDefault()
        }

        if (e.key === 'ArrowDown') {
            focusOption(focusedOption !== null ? (focusedOption + 1) % options.length : 0)
        } else if (e.key === 'ArrowUp') {
            focusOption(focusedOption ? focusedOption - 1 : options.length - 1)
        } else if (e.key === 'Escape') {
            onReset ? onReset() : onChange('', name)
        } else if (
            e.key === 'Enter' &&
            focusedOption === null &&
            !loading
        ) {
            selectFirstOption()
        }
    }

    /**
     * @param {number} index Index of the option
     */
    const handleOptionBlur = index => {
        if (index === focusedOption) {
            // Reason to lose focus is not this component
            setFocusedOption(null) // Prevent refocus on the option
        }
    }

    const inputProps = {
        className: classes,
        value,
        label,
        name,
        icon,
        placeholder,
        autoFocus,
        onChange,
        onReset,
        onKeyDown: handleKeyDown,
        autoComplete: 'off',
        noLabel,
        loading,
    }

    return (
        <InputField {...inputProps}>
            {!!options.length && (
                <DropdownList>
                    {options.map((option, i) => (
                        <DropdownOptionItem key={i} {...option} onClick={onSelect && handleClick} childRef={el => i === focusedOption && el && el.focus()}
                            onKeyDown={handleKeyDown} onBlur={() => handleOptionBlur(i)} />
                    ))}
                </DropdownList>
            )}
        </InputField>
    )
}

export default AutocompleteField
