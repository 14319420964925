import './MainNavigation.scss'

import React from 'react'

import { Button } from '../Button'
import NewRouteDropdown from './NewRouteDropdown.jsx'
import UserMenuDropdown from './UserMenuDropdown.jsx'
import { translated } from '../_translation'

/**
 * @typedef {Object} URLsConfig
 * @property {string} login
 * @property {string} premiumCta
 * @property {string} premiumMenuItem
 * @property {string} help
 * @property {string} logout
 * @property {string} apps
 * @property {string} regions
 */

/**
 * Navigation inside the page header.
 * @param {Object} props
 * @param {URLsConfig} props.urls
 * @param {boolean} [props.open]
 * @param {boolean} [props.loggedIn] Nav is for authenticated user, even if there is no user prop yet
 * @param {import("@bikemap/js/entities").User} [props.user]
 * @param {() => void} [props.onLoginClick]
 * @param {() => void} [props.onLogoutClick]
 * @param {() => void} props.onClose
 * @param {() => void} [props.onHelp]
 * @param {() => React.ComponentType} [props.renderStaffListGroup]
 * @param {() => React.ComponentType} [props.renderNewRouteActions]
 * @param {(HTMLElement) => void} [props.onAfterOpenNewRouteDropdown]
 */
const MainNavigation = ({
    gettext, urls, open, loggedIn, user, onLoginClick, onLogoutClick, onClose, onHelp, renderStaffListGroup,
    renderNewRouteActions, onAfterOpenNewRouteDropdown,
}) => {
    const login = gettext('Sign up or Log in')
    const premium = gettext('Go Premium')
    const routes = gettext('Routes')
    const apps = gettext('Apps')
    const regions = gettext('Regions')

    const handleLoginClick = e => {
        if (onLoginClick) {
            e.preventDefault()
            onLoginClick()
        }
    }

    const menuItemUrls = {
        premium: urls.premiumMenuItem,
        help: urls.help,
        logout: urls.logout,
    }

    return (
        <>
            {open && <div className="main-navigation-backdrop" onClick={() => { onClose() }} />}
            <nav className={'main-navigation' + (open ? ' open' : '')}>
                {loggedIn ? (
                    <>
                        {user && !user.isPremium && (
                            <Button href={urls.premiumCta} variant="premium" size="sm" data-testid="main-nav-premium">
                                {premium}
                            </Button>
                        )}
                        <UserMenuDropdown urls={menuItemUrls} user={user} onHelp={onHelp}
                            renderStaffListGroup={renderStaffListGroup} onLogoutClick={onLogoutClick} />
                    </>
                ) : (
                    <Button href={urls.login} variant="primary" size="sm" onClick={handleLoginClick} data-testid="main-nav-login">
                        {login}
                    </Button>
                )}
                <NewRouteDropdown
                    renderAdditionalActions={renderNewRouteActions}
                    onAfterOpen={onAfterOpenNewRouteDropdown}
                />
                <a
                    href="#"
                    rel="nofollow"
                    className="main-navigation-link js-inject-web-app-url"
                    data-url-key="WEB_APP_DISCOVER_URL"
                    data-testid="header-link-discover"
                >
                    {routes}
                </a>
                <a href={urls.apps} className="main-navigation-link">
                    {apps}
                </a>
                <a href={urls.regions} className="main-navigation-link">
                    {regions}
                </a>
            </nav>
        </>
    )
}

export default translated(MainNavigation)
