import { Storage, STORAGE_KEY_ACCESS_TOKEN_DATA } from '@bikemap/js/utility/Storage'

/**
 * Try to find valid token data from session storage.
 * @param {import('@bikemap/js/api/auth-token').ExtendedAccessTokenData} tokenData
 * @returns {void}
 */
export function setStoredAccessToken(tokenData) {
    Storage.setItem(STORAGE_KEY_ACCESS_TOKEN_DATA, JSON.stringify(tokenData), true)
}

/**
 * Get token data from session storage.
 * @returns {import('@bikemap/js/api/auth-token').ExtendedAccessTokenData|null}
 */
export function getStoredAccessToken() {
    const storedToken = JSON.parse(Storage.getItem(STORAGE_KEY_ACCESS_TOKEN_DATA, true))
    return storedToken || null
}

export function removeStoredAccessToken() {
    Storage.removeItem(STORAGE_KEY_ACCESS_TOKEN_DATA, true)
}
