/**
 * Represents a real-time POI category.
 */
class PoiCategory {

    /** @type {number} */
    id

    /** @type {number} */
    parent

    /** @type {string} */
    icon

    /** @type {string} */
    color

    /** @type {string} */
    name

    /** @type {import("moment").Duration} */
    ttl

    /** @type {string} */
    identifier

}

export default PoiCategory
