import React from 'react'

import { SelectDropdownField } from '@bikemap/components/SelectDropdownField'
import { gettext } from '@bikemap/js/services'
import { UNIT } from '@bikemap/js/settings'
import { changeUnit } from '../preferences'

const options = [
    { value: 'metric', label: gettext('Meters / km') },
    { value: 'imperial', label: gettext('Feet / miles') },
]

const UnitSelect = () => {
    return <SelectDropdownField name="unit" label={gettext('Unit')} noLabel options={options} value={UNIT} onChange={changeUnit} />
}

export default UnitSelect
