import userService from '@bikemap/js/services/user'
import sessionDataService from '@bikemap/js/services/sessionData'
import { logError } from '@bikemap/js/utility'
import { gettext, notifications } from '@bikemap/js/services'

/**
 * Initialize and activate the support chat.
 */
export async function initSupportChat() {
    try {
        const [isPremium, { zendesk }] = await Promise.all([
            userService.getIsPremium(),
            sessionDataService.getSessionData(),
        ])

        if (isPremium && zendesk) {
            if (!isZendeskInitialized()) {
                const range = document.createRange()
                range.selectNode(document.body)
                const documentFragment = range.createContextualFragment(zendesk)
                document.body.appendChild(documentFragment)
            }

            activateZendeskWhenInitialized()
        } else {
            throw Error('Zendesk not available')
        }
    } catch (error) {
        logError('Support chat could not be initialized', error)
    }
}

let waitingCounter = 0

function activateZendeskWhenInitialized() {
    if (isZendeskInitialized()) {
        window.zE.activate()
    } else {
        if (++waitingCounter < 100) {
            setTimeout(activateZendeskWhenInitialized, 100)
        } else {
            notifications.error(gettext(
                'An error occurred while loading the support chat. Please contact ' +
                '<a href="mailto:support@bikemap.net">support@bikemap.net</a>.'
            ))
            throw Error('Timeout waiting for Zendesk to initialize')
        }
    }
}

/**
 * @returns {boolean}
 */
function isZendeskInitialized() {
    return window.zE && window.zE.activate
}
