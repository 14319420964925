import { logError } from '@bikemap/js/utility/helpers'
import ApiService from '../ApiService'

/**
 * @typedef {Object} MapStyle
 * @property {string} name
 * @property {string} thumbnail
 * @property {boolean} isPremium
 * @property {boolean} isDefault
 * @property {string} url
 */

class MapStyleApiService extends ApiService {

    /**
     * Get the list of available map styles.
     * @returns {Promise<MapStyle[]>}
     */
    async list() {
        try {
            const res = await this.endpoint.get({
                queryParams: { is_inhoused: 'true' },
            })

            return res.map(mapStyle => {
                const { name, thumbnail, style } = mapStyle
                return {
                    name,
                    thumbnail,
                    isPremium: mapStyle.is_premium,
                    isDefault: mapStyle.is_default,
                    url: style,
                }
            })
        } catch (e) {
            logError('Problem fetching map styles', e)
        }
    }

}

export default MapStyleApiService
