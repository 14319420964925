import './LanguageField.scss'

import React from 'react'

import { SelectDropdownField } from '../SelectDropdownField'
import { translated } from '../_translation'
import FlagDe from './FlagDe.jsx'
import FlagUK from './FlagUK.jsx'

/**
 * @typedef {Object} LanguageOptionValues
 * @property {string} locale
 * @property {React.ReactNode} label
 */

/**
 * @param {Object} props
 * @param {LanguageOptionValues[]} props.languages
 * @param {string} props.value
 * @param {string} [props.name="language"]
 * @param {(value: string, name: string) => void} props.onChange
 * @param {boolean} [props.noLabel]
 */
const LanguageField = ({
    gettext, value, name, onChange, noLabel, languages,
}) => {
    const props = {
        className: 'language-field',
        name: name || 'language',
        value,
        label: gettext('Language'),
        noLabel,
        onChange: (value, name) => {
            onChange(/** @type {string} */(value), name)
        },
        options: languages.map(({ locale, label }) => ({
            value: locale,
            label: <>{(locale === 'de') ? <FlagDe /> : <FlagUK />} {label}</>,
        })),
    }

    return <SelectDropdownField {...props} />
}

export default translated(LanguageField)
