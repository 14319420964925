import { close } from './modalManager'
import { renderIcon, renderCloseButton } from '../../utility'

// Quick helper
function el(type, className) {
    const element = document.createElement(type)
    element.classList.add(className)
    return element
}

export default class Modal {

    // At time of coding, class properties are not yet enabled:
    // options
    // node
    // id
    // optionsChecksum
    // header
    // body
    // footer
    // content
    // dialog
    // loading
    // onSubmit
    // onChange
    // onShow
    // onClose
    // focusSelector
    // referrer

    constructor(id, options) {
        this.id = id
        this.options = Object.assign({}, options)

        this.initNode()
        this.initEventHandlers()

        this.focusSelector = options.focus
        this.referrer = options.referrer

        if (options.init) options.init(this)
    }

    patch(options) {
        // TODO: This method does not support all options. If you need to patch another option,
        // please add the necessary code!
        let updateHeader = false
        for (const i in options) {
            if (options[i] !== this.options[i]) {
                this.options[i] = options[i]
                switch (i) {
                    case 'header':
                    case 'title':
                    case 'lead':
                        updateHeader = true
                        break
                    case 'body':
                        this.body.innerHTML = options[i]
                        break
                    default:
                        this[i] = options[i]
                        break
                }
            }
        }
        if (updateHeader) this.renderHeader()
    }

    show() {
        if (typeof this.onShow === 'function') {
            this.onShow(new CustomEvent('modal.show'), this)
        }

        this.node.classList.remove('hidden')
        this.node.setAttribute('aria-hidden', 'false')

        // Set focus to a contained element, if a selector is given
        const focusElement = this.node.querySelector(this.focusSelector)
        if (focusElement) {
            // Focus after CSS animation is done
            setTimeout(() => {
                focusElement.focus()
            }, 300)
        } else {
            this.node.focus()
        }
    }

    close() {
        this.node.classList.add('hidden')
        this.node.setAttribute('aria-hidden', 'true')

        if (typeof this.onClose === 'function') {
            this.onClose(new CustomEvent('modal.close'), this)
        }
    }

    setLoading(loading = true) {
        if (loading && !this.loading) {
            this.content.classList.add('loading-spinner')
        } else if (!loading && this.loading) {
            this.content.classList.remove('loading-spinner')
        }
        this.loading = loading
    }

    handleSubmit(e) {
        if (typeof this.onSubmit === 'function') {
            e.preventDefault()
            this.onSubmit(e, this)
        }
    }

    handleChange(e) {
        if (typeof this.onChange === 'function') {
            this.onChange(e, this)
        }
    }

    initNode() {
        const { form, className, fullscreen } = this.options
        this.node = el('div', 'modal')
        if (className) {
            this.node.classList.add(className)
        }
        this.node.id = 'modal-' + this.id
        this.node.setAttribute('tabindex', -1)
        this.node.setAttribute('role', 'dialog')
        this.dialog = el('div', 'modal-dialog')
        this.dialog.setAttribute('role', 'document')
        if (fullscreen) {
            this.dialog.classList.add('fullscreen')
        }
        this.node.append(this.dialog)
        this.content = el((form !== false) ? 'form' : 'div', 'modal-content')
        if (form) {
            this.content.action = form.action || ''
            this.content.method = form.method || 'post'
        }
        this.dialog.append(this.content)
        this.header = el('div', 'modal-header')
        this.content.append(this.header)
        this.body = el('div', 'modal-body')
        this.content.append(this.body)
        this.footer = el('div', 'modal-footer')
        this.content.append(this.footer)

        this.renderHeader()
        this.renderBody()
        this.renderFooter()
    }

    initEventHandlers() {
        const { onSubmit, onChange, onShow, onClose, noClose } = this.options
        if (!noClose) {
            this.node.addEventListener('click', e => {
                if (!this.loading && (e.target === this.node || e.target === this.dialog)) {
                    close()
                }
            })
        }
        this.content.addEventListener('submit', this.handleSubmit.bind(this))
        this.onSubmit = onSubmit
        this.content.addEventListener('change', this.handleChange.bind(this))
        this.onChange = onChange
        this.onShow = onShow
        this.onClose = onClose
    }

    renderHeader() {
        const { icon, title, titleClasses, lead, noClose, header } = this.options
        let content = header || ''
        if (icon) {
            if (icon.startsWith('#')) {
                content += '<svg class="modal-icon"><use xlink:href="' + icon + '"></use></svg>'
            } else {
                content += '<i class="i-' + icon + ' text-primary modal-icon"></i>'
            }
        }
        if (title) {
            const titleId = 'modal-' + this.id + '-title'
            const titleCls = titleClasses || 'modal-title'
            const titleData = 'data-testid="modal-title" data-modal-id="' + this.id + '"'
            content += '<h2 id="' + titleId + '" class="' + titleCls + '" ' + titleData + '>' + title + '</h2>'
            this.node.setAttribute('aria-labelledby', titleId)
        }
        if (lead) {
            content += '<p class="modal-lead">' + lead + '</p>'
        }
        this.header.innerHTML = content

        if (!noClose) {
            const closeBtn = renderCloseButton()
            closeBtn.addEventListener('click', close)
            this.header.prepend(closeBtn)
        }
    }

    renderBody() {
        const { body, message } = this.options
        if (body) {
            if (typeof body === 'string') {
                this.body.innerHTML = body
            } else {
                this.body.innerHTML = ''
                this.body.append(body)
            }
        } else if (message) {
            this.body.innerHTML = '<p>' + message + '</p>'
        }
    }

    renderFooter() {
        const { footer, buttons } = this.options
        if (footer) {
            if (typeof footer === 'string') {
                this.footer.innerHTML = footer
            } else {
                this.footer.innerHTML = ''
                this.footer.append(footer)
            }
        }

        if (buttons && buttons.length) {
            for (let i = 0; i < buttons.length; i++) {
                const { content, style, type, action, className, icon, href } = buttons[i]

                /** @type {HTMLAnchorElement|HTMLButtonElement} */
                const btn = document.createElement((href) ? 'a' : 'button')
                btn.setAttribute('class', className || '')
                btn.classList.add('btn', 'btn-' + (style || 'default'))

                if (href) {
                    btn.setAttribute('href', href)
                } else {
                    const typeAttr = (!type && action === 'submit') ? 'submit' : type
                    btn.setAttribute('type', typeAttr || 'button')
                }

                btn.innerHTML = icon ? '<span>' + content + '</span>' : content

                if (icon) {
                    btn.prepend(renderIcon(icon))
                }

                switch (action) {
                    case 'close':
                        btn.addEventListener('click', close)
                        break
                    default:
                        if (typeof action === 'function') {
                            btn.addEventListener('click', e => { action(e, this) })
                        }
                        break
                }

                this.footer.append(btn)
            }
        }
    }

}
