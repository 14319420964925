import { call, put, debounce } from 'redux-saga/effects'

import { updateGeonameSuggestions, QUERY_LOCATION } from './actions'
import { AUTOCOMPLETE_DEBOUNCE_TIME } from '../../settings'
import { autocomplete } from '../../utility'

/**
 * Fetch new autocomplete suggestions for location field.
 * @param {Object} action
 */
export function* locationAutocompleteSaga({ geonameQuery }) {
    if (geonameQuery.length < 3) {
        yield put(updateGeonameSuggestions([]))
    } else {
        try {
            const res = yield call(autocomplete, geonameQuery)
            yield put(updateGeonameSuggestions(res))
        } catch (error) {
            yield call(console.error, error)
        }
    }
}

/**
 * Saga for geolocation functionality.
 */
export function* geolocationSaga() {
    // Handle changes in location input
    yield debounce(AUTOCOMPLETE_DEBOUNCE_TIME, QUERY_LOCATION, locationAutocompleteSaga)
}
