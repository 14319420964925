import React from 'react'

import { CookieBanner } from '@bikemap/components/CookieBanner'
import { LANGUAGE } from '@bikemap/js/settings'
import { changeLanguage } from '@bikemap/js/integrations/language'
import { getCookiePreferences, saveCookiePreferences } from './preferences'

/**
 * @param {Object} props
 * @param {() => void} props.onClose
 */
const ConnectedCookieBanner = ({ onClose }) => {
    const props = {
        language: LANGUAGE,
        onLanguageSelect: changeLanguage,
        onSubmit: preferences => {
            saveCookiePreferences(preferences)
            onClose()
        },
        initialPreferences: getCookiePreferences(),
    }
    return <CookieBanner {...props} />
}

export default ConnectedCookieBanner
