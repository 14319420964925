import { showLoginModal } from './modals'

/**
 * Set a click handler to all links with href="#log-in" that opens the login modal.
 * @param {HTMLElement} [container] optional DOM node to search inside
 */
export function setLoginClickHandler(container) {
    const loginLinks = (container || document).querySelectorAll('a[href="#log-in"]')
    for (let i = 0; i < loginLinks.length; i++) {
        loginLinks[i].addEventListener('click', e => {
            e.preventDefault()
            showLoginModal()
        })
    }
}

/**
 * Show a login modal immediately if the current page URL includes the hash "log-in".
 */
export function triggerLoginViaUrlHash() {
    if (window.location.hash.indexOf('#log-in') !== -1) {
        showLoginModal()
    }
}
