import React from 'react'
import { render } from 'react-dom'

import { TranslationProvider } from '@bikemap/components/_translation'
import { gettext } from '@bikemap/js/services'

import LanguageSelect from '@bikemap/js/integrations/language/LanguageSelect.jsx'
import UnitSelect from './components/UnitSelect.jsx'

/**
 * Initialize the full page footer with site settings.
 */
export function initPageFooter() {
    const settingsColumn = document.getElementById('site-settings-column')

    if (settingsColumn) {
        render((
            <TranslationProvider translate={gettext}>
                <LanguageSelect />
                <UnitSelect />
            </TranslationProvider>
        ), settingsColumn)
    }
}
