import moment from 'moment'

class RouteStatisticsWeek {

    /** @type {moment.Moment} */
    start

    /** @type {import("js-quantities")[]} */
    distances = new Array(7)

    /** @type {moment.Duration} */
    totalTime = moment.duration(0)

    /** @type {import("js-quantities")} */
    totalDistance

    /** @type {import("js-quantities")} */
    totalAscent

    /** @type {import("js-quantities")} */
    totalDescent

    /**
     * @param {moment.Moment} start Start date of the week
     */
    constructor(start) {
        this.start = start
    }

}

export default RouteStatisticsWeek
