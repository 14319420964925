import Qty from 'js-quantities'
import moment from 'moment'

import { Route } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {Route}
 */
export default function createRouteFromResponse(res) {
    const route = new Route

    route.id = res.id
    if (res.created) {
        route.created = moment(res.created)
    }
    route.title = res.title
    route.staticmap = res.staticmap
    if (res.distance) {
        route.distance = Qty(res.distance, 'm')
    }
    if (res.altitude_difference) {
        route.ascent = Qty(res.altitude_difference, 'm')
    }
    if (res.altitude_difference_back) {
        route.descent = Qty(res.altitude_difference_back, 'm')
    }
    route.isFavorite = res.is_favorite
    route.favoriteCount = res.route_favorite_count
    route.location = res.location
    route.category = res.category
    route.ground = res.ground
    route.images = res.route_images
    if (res.user) {
        route.user = res.user.id
    }
    if (res.start) {
        route.start = res.start.coordinates
    }
    route.rating = res.rating
    route.views = res.views

    // Details
    route.points = res.points

    return route
}
