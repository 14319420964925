import cloneDeep from 'lodash-es/cloneDeep'

/**
 * Context object with readonly properties.
 * @param {string} [data] Source context data
 */
function Context(data) {
    if (!data) return
    const json = Buffer.from(data, 'base64')
    const contextObject = JSON.parse(json)
    Object.assign(this, contextObject)
}

// @ts-ignore
const globalContext = window.bmContext

/**
 * Get a copy of a context entry.
 * @param {Context} context Context data object
 * @param {string} key Key of context entry
 */
const provideContext = (context, key) => cloneDeep(key ? context[key] : context)

/**
 * Provides context data injected via the HTML.
 */
export default {

    /**
     * Get an API base URL by endpoint name or a copy of the whole global API context,
     * if no key is provided.
     * @param {string} [key] Key of context entry
     */
    api: key => provideContext(new Context(globalContext.api), key),

    /**
     * Deprecated! Please use local constants (settings) instead.
     * Get an entry from the local context of the page or a copy of the whole
     * context, if no key is provided.
     * @param {string} [key] Key of context entry
     */
    page: key => provideContext(new Context(globalContext.page), key),
}
