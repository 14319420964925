import ApiService from '../ApiService'
import { createPoiVoteFromResponse } from '../factories'

/**
 * @typedef {Object} PoiVoteListResult
 *
 * @property {import("../../entities").PoiVote[]} votes
 * @property {number} count
 */

class PoiVoteApiService extends ApiService {

    constructor(endpoint) {
        super(endpoint)
        this.list = this.list.bind(this)
    }

    /**
     * Get all votes of a POI.
     * @param {number} poiId ID of the POI
     * @param {Object} options
     * @param {number} [options.page=1] Results page
     * @param {number} [options.pageSize] Page size for pagination
     * @returns {Promise<PoiVoteListResult>} Votes pagination result
     */
    async list(poiId, { page, pageSize }) {
        const queryParams = {
            page: page || 1,
        }
        if (pageSize) {
            queryParams.page_size = pageSize
        }

        const { results, count } = await this.endpoint.get({
            params: { poiId },
            queryParams,
        })

        return {
            votes: results.map(createPoiVoteFromResponse),
            count,
        }
    }

}

export default PoiVoteApiService
