import ApiService from '../ApiService'

class IntegrationsApiService extends ApiService {

    /**
     * @param {"garmin" | "wahoo"} provider
     */
    async disconnect(provider) {
        this.endpoint.delete({
            params: { provider },
        })
    }

}

export default IntegrationsApiService
