import { UNIT } from '@bikemap/js/settings'
import { extractFromUrl } from '@bikemap/js/utility'
import { changeUnit } from './preferences'
import { getCookiePreferences, saveCookiePreferences } from '../cookieconsent'

/**
 * Initialize global session preferences from URL parameters and remove the parameters.
 */
export function getPreferencesFromUrlParams() {
    useUrlUnitPreference()
    useUrlCookieConsent()
}

function useUrlUnitPreference() {
    const urlUnitPreference = extractFromUrl('unit')
    if (
        (urlUnitPreference === 'metric' || urlUnitPreference === 'imperial') &&
        urlUnitPreference !== UNIT
    ) {
        changeUnit(urlUnitPreference)
    }
}

function useUrlCookieConsent() {
    const urlStatistics = extractFromUrl('cookieConsentStatistics')
    if (urlStatistics && getCookiePreferences() === null) {
        saveCookiePreferences({
            statistics: !!Number(urlStatistics),
        })
    }
}
