import { readCookie } from '../cookie'

export function getApiHeaders() {
    return {
        'X-CSRFToken': readCookie('csrftoken'),
        'X-Local-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
}

export function handleNetworkErrors(res) {
    if (res.ok) return res.json()
    return Promise.reject(res)
}

/**
 * A common GET request.
 * @param {string} url URL to fetch from
 * @param {Object} [headers] Additional headers
 */
export function defaultGet(url, headers = {}) {
    return fetch(url, {
        headers: {
            'X-CSRFToken': readCookie('csrftoken'),
            'x-requested-with': 'XMLHttpRequest',
            ...headers,
        },
        credentials: 'same-origin',
    })
        .then(handleNetworkErrors)
}

/**
 * A common POST request.
 * @param {string} url URL to fetch from
 * @param {FormData} [body] Request body data
 * @param {Object} [headers] Additional headers
 */
export function defaultPost(url, body = new FormData, headers = {}) {
    return fetch(url, {
        method: 'POST',
        body,
        headers: {
            'X-CSRFToken': readCookie('csrftoken'),
            'x-requested-with': 'XMLHttpRequest',
            ...headers,
        },
        credentials: 'same-origin',
    })
        .then(handleNetworkErrors)
}

export function handleRedirect(res) {
    if (res.url) {
        if (location.href === res.url) {
            location.reload()
        } else {
            location.href = res.url
        }
    } else {
        return res
    }
}
