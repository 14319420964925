import { getApiHeaders, serializeQueryParams } from '@bikemap/js/utility'
import context from '@bikemap/js/services/context'
import gettext from '@bikemap/js/services/gettext'
import notifications from '@bikemap/js/services/notifications'
import { authToken } from '@bikemap/js/api/auth-token'
import sessionData from '../services/sessionData'
import { API_BASE_URL_CACHED } from '@bikemap/js/settings'

// Don't rely on local authentication when using development API
const ALWAYS_AUTHORIZE = !!context.api('developmentAccess')

/**
 * Common arguments for the request.
 * @typedef {Object} RequestArgs
 * @property {Object} [params] URL params
 * @property {Object} [queryParams] URL query params
 */

/**
 * Utility class to connect to an API endpoint.
 */
class Endpoint {

    /** @type {string} */
    basePath

    /**
     * @param {string} path Path of the endpoint
     */
    constructor(path) {
        this.basePath = path
    }

    /**
     * GET request to the endpoint including response and error handling.
     * @param {RequestArgs} [args] Request arguments
     * @returns {Promise<Object>} The deserialized response body
     */
    async get(args = {}) {
        return this._baseRequest(args)
    }

    /**
     * POST request to the endpoint including response and error handling.
     * @param {FormData|Object} body Request body as FormData or plain object which means JSON request
     * @param {RequestArgs} [requestArgs] Request arguments
     * @param {Object} [requestHeaders] Additional headers for the fetch() call
     * @returns {Promise<Object>} The deserialized response body
     */
    async post(body, requestArgs = {}, requestHeaders = {}) {
        const isJsonRequest = !(body instanceof FormData)

        const headers = isJsonRequest ? {
            'Content-Type': 'application/json',
        } : {}

        return this._baseRequest(requestArgs, {
            method: 'POST',
            body: isJsonRequest ? JSON.stringify(body) : body,
        }, {
            ...headers,
            ...requestHeaders,
        })
    }

    /**
     * PATCH request to the endpoint including response and error handling.
     * @param {FormData} body Request body
     * @param {RequestArgs} [requestArgs] Request arguments
     * @returns {Promise<Object>} The deserialized response body
     */
    async patch(body, requestArgs = {}) {
        return this._baseRequest(requestArgs, { method: 'PATCH', body })
    }

    /**
     * DELETE request to the endpoint including response and error handling.
     * @param {RequestArgs} [requestArgs] Request arguments
     * @param {FormData} [body] Request body (required by some of our DELETE endpoints)
     * @returns {Promise<Object>} The deserialized response body
     */
    async delete(requestArgs = {}, body) {
        const options = { method: 'DELETE', body }
        if (body) {
            options.body = body
        }
        return this._baseRequest(requestArgs, options)
    }

    /**
     * Shared code between all different request types.
     * @param {RequestArgs} requestArgs Request arguments
     * @param {Object} [options] Additional options for the fetch() call
     * @param {Object} [headers] Additional headers for the fetch() call
     * @returns {Promise<Object|Array>} Deserialized JSON response
     */
    async _baseRequest(requestArgs, options, headers = {}) {
        const fetchOptions = {
            headers: {
                ...getApiHeaders(),
                ...headers,
            },
            credentials: 'include',
            ...options,
        }

        const isLoggedIn = await sessionData.isLoggedIn()
        if (!isLoggedIn || ALWAYS_AUTHORIZE) {
            fetchOptions.headers.Authorization = await authToken.getAuthHeader()
        }

        let res
        try {
            res = await fetch(this._buildPath((options && options.method) || 'GET', requestArgs), fetchOptions)
        } catch (error) {
            this._notifyConnectionError()
            throw error
        }

        if (!res.ok) {
            throw Error(res.statusText)
        }

        return res.status !== 204 ? await res.json() : null
    }

    /**
     * Show a notification about connection problems.
     */
    _notifyConnectionError() {
        notifications.error(
            gettext('Looks like you lost your internet connection. Please try again.'),
            gettext('No connection'),
        )
    }

    /**
     * Build the actual path by inserting params into the base path's placeholders.
     * @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'} method
     * @param {Object} [args]
     * @param {Object} [args.params] URL params
     * @param {Object} [args.queryParams] URL query params
     * @returns {string} The full path
     */
    _buildPath(method, args = {}) {
        const { params, queryParams } = args
        // API_BASE_URL has cookie issues currently
        // let path = (method === 'GET' ? API_BASE_URL_CACHED : API_BASE_URL) + this.basePath
        let path = API_BASE_URL_CACHED + this.basePath

        if (params) {
            for (const key in params) {
                path = path.replace(':' + key, params[key])
            }
        }

        if (queryParams) {
            path += serializeQueryParams(queryParams)
        }

        return path
    }

}

export default Endpoint
