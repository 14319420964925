import { SET_LANGUAGE_URL } from '@bikemap/js/settings'
import { readCookie } from '@bikemap/js/utility'

export const changeLanguage = locale => {
    const body = new FormData
    body.append('language', locale)
    body.append('next', location.href)
    body.append('csrfmiddlewaretoken', readCookie('csrftoken'))

    fetch(SET_LANGUAGE_URL, {
        method: 'POST',
        body,
    }).then(res => {
        location.href = res.url
    })
}
