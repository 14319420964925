import React from 'react'

import { Dropdown, DropdownList, DropdownItem, DropdownOptionItem } from '../Dropdown'
import { PremiumBadge } from '../PremiumBadge'
import { User } from '../User'
import { translated } from '../_translation'

/**
 * @typedef {Object} URLsConfig
 * @property {string} premium
 * @property {string} help
 * @property {string} logout
 */

/**
 * Simple dropdown with links to route creation tools.
 * @param {Object} props
 * @param {URLsConfig} props.urls
 * @param {import("@bikemap/js/entities").User} [props.user]
 * @param {() => void} [props.onHelp]
 * @param {() => React.ComponentType} [props.renderStaffListGroup]
 * @param {() => void} [props.onLogoutClick]
 */
const UserMenuDropdown = ({
    gettext, urls, user, onHelp, renderStaffListGroup, onLogoutClick,
}) => {
    if (!user) {
        // No user information yet, so show a non-interactive user placeholder
        return <User />
    }

    const myRoutes = gettext('My Routes')
    const routeCollections = gettext('Route Collections')
    const settings = gettext('Settings')
    const premium = gettext('Go Premium')
    const help = gettext('Send us a message')
    const logout = gettext('Log out')

    const dropdownProps = {
        className: 'user-menu-dropdown',
        renderTrigger: props => (
            <User {...props} user={user}>
                <i className="i-caret-down i-sm" />
            </User>
        ),
        renderList: onClose => {
            const handleHelp = () => {
                onHelp()
                onClose()
            }

            return (
                <DropdownList>
                    <DropdownList.Group>
                        <DropdownOptionItem
                            href={user.getRoutesUrl()}
                            icon="i-routes"
                            label={myRoutes}
                            dataTestId="header-user-menu-my-routes"
                        />
                        <DropdownOptionItem
                            href={user.getRouteCollectionsUrl()}
                            icon="i-collection"
                            label={routeCollections}
                            dataTestId="header-user-menu-collections"
                        />
                        <DropdownOptionItem
                            href={user.getSettingsUrl()}
                            icon="i-settings"
                            label={settings}
                            dataTestId="header-user-menu-settings"
                        />
                    </DropdownList.Group>
                    <DropdownList.Group>
                        {user.isPremium ? (
                            <DropdownOptionItem href={urls.help} icon="i-feedback" label={help}
                                onClick={onHelp && handleHelp} />
                        ) : (
                            <DropdownItem className="text-premium">
                                <PremiumBadge />
                                <a href={urls.premium}>
                                    <strong>{premium}</strong>
                                </a>
                            </DropdownItem>
                        )}
                    </DropdownList.Group>
                    {user.isStaff && renderStaffListGroup && renderStaffListGroup()}
                    <DropdownList.Group>
                        <DropdownOptionItem href={urls.logout} icon="i-logout" label={logout} onClick={onLogoutClick} />
                    </DropdownList.Group>
                </DropdownList>
            )
        },
    }

    return <Dropdown {...dropdownProps} right />
}

export default translated(UserMenuDropdown)
