import { serializeQueryParams, deserializeQueryParams } from '../utility'

/**
 * @typedef {Object} HistoryServiceConfig
 *
 * @property {Object} [queryParamTypes] Object of serializers for the query parameters
 * @property {Object} [queryParamDefaults] Object of default values to exclude from query parameters
 */

/** @type {HistoryServiceConfig} */
let config = {}

/** @type {Object} */
let defaults = {}

/** @type {Object} */
let queryParams = {}

/**
 * Configure the history service.
 * @param {HistoryServiceConfig} configUpdate Configuration to override default / latest config
 */
export function configure(configUpdate) {
    config = {
        ...config,
        ...configUpdate,
    }

    if (configUpdate.queryParamDefaults) {
        // Serialize the defaults, so we can compare every type of value
        defaults = serializeValues(configUpdate.queryParamDefaults)
    }
}

/**
 * Update browser history with new query params.
 */
function updateHistory() {
    history.replaceState({}, document.title, location.pathname + serializeQueryParams(queryParams))
}

/**
 * Compare values object with current object.
 * @param {Object} values
 */
function differFromCurrent(values) {
    // Check for different keys
    const comparableKeys = obj => JSON.stringify(Object.keys(obj).sort())
    if (comparableKeys(values) !== comparableKeys(queryParams)) {
        return true
    }

    // Check for different values
    for (const key in values) {
        if (values[key] !== queryParams[key]) return true
    }
    return false
}

/**
 * Get the current query parameters.
 * @returns {Object} Object of query params with serialized values
 */
export function getQueryParams() {
    return deserializeQueryParams(location.search)
}

/**
 * Set new query parameters.
 * @param {Object} newQueryParams Object of query params with serialized values
 */
export function setQueryParams(newQueryParams) {
    // Only update history when a change happened to one of the relevant values
    if (differFromCurrent(newQueryParams)) {
        queryParams = newQueryParams
        updateHistory()
    }
}

/**
 * Create a new object with selected and converted values from a source object.
 * @param {Object} source Source object
 * @param {Object} config Configuration with keys to pick, referencing a configuration for the item
 * @param {(value, config) => *} converter Converter function called with a picked item and its configuration
 */
function pickAndConvert(source, config, converter) {
    const converted = {}
    for (const key in config) {
        const item = source[key]
        if (item !== undefined && item !== null) {
            const convertedValue = converter(item, config[key])
            if (convertedValue !== null) {
                converted[key] = convertedValue
            }
        }
    }
    return converted
}

/**
 * Pick and deserialize certain keys from a source object - if they exist - to a new object.
 * @param {Object} source Source object containing serialized values
 */
function deserializeValues(source) {
    return pickAndConvert(source, config.queryParamTypes, (value, config) => config.deserialize(value))
}

/**
 * Pick and serialize certain keys from a source object - if they exist - to a new object.
 * @param {Object} source Params object with original values
 */
function serializeValues(source) {
    return pickAndConvert(source, config.queryParamTypes, (value, config) => config.serialize(value))
}

/**
 * Hydrate an app with information from the page's URL.
 * @param {(values) => void} mapParamsToState Maps a flat object with all values to the state, only called when there are values
 */
export function hydrateState(mapParamsToState) {
    const paramsSource = getQueryParams()

    // Skip if there are no values
    if (!Object.keys(paramsSource).length) return

    // Deserialize and hydrate
    const deserializedParams = deserializeValues(paramsSource)

    // Update state
    mapParamsToState(deserializedParams)

    // Cache params after successful hydration
    queryParams = paramsSource
}

/**
 * Filter out default values.
 * @param {Object} values
 */
function filterOutDefaults(values) {
    // Skip, if there are no defaults
    if (!Object.keys(defaults).length) return values

    const filtered = {}
    for (const key in values) {
        const defaultValue = defaults[key]
        if (!defaultValue || values[key] !== defaultValue) {
            filtered[key] = values[key]
        }
    }
    return filtered
}

/**
 * Bind the history manager to a state, so that it updates automatically after changes.
 * @param {{ subscribe, getState }} store Store containing the state
 * @param {(state) => Object} [mapStateToParams] Function to map relevant values from the state to a flat object
 */
export function bindState(store, mapStateToParams) {
    store.subscribe(() => {
        const state = store.getState()
        const rawParams = mapStateToParams ? mapStateToParams(state) : state
        const newParams = filterOutDefaults(serializeValues(rawParams))

        setQueryParams(newParams)
    })
}
