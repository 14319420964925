import { renderIcon, renderCloseButton, renderSVG } from '../../utility'

function createCloseBtn(onClose) {
    const btn = renderCloseButton()
    if (onClose) {
        btn.addEventListener('click', onClose)
    }
    return btn
}

/**
 * Create the title element including an optional icon.
 * @param {string} [title=""] A short title
 * @param {string} [icon] Name of the icon
 * @param {string} [iconType] Icon type if it is not one of the Bikemap icons
 */
function createTitle(title = '', icon, iconType) {
    const el = document.createElement('h5')
    el.appendChild(document.createTextNode(title))
    if (icon && icon.length) {
        let iconEl
        if (iconType === 'img') {
            iconEl = document.createElement('img')
            iconEl.setAttribute('alt', 'Notification Icon')
            iconEl.setAttribute('src', icon)
        } else if (iconType === 'svg') {
            iconEl = renderSVG(icon)
        } else {
            iconEl = renderIcon(icon)
        }
        iconEl.classList.add('notification-icon')
        el.prepend(iconEl)
    }
    el.classList.add('notification-title')
    return el
}

/**
 * Create image container with image and optional badge on top of it.
 * @param {string} imageUrl
 * @param {string} [imageBadgeUrl]
 */
function createImageEl(imageUrl, imageBadgeUrl) {
    const contentEl = document.createElement('div')
    contentEl.classList.add('notification-image-content')

    const wrapperEl = document.createElement('div')
    wrapperEl.classList.add('notification-image-wrapper')
    contentEl.append(wrapperEl)

    const imgEl = document.createElement('img')
    imgEl.setAttribute('alt', 'Notification Image')
    imgEl.setAttribute('src', imageUrl)
    wrapperEl.append(imgEl)

    if (imageBadgeUrl) {
        const badgeEl = document.createElement('img')
        badgeEl.setAttribute('alt', 'Badge')
        badgeEl.setAttribute('src', imageBadgeUrl)
        badgeEl.classList.add('notification-image-badge')
        contentEl.append(badgeEl)
    }

    return contentEl
}

/**
 * Create left and right footer elements, with both elements always rendered -
 * to keep them on correct side if the other one is missing.
 * @param {string} [footerLeft]
 * @param {string} [footerRight]
 */
function createFooterEl(footerLeft, footerRight) {
    const el = document.createElement('div')
    el.classList.add('notification-footer')

    const leftEl = document.createElement('div')
    leftEl.classList.add('notification-footer-left')
    el.append(leftEl)

    const rightEl = document.createElement('div')
    rightEl.classList.add('notification-footer-right')
    el.append(rightEl)

    if (footerLeft) {
        leftEl.innerHTML = footerLeft
    }
    if (footerRight) {
        rightEl.innerHTML = footerRight
    }

    return el
}

class Notification {

    /** @type {HTMLDivElement} */
    node

    /** @type {string} */
    name

    /** @type {function[]} */
    onClose

    /**
     * @param {import(".").NotificationOptions} options
     */
    constructor(options) {
        const {
            name,
            title,
            imageUrl,
            imageBadgeUrl,
            icon,
            message,
            footerLeft,
            footerRight,
            type,
            onClose,
        } = options
        const iconType = options.icon_type
        this.name = name

        // DOM node
        this.node = document.createElement('div')
        this.node.classList.add('notification', type)

        const closeBtn = createCloseBtn(this.close.bind(this))
        closeBtn.setAttribute('data-testid', 'notification-close-button')
        closeBtn.setAttribute('data-notification-name', this.name)
        this.node.append(closeBtn)

        if (imageUrl) {
            this.node.append(createImageEl(imageUrl, imageBadgeUrl))
        }

        const contentEl = document.createElement('div')
        contentEl.classList.add('notification-message-content')

        if (message) {
            // Wrap plain text with <p> (very simple check but serves our needs)
            if (message.trim().startsWith('<')) {
                contentEl.innerHTML = message
            } else {
                const p = document.createElement('p')
                p.innerHTML = message
                contentEl.append(p)
            }
        }

        contentEl.prepend(createTitle(title, icon, iconType))

        if (footerLeft || footerRight) {
            contentEl.append(createFooterEl(footerLeft, footerRight))
        }

        this.node.append(contentEl)

        // Close handlers
        this.onClose = []
        if (onClose) {
            this.onClose.push(onClose)
        }
    }

    fadeOut() {
        // Trigger CSS animation and continue afterwards
        this.node.classList.add('fade-out')
        return new Promise((resolve) => {
            setTimeout(resolve, 500)
        })
    }

    close(e) {
        this.fadeOut()
            .then(() => {
                this.onClose.forEach(handler => handler(e))
            })
            .then(() => this.node.remove())
    }

    addCloseHandler(handler) {
        this.onClose.push(handler)
    }

}

export default Notification
