import './Avatar.scss'

import React, { useState } from 'react'

import { PremiumBadge } from '../PremiumBadge'
import { translated } from '../_translation'

/**
 * Displays the user's avatar image.
 * @param {Object} props
 * @param {string} [props.image] Avatar image URL
 * @param {boolean} [props.isPremium] Is it a premium user?
 */
const Avatar = ({ gettext, image, isPremium }) => {
    const alt = gettext('Avatar')
    const [imgNotFound, setImgNotFound] = useState(false)

    return (
        <div className="avatar">
            <div className='avatar-img-container'>
                <i className='i-helmet text-secondary' />
                {image && (
                    <img src={image} width={33} height={33} alt={alt} loading='lazy'
                        onError={() => setImgNotFound(true)} style={imgNotFound ? { display: 'none' } : null} />
                )}
            </div>
            {isPremium && <PremiumBadge />}
        </div>
    )
}

export default translated(Avatar)
