import { user as userService } from '@bikemap/js/services'
import sessionData from '@bikemap/js/services/sessionData'

/**
 * Set body classes based on auth and user data, so content can be shown or hidden via CSS.
 */
export async function initConditionalContent() {
    if (await sessionData.isLoggedIn()) {
        // Set unsure class to hide all conditional content while loading user data
        document.body.classList.add('bm-unsure')

        const user = await userService.getEntity()
        if (user.isReferralPremium) {
            document.body.classList.add('bm-referral-premium')
        } else if (user.isPremium) {
            document.body.classList.add('bm-premium', 'bm-premium-' + user.getPlanPeriod())
        }
        document.body.classList.remove('bm-unsure')
        document.body.classList.add('bm-login')
    } else {
        document.body.classList.add('bm-guest')
    }
}
