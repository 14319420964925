import './PremiumBadge.scss'

import React from 'react'

import { translated } from '../_translation'

const PremiumBadge = ({ gettext, ...props }) => {
    const premiumBadge = gettext('Premium Badge')

    return (
        <svg className="premium-badge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 144" {...props}>
            <title>{premiumBadge}</title>
            <path className="below" d="M32.972 78.9v64.692L59.286 127l26.274 16.592V78.9z"/>
            <path className="border" d="M118.139 59.35l-10.025 13.008 2.154 16.28-15.232 6.27-6.304 15.15-16.368-2.142-13.078 9.97-13.118-9.97-16.328 2.142-6.305-15.15-15.232-6.27 2.115-16.28L.394 59.349l10.024-13.008-2.115-16.28 15.232-6.27L29.84 8.64l16.328 2.141L59.286.812l13.078 9.97L88.732 8.64l6.304 15.15 15.232 6.271-2.154 16.28z"/>
            <ellipse className="circle" cx="59.912" cy="58.531" rx="38.609" ry="38.402"/>
            <path className="symbol" d="M59.91 29.38l7.4 20.75h19.912l-16.246 12.2 5.815 21.382-16.88-12.83-16.917 12.83 5.815-21.382-16.246-12.2h19.911z"/>
        </svg>
    )
}

export default translated(PremiumBadge)
