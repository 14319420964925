import context from '@bikemap/js/services/context'

/**
 * Get the base URL for static files with trailing slash, depending on the environment.
 * @param {import("./environment").EnvironmentType} environment
 * @returns {string}
 */
export function getStaticUrl(environment) {
    switch (environment) {
        case 'PRODUCTION': return 'https://static.bikemap.net/'
        case 'DEVELOPMENT': return 'https://development-static.bikemap.net/'
        default: return 'http://127.0.0.1:8000/static/'
    }
}

/**
 * Get the absolute API base URL (including host) with trailing slash.
 * @param {import("./environment").EnvironmentType} environment
 * @returns {string}
 */
export function getAbsoluteApiUrl(environment) {
    if (environment === 'PRODUCTION') {
        return 'https://api.bikemap.net/api/'
    } else if (
        environment === 'DEVELOPMENT' ||
        context.api('developmentAccess')
    ) {
        return 'https://development-api.bikemap.net/api/'
    }
    return 'http://localhost:8000/api/'
}

/**
 * Get the absolute API base URL on the cache layer (including host) with trailing slash.
 * @param {import("./environment").EnvironmentType} environment
 * @returns {string}
 */
export function getAbsoluteCashedApiUrl(environment) {
    if (environment === 'PRODUCTION') {
        return 'https://www.bikemap.net/api/'
    } else if (
        environment === 'DEVELOPMENT' ||
        context.api('developmentAccess')
    ) {
        return 'https://development.bikemap.net/api/'
    }
    return 'http://localhost:8000/api/'
}

/**
 * Get the absolute web app base URL.
 * @param {import("./environment").EnvironmentType} environment
 * @returns {string}
 */
export function getWebAppUrl(environment) {
    if (environment === 'PRODUCTION') {
        return 'https://web.bikemap.net'
    } else if (environment === 'DEVELOPMENT') {
        return 'https://web-staging.bikemap.net'
    }
    return 'http://localhost:4200'
}
