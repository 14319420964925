import { getImagePath } from './helpers'

/*
Utilities for manipulating the DOM.
*/

/**
 * @param {string} name Class name of the icon
 * @param {string} [variant] Icon variant
 * @returns {HTMLElement} The <i> icon element
 */
export function renderIcon(name, variant) {
    const icon = document.createElement('i')
    icon.className = name
    if (variant) icon.classList.add('text-' + variant)
    return icon
}

/**
 * @param {string} filename File name or path relative to [staticBaseUrl]/img/
 * @param {string} [alt] Alternative text
 * @param {string} [width] Width attribute
 * @param {string} [height] Height attribute
 * @param {boolean} [lazy=true] Native lazy loading
 * @returns {HTMLImageElement} The <img> element
 */
export function renderImg(filename, alt, width, height, lazy = true) {
    const img = document.createElement('img')
    img.setAttribute('src', (filename.startsWith('http')) ? filename : getImagePath(filename))
    img.setAttribute('alt', alt || '')
    if (width) img.setAttribute('width', width)
    if (height) img.setAttribute('height', height)
    if (lazy) img.setAttribute('loading', 'lazy')
    return img
}

/**
 * @param {string} id ID (without "#") of the SVG
 * @returns {SVGElement} The <svg> element
 */
export function renderSVG(id) {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    const useElement = document.createElementNS('http://www.w3.org/2000/svg', 'use')
    useElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + id)
    svg.append(useElement)
    return svg
}

/**
 * @returns {HTMLButtonElement} The button element
 */
export function renderCloseButton() {
    const btn = document.createElement('button')
    btn.className = 'btn close-btn'
    btn.type = 'button'
    btn.append(renderIcon('i-close'))
    return btn
}

/**
 * @typedef {'left'|'right'} TooltipPosition
 */

/**
 * Makes an element by selector show a certain tooltip on mouseover. If no element is found, nothing happens.
 * @param {string} selector Selector for the element the tooltip should be applied on
 * @param {string} text Text to show in the tooltip
 * @param {TooltipPosition} [position="right"] Text to show in the tooltip
 */
export function setTooltip(selector, text, position = 'right') {
    const el = document.querySelector(selector)
    if (el) {
        el.setAttribute('data-tooltip', text)
        if (position === 'right') {
            el.classList.add('bm-tooltip-right')
        }
        if (position === 'left') {
            el.classList.add('bm-tooltip-left')
        }
        el.setAttribute('title', '')
    }
}

/**
 * Dynamically inject script tag with promise.
 * @param {string} src Script to load
 * @returns {Promise<void>}
 */
export function injectScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = src
        script.addEventListener('load', resolve)
        script.addEventListener('error', e => reject(e))
        document.head.appendChild(script)
    })
}

/**
 * Remove all children of a given element.
 * @param {HTMLElement} el
 */
export function removeAllChildren(el) {
    while (el.firstChild) {
        el.removeChild(el.lastChild)
    }
}
