import moment from 'moment'

import { PoiCategory } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {PoiCategory}
 */
export default function createPoiCategoryFromResponse(res) {
    const category = new PoiCategory

    category.id = res.id
    category.parent = res.parent
    category.icon = res.icon
    category.color = res.color
    category.name = res.name
    if (res.ttl) {
        category.ttl = moment.duration(res.ttl)
    }
    category.identifier = res.identifier

    return category
}
