import React from 'react'
import TranslationContext from './TranslationContext'

/**
 * Wrap an app using Bikemap UI components with this to provide translation.
 * @param {Object} props
 * @param {(message: string) => string} translate Translation function
 */
const TranslationProvider = ({ translate, children }) => (
    <TranslationContext.Provider value={translate}>
        {children}
    </TranslationContext.Provider>
)

export default TranslationProvider
