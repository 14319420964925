import './InputField.scss'

import React, { useRef } from 'react'

import { MinimalButton } from '../MinimalButton'
import { FormField } from '../FormField'

/**
 * @param {Object} props
 * @param {string} props.value
 * @param {string} props.name
 * @param {string} props.label
 * @param {(value: string, name?: string) => void} props.onChange
 * @param {boolean} [props.secondary]
 * @param {"text"|"email"|"password"} [props.type="text"]
 * @param {string} [props.icon]
 * @param {() => void} [props.onReset] This handler will be called on reset button click instead of just removing the value
 * @param {string} [props.placeholder]
 * @param {boolean} [props.autoFocus]
 * @param {React.KeyboardEventHandler<HTMLInputElement>} [props.onKeyDown]
 * @param {string} [props.autoComplete]
 * @param {boolean} [props.noLabel]
 * @param {boolean} [props.noResetIcon]
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.className]
 * @param {boolean} [props.loading]
 * @param {boolean} [props.isRequired] Showing astrix in label if set as required
 * @param {string} [props.error] Error message shown below the form element
 */
const InputField = ({
    value, name, label, onChange, secondary, type, icon, onReset, placeholder, autoFocus, onKeyDown,
    autoComplete, noLabel, noResetIcon, children, className, loading, isRequired, error,
}) => {
    const inputRef = useRef(null)

    let classes = 'input-field'
    if (className) classes += ' ' + className
    if (loading) classes += ' loading-spinner'

    const inputProps = {
        className: secondary ? 'input-secondary' : null,
        value,
        name,
        onChange: e => onChange(e.target.value, name),
        type: type || 'text',
        placeholder,
        autoFocus,
        onKeyDown,
        autoComplete,
    }

    const handleReset = () => {
        inputRef.current.focus()
        if (onReset) {
            onReset()
        } else {
            onChange('', name)
        }
    }

    return (
        <FormField className={classes} name={name} label={label} noLabel={noLabel} isRequired={isRequired} error={error} renderElement={elementProps => (
            <>
                {icon && <i className={icon + ' input-icon'} />}
                {!loading && value && !noResetIcon && (
                    <MinimalButton className="input-ctrl" icon="i-cancel" variant="text" onClick={handleReset} />
                )}
                <input {...elementProps} {...inputProps} ref={inputRef} />
            </>
        )}>
            {children}
        </FormField>
    )
}

export default InputField
