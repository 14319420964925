import React from 'react'

/**
 * A simple close button.
 * @param {Object} props
 * @param {(event: React.MouseEvent) => void} [props.onClick] Click event handler
 * @param {string} [props.className] Additional classes
 */
const CloseButton = ({ onClick, className }) => {
    let classes = 'btn close-btn'
    if (className) classes += ' ' + className

    return (
        <button type="button" className={classes} onClick={onClick}>
            <i className="i-close" />
        </button>
    )
}

export default CloseButton
