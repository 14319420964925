import { Storage, STORAGE_KEY_COOKIE_PREFERENCES } from '@bikemap/js/utility'

/**
 * @typedef {Object} CookiePreferences
 * @property {boolean} statistics
 */

/**
 * @returns {CookiePreferences|null}
 */
export function getCookiePreferences() {
    const storageJson = Storage.getItem(STORAGE_KEY_COOKIE_PREFERENCES)

    if (storageJson) {
        try {
            const item = JSON.parse(storageJson)
            return item.value
        } catch {
            return null
        }
    }

    return null
}

/**
 * @param {'statistics'} key
 * @returns {boolean}
 */
export function getCookiePreference(key) {
    const preferences = getCookiePreferences()
    return !!(preferences && preferences[key])
}

/**
 * @param {CookiePreferences} preferences
 */
export function saveCookiePreferences(preferences) {
    Storage.setWithTimestamp(STORAGE_KEY_COOKIE_PREFERENCES, preferences)
}
