import './FormField.scss'

import React from 'react'

/**
 * @typedef {Object} ElementProps
 * @property {string} name
 * @property {string} id
 */

/**
 * Generic form field wrapping label and form element.
 * @param {Object} props
 * @param {((props: ElementProps) => React.ReactNode)} props.renderElement
 * @param {string} props.name Name of the form element
 * @param {string} props.label Label text of the form field
 * @param {string} [props.icon] Name of the icon to display in the label
 * @param {string} [props.className] Additional classes
 * @param {boolean} [props.noLabel] Hide the label
 * @param {boolean} [props.isRequired] Showing astrix in label if set as required
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.error] Error message shown below the form element
 */
const FormField = ({
    renderElement, name, label, icon, className, noLabel, isRequired, children, error,
}) => {
    const id = name + '-field-' + Math.floor(Math.random() * 1000)

    let classes = 'form-field'
    if (className) classes += ' ' + className
    if (noLabel) classes += ' no-label'

    return (
        <div className={classes}>
            <label htmlFor={id}>
                {icon && <i className={icon + ' text-secondary'} />}
                {label}
                {isRequired && <span className='text-danger'>*</span>}
            </label>
            {renderElement({ name, id })}
            {error && <p className="form-field-error">{error}</p>}
            {children}
        </div>
    )
}

export default FormField
