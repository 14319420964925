import { ENV } from './environment'
import { LANGUAGE } from './basics'
import { getAbsoluteApiUrl, getStaticUrl, getWebAppUrl, getAbsoluteCashedApiUrl } from './helpers'

export const URL_BASE = '/' + LANGUAGE + '/'

export const STATIC_URL = getStaticUrl(ENV)

export const API_BASE_URL_CACHED = getAbsoluteCashedApiUrl(ENV)

export const API_BASE_URL = getAbsoluteApiUrl(ENV)

export const SESSION_DATA_URL = URL_BASE + 'session_data/'

export const MARK_HINT_SEEN_URL = URL_BASE + 'u/mark_hint_seen/'

export const CHECK_EMAIL_AVAILABLE_URL = URL_BASE + 'u/check_email_available/'

export const CHECK_USERNAME_AVAILABLE_URL = URL_BASE + 'u/check_username_available/'

export const USER_LOCATION_URL = '/user_location/'

export const USER_PROFILE_URL = URL_BASE + 'u/:slug/'

export const USER_ROUTES_URL = URL_BASE + 'u/:slug/routes/'

export const USER_ROUTE_COLLECTIONS_URL = URL_BASE + 'u/:slug/collections/'

export const USER_SETTINGS_URL = URL_BASE + 'u/:slug/settings/'

export const USER_MEMBERSHIP_URL = URL_BASE + 'u/:slug/settings/manage-subscription/'

export const SEARCH_AUTOCOMPLETE_URL = URL_BASE + 'search/ac/'

export const LOGIN_URL = URL_BASE + 'login/'

export const LOGOUT_URL = URL_BASE + 'logout/?next=' + encodeURIComponent(window.location.pathname)

export const ROUTE_CREATE_URL = URL_BASE + 'r/create/'

export const ROUTE_DETAIL_URL = URL_BASE + 'r/:routeId/'

export const ROUTE_EDIT_URL = URL_BASE + 'r/:routeId/edit/'

export const ROUTE_COLLECTION_DETAIL_URL = URL_BASE + 'c/:routeCollectionId/'

export const GEONAME_DETAIL_URL = URL_BASE + 'l/:geonameId/'

export const SET_LANGUAGE_URL = '/i18n/setlang/'

export const SET_UNIT_URL = '/set_unit/'

export const REGIONS_URL = URL_BASE + 'l/'

export const PREMIUM_URL = URL_BASE + 'premium/'

export const APPS_URL = URL_BASE + 'apps/'

export const HELP_CENTER_URL = 'https://help.bikemap.net/'

export const ADMIN_URL = '/admin/'

export const POIBOY_URL = '/admin/pois/map'

export const SOCIAL_AUTH_CANCEL_URL = '/social-auth-cancel'

export const DEFAULT_MAP_STYLE_URL = API_BASE_URL_CACHED + 'v5/map_styles/default/style/?is_inhoused=true'

export const WEB_APP_BASE_URL = getWebAppUrl(ENV)

/**
 * These URLs should not be rendered into the DOM immediately, just after user interaction.
 */
export const WEB_APP_URLS = {
    WEB_APP_DISCOVER_URL: WEB_APP_BASE_URL,
    WEB_APP_PLAN_URL: WEB_APP_BASE_URL + '/plan',
    WEB_APP_IMPORT_URL: WEB_APP_BASE_URL + '/import',
    WEB_APP_ROUTE_DETAILS_URL: WEB_APP_BASE_URL + '/r/:id',
    WEB_APP_EDIT_URL: WEB_APP_BASE_URL + '/r/:id/edit',
    WEB_APP_REGION_URL: WEB_APP_BASE_URL + '/l/:id',
    WEB_APP_ROUTE_COLLECTION_URL: WEB_APP_BASE_URL + '/c/:id',
}

export const BLOG_CMS_API_URL = 'https://blog.bikemap.net/wp-json/wp/v2/pages/:id'
