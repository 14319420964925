import React, { useContext } from 'react'

import TranslationContext from './TranslationContext'

/**
 * Higher order component that provides a translation function.
 * @param {React.ComponentType} Component
 * @returns {React.ComponentType}
 */
const translated = Component => props => {
    const gettext = useContext(TranslationContext)

    const componentProps = {
        gettext,
        ...props,
    }

    return <Component {...componentProps} />
}

export default translated
