import has from 'lodash-es/has'
import { User } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {User}
 */
export default function createUserFromResponse(res) {
    const user = new User

    user.id = res.id
    user.slug = res.slug
    user.name = res.displayname
    user.avatar = res.avatar_image
    user.cover = res.cover_image
    user.biography = res.biography
    user.favoriteRoutesCount = res.favorited_count
    user.plannedRoutesCount = res.planned_count
    user.isPremium = res.is_subscribed
    user.premiumPlan = (res.subscription_info && res.subscription_info.plan) || null
    user.isReferralPremium = !!(res.is_subscribed && res.has_free_subscription && user.premiumPlan === 'pro-referral')
    user.externalId = res.external_id

    if (res.includes && has(res.includes, 'referral_code.link')) {
        user.referralCodeLink = res.includes.referral_code.link || undefined
    }
    if (res.includes && has(res.includes, 'referral_rewards_loop')) {
        const rewardsLoop = res.includes.referral_rewards_loop || {}
        user.referralRewardsLoop = {
            maxLoopDuration: rewardsLoop.max_loop_duration || undefined,
            startDate: rewardsLoop.start_date || undefined,
            freePremiumExpirationDate: rewardsLoop.free_premium_expiration_date || undefined,
        }
    }

    return user
}
