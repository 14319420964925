const SCROLLING_DISABLED_CLASS = 'scrolling-disabled'

/** @type {number} */
let scrollTop

/** @type {boolean} */
let disabled = false

/**
 * Disable scrolling of the whole page while keeping the viewport width (with scrollbar).
 */
function disable() {
    if (disabled) return

    if (document.documentElement.scrollHeight > window.innerHeight) {
        scrollTop = document.documentElement.scrollTop
        document.body.style.top = -scrollTop + 'px'
    }

    document.body.classList.add(SCROLLING_DISABLED_CLASS)
    disabled = true
}

/**
 * Enable scrolling again after it has been disabled.
 */
function enable() {
    if (!disabled) return

    document.documentElement.style.scrollBehavior = 'auto' // we don't want the animation now
    document.body.classList.remove(SCROLLING_DISABLED_CLASS)
    document.documentElement.scrollTop = scrollTop
    document.body.style.removeProperty('top')
    document.documentElement.style.removeProperty('scroll-behavior')
    scrollTop = null
    disabled = false
}

export default { disable, enable }
