import React from 'react'

/**
 * Combines icon and color data of a POI category to an icon.
 * @param {Object} props
 * @param {import("../../entities").PoiCategory} props.category The POI category
 */
const PoiCategoryIcon = ({ category }) => (
    <div className="poi-category-icon" style={{ backgroundColor: category.color }}>
        <img src={category.icon} alt={category.name} />
    </div>
)

export default PoiCategoryIcon
