/**
 * Insert an error message below a form field.
 * @param {HTMLElement} field Form field
 * @param {string} message The error message
 */
export function insertFieldError(field, message) {
    const fieldError = document.createElement('p')
    fieldError.classList.add('form-field-error')
    fieldError.textContent = message

    const parent = (typeof field.closest === 'function') ? field.closest('.form-field') : field.parentNode
    parent.append(fieldError)
}
