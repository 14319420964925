import moment from 'moment'

import { Poi } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {Poi}
 */
export default function createPoiFromResponse(res) {
    const poi = new Poi

    poi.id = res.id
    poi.user = res.user.id
    if (res.created) {
        poi.created = moment(res.created)
    }
    if (res.modified) {
        poi.modified = moment(res.modified)
    }
    poi.category = res.category
    poi.scoreNeg = res.score_neg
    poi.scorePos = res.score_pos
    poi.address = res.address
    if (res.ttl) {
        poi.ttl = moment(res.ttl)
    }

    poi.coords = res.coords.coordinates

    if (res.description) {
        poi.description = res.description
    }
    poi.image = res.image
    poi.reviewed = !!res.reviewed
    poi.active = !!res.active
    poi.isSpam = !!res.is_spam
    poi.commentCount = res.comment_count
    poi.mergedInto = res.merged_into

    return poi
}
