import ApiService from '../ApiService'
import { createUserFromResponse } from '../factories'
import createPoiCommentFromResponse from '../factories/createPoiCommentFromResponse'

/**
 * @typedef {Object} PoiCommentEndpoints
 *
 * @property {import("..").Endpoint} detail
 * @property {import("..").Endpoint} list
 * @property {import("..").Endpoint} markSpam
 */

class PoiCommentApiService extends ApiService {

    /** @type {import("..").Endpoint} */
    listEndpoint

    /** @type {import("..").Endpoint} */
    markSpamEndpoint

    /**
     * @param {PoiCommentEndpoints} endpoints
     */
    constructor(endpoints) {
        super(endpoints.detail)
        this.listEndpoint = endpoints.list
        this.markSpamEndpoint = endpoints.markSpam

        this.list = this.list.bind(this)
        this.update = this.update.bind(this)
        this.markSpam = this.markSpam.bind(this)
        this.delete = this.delete.bind(this)
    }

    /**
     * Get all comments of a POI.
     * @param {number} poiId ID of the POI
     * @param {Object} options
     * @param {number} [options.page=1] Results page
     * @param {number} [options.pageSize] Page size for pagination
     * @returns {Promise<import("../ApiService").ListResult>}
     */
    async list(poiId, { page, pageSize }) {
        const queryParams = {
            page: page || 1,
        }
        if (pageSize) {
            queryParams.page_size = pageSize
        }

        const { results, count } = await this.listEndpoint.get({
            params: { poiId },
            queryParams,
        })

        return this._mapListResult(results, count, {
            comments: createPoiCommentFromResponse,
            users: result => createUserFromResponse(result.user),
        })
    }

    /**
     * Update a POI comment.
     * @param {number} commentId ID of the POI comment
     * @param {FormData} changes Changes to the POI comment
     * @returns {Promise<import("../../entities").PoiComment>}
     */
    async update(commentId, changes) {
        const res = await this.endpoint.patch(changes, {
            params: { commentId },
        })
        return createPoiCommentFromResponse(res)
    }

    /**
     * Mark POI comment as spam.
     * @param {number} commentId ID of the POI comment
     * @returns {Promise<import("../../entities").PoiComment>}
     */
    async markSpam(commentId) {
        const res = await this.markSpamEndpoint.post(null, {
            params: { commentId },
        })
        return createPoiCommentFromResponse(res)
    }

    /**
     * Delete a POI comment.
     * @param {number} commentId ID of the POI comment
     * @returns {Promise}
     */
    async delete(commentId) {
        await this.endpoint.delete({
            params: { commentId },
        })
    }

}

export default PoiCommentApiService
