import moment from 'moment'
import has from 'lodash-es/has'
import isEmpty from 'lodash-es/isEmpty'
import { logError } from '@bikemap/js/utility'
import { fetchAccessToken } from './fetch'
import {
    setStoredAccessToken, getStoredAccessToken, removeStoredAccessToken,
} from './storage'
import sessionData from '@bikemap/js/services/sessionData'

/**
 * @typedef {Object} ExtendedAccessTokenData
 * @property {string} accessToken
 * @property {number} expiresIn Time to live (TTL) in seconds
 * @property {string} scope
 * @property {string} tokenType
 * @property {string} refreshToken
 * @property {number} expiryTimestamp Unix timestamp in seconds
 * @property {boolean} isForLoggedIn
 */

/**
 * Handle getting token for authorization header.
 */
class AuthToken {

    /** @type {string} Header consisting of token type and the token (e.g. "Bearer abc...") */
    authorizationHeader

    /** @type {string} */
    refreshToken

    /** @type {Promise} */
    tokenPromise

    /** @type {moment.Moment} */
    expiryDateTime

    /** @type {int} Timeout ID */
    refreshTokenTimeout

    init = () => {
        window.addEventListener('focus', this._recoverAuthentication)
        return this
    }

    /**
     * Make sure we have a valid token by checking the expiry time and refreshing if necessary.
     * Useful if token expires in the background (while tab is not in focus),
     * so our setTimeout callback didn't run.
     */
    _recoverAuthentication = () => {
        if (this.expiryDateTime && this.expiryDateTime.diff(moment()) < 0) {
            // Current token expired
            this._triggerTokenPromise()
        }
    }

    /**
     * Try to find valid token data from session storage.
     * @returns {Promise<ExtendedAccessTokenData|null>}
     */
    _getStoredAccessToken = async () => {
        try {
            const token = getStoredAccessToken()
            if (!token) {
                return null
            }

            // Token is stored and it didn't yet expire
            const isExpiryTimeOk =
                has(token, 'expiryTimestamp') &&
                moment.unix(token.expiryTimestamp).diff(moment()) > 0

            // Token is for same logged in state
            //  e.g. it was stored for logged-in user but user is now not logged in
            //  This would force getting new token data after the logout or login
            const isForSameLoginType = (token.isForLoggedIn === await sessionData.isLoggedIn())

            if (isExpiryTimeOk && isForSameLoginType) {
                return token
            }
            return null
        } catch (e) {
            logError('Get stored access token data', e)
            return null
        }
    }

    /**
     * Take token data we got from API response and add some additional data.
     * @param {import('./fetch').AccessTokenData} tokenData
     * @returns {Promise<ExtendedAccessTokenData>}
     */
    _extendTokenData = async (tokenData) => {
        return {
            ...tokenData,
            // Timestamp in sec
            expiryTimestamp: moment().add(tokenData.expiresIn, 's').unix(),
            // Also check if this token is for logged-in user or not
            isForLoggedIn: await sessionData.isLoggedIn(),
        }
    }

    /**
     * Fetch new access token from API, attach additional data and store it.
     * @returns {Promise<ExtendedAccessTokenData>}
     */
    _getNewToken = async () => {
        try {
            // Clear token data before fetching a new one
            removeStoredAccessToken()

            // Fetch new token
            const newToken = await fetchAccessToken(this.refreshToken)

            const extendedToken = await this._extendTokenData({
                ...newToken,
                // Reduce by 30 sec, so we can fetch a new one before it expires in backend
                expiresIn: newToken.expiresIn - 30,
            })

            // Save new token to storage
            setStoredAccessToken(extendedToken)

            // Return new token data
            return extendedToken
        } catch (e) {
            logError('Fetch new access token', e)
            return await this._extendTokenData({
                accessToken: '',
                // Will try to fetch again in 5 seconds
                expiresIn: 5,
                scope: '',
                tokenType: '',
                refreshToken: '',
            })
        }
    }

    /**
     * Get new or use previously stored token data.
     * @returns {Promise<ExtendedAccessTokenData>}
     */
    _getNewOrStoredToken = async () => {
        const storedToken = await this._getStoredAccessToken()
        if (storedToken) {
            return storedToken
        }

        return await this._getNewToken()
    }

    /**
     * Format token for `Authorization` header (e.g. "Bearer abc...").
     * @param  {ExtendedAccessTokenData} token
     * @returns {string}
     */
    _formatAuthorizationHeader = (token) => {
        if (!isEmpty(token.tokenType) && !isEmpty(token.accessToken)) {
            return token.tokenType + ' ' + token.accessToken
        }
        return ''
    }

    /**
     * Fetch the access token and refresh when it expires.
     * @returns {Promise<void>}
     */
    _getToken = async () => {
        const token = await this._getNewOrStoredToken()

        // Hold token data in memory
        this.authorizationHeader = this._formatAuthorizationHeader(token)
        this.refreshToken = token.refreshToken
        this.expiryDateTime = moment.unix(token.expiryTimestamp)

        clearTimeout(this.refreshTokenTimeout)
        // Refresh token when it expires
        this.refreshTokenTimeout = setTimeout(() => {
            this._triggerTokenPromise()
        }, (token.expiresIn * 1000))
    }

    /**
     * Trigger access token request and save promise for coming calls.
     * @returns {void}
     */
    _triggerTokenPromise = () => {
        this.tokenPromise = this._getToken()
    }

    /**
     * Returns the string to use as value of the authorization header after making an authentication
     * request if necessary.
     * @returns {Promise<string>}
     */
    getAuthHeader = async () => {
        // If no auth request has ever been triggered, trigger it now
        if (!this.tokenPromise) {
            this._triggerTokenPromise()
        }

        // Wait for an ongoing auth request to finish
        await this.tokenPromise

        // Clear this promise so that consequent getAuthHeader() calls would
        // skip promise currently in memory
        this.tokenPromise = undefined

        return this.authorizationHeader
    }

    /**
     * Synchronous function to immediately get the current auth header (can be empty).
     * @returns {string}
     */
    getCurrentAuthHeader = () => {
        return this.authorizationHeader || ''
    }

}

export default AuthToken
