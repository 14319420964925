import './User.scss'

import React from 'react'

import { Avatar } from '../Avatar'
import UserInfo from './UserInfo.jsx'

/**
 * Displays the user's avatar, name and one additional info.
 * @param {Object} props
 * @param {import("../../js/entities").User} [props.user] The user entity
 * @param {string} [props.info] Info line
 * @param {string} [props.className] Additional classes
 * @param {React.ReactNode} [props.children]
 * @param {() => void} [props.onClick]
 */
const User = ({ user, info, className, children, onClick }) => {
    if (!user) {
        return <div className="user-placeholder" data-testid="placeholder" />
    }

    const classes = 'user' + (className ? ' ' + className : '')
    const profileUrl = user.getProfileUrl()

    const content = (
        <>
            <Avatar image={user.avatar} isPremium={user.isPremium} />
            <UserInfo name={user.name} info={info} />
            {children}
        </>
    )

    const handleClick = e => {
        e.preventDefault()
        onClick()
    }

    if (profileUrl) {
        return (
            <a
                href={profileUrl}
                className={classes}
                onClick={onClick && handleClick} rel='nofollow'
                data-testid="user-profile-link"
            >
                {content}
            </a>
        )
    }
    if (onClick) {
        return <button className={classes} onClick={handleClick} data-testid="user-profile-button">{content}</button>
    }
    return <div className={classes}>{content}</div>
}

export default User
