import has from 'lodash-es/has'
import { onElementInteraction } from '@bikemap/js/utility/events'
import { WEB_APP_URLS, LANGUAGE, UNIT } from '@bikemap/js/settings'
import { getCookiePreferences } from '@bikemap/js/integrations/cookieconsent/preferences'

/**
 * Get params for web app URLs to transfer common data.
 * @returns {string}
 */
export function getWebAppParams() {
    let cookies = ''
    const cookiePreferences = getCookiePreferences()
    if (cookiePreferences) {
        cookies = '&cookieConsentStatistics=' + (cookiePreferences.statistics ? '1' : '0')
    }

    return '?language=' + LANGUAGE + '&unit=' + UNIT + cookies
}

/**
 * Injects web app URL to provided element since we
 * don't want to have it in the DOM on initial page load to improve SEO.
 * @param {HTMLAnchorElement} anchorElement
 */
function setWebAppUrl(anchorElement) {
    const urlKey = anchorElement.getAttribute('data-url-key')
    let url = has(WEB_APP_URLS, urlKey) ? WEB_APP_URLS[urlKey] : null
    if (url) {
        const urlId = anchorElement.getAttribute('data-url-id')
        if (urlId) {
            url = url.replace(':id', urlId)
        }
        url += getWebAppParams()
        if (urlKey === 'WEB_APP_DISCOVER_URL') {
            // Simply append current search params in case some of them are supported by discover
            url += '&' + window.location.search.replace('?', '')
        }
        const urlSearchParams = anchorElement.getAttribute('data-url-search-params')
        if (urlSearchParams) {
            url += '&' + urlSearchParams
        }
        anchorElement.setAttribute('href', url)
    }
}

/**
 * Process all web app (web.bikemap.net) URLs on
 * minimal user interaction (hover, click, tap, focus, etc).
 * @param {HTMLElement|Document} [parentEl] DOM element in which to look for links
 */
export function initWebAppUrls(parentEl = document) {
    parentEl.querySelectorAll('.js-inject-web-app-url').forEach((el) => {
        onElementInteraction(el, () => setWebAppUrl(el))
    })
}
