import moment from 'moment'

import { PoiComment } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {PoiComment}
 */
export default function createPoiCommentFromResponse(res) {
    const comment = new PoiComment

    comment.id = res.id
    comment.poi = res.poi
    comment.user = res.user.id
    if (res.created) {
        comment.created = moment(res.created)
    }
    if (res.modified) {
        comment.modified = moment(res.modified)
    }
    if (res.comment) {
        comment.text = res.comment
    }
    comment.image = res.image

    return comment
}
