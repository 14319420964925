import { readCookie } from '@bikemap/js/utility'
import { gettext, notifications } from '@bikemap/js/services'

window.$ = window.$ || {}
$.outdoorish = $.outdoorish || {}

/* ******************* Send confirmation mail ******************** */
$.outdoorish.resendConfirmationMail = (url) => {
    $.ajax({
        type: 'POST',
        url: url,
        headers: {
            'X-CSRFToken': readCookie('csrftoken'),
        },
        success: function (data) {
            if (data.status === 'OK') {
                notifications.show({
                    title: data.status,
                    icon: 'i-check',
                    message: data.message + data.email,
                    type: 'success',
                })
            } else {
                notifications.show({
                    title: data.status,
                    icon: 'i-warning-sign',
                    message: data.message,
                    type: 'danger',
                })
            }
        },
        error: function () {
            notifications.show({
                title: gettext('Error'),
                icon: 'i-warning-sign',
                message: gettext(
                    'Sorry, sending mails is currently not possible please try again later'),
                type: 'danger',
            })
        },
    })
}
