import moment from 'moment'

import { CATEGORIES, SURFACES } from '../integrations/routes/settings'
import { ROUTE_EDIT_URL } from '../settings'

const CATEGORY_ICONS = {
    bike: 'i-bike',
    roadbike: 'i-roadbike',
    mountainbike: 'i-mountainbike',
}

/**
 * Represents a route with all it's properties and utility methods.
 */
class Route {

    /** @type {number} */
    id

    /** @type {moment.Moment} */
    created = moment()

    /** @type {string} */
    title

    /** @type {string} */
    description

    /** @type {string} */
    staticmap

    /** @type {import("js-quantities")} */
    distance

    /** @type {import("js-quantities")} */
    ascent

    /** @type {import("js-quantities")} */
    descent

    /** @type {boolean} */
    isFavorite

    /** @type {number} */
    favoriteCount

    /** @type {string} */
    location

    /** @type {number[]} */
    category

    /** @type {number[]} */
    ground

    /** @type {string[]} */
    images = []

    /** @type {number} */
    user

    /** @type {[number, number]} */
    start

    /** @type {Object} GeoJSON geometry */
    points

    /** @type {number} */
    rating

    /** @type {number} */
    views

    /** @type {boolean} */
    isPrivate

    /** @type {boolean} */
    isPlanned

    /** @type {string} */
    appType = 'Web'

    /** @type {string} */
    appVersion

    /**
     * @returns {string[]} Translated names of the route categories
     */
    getCategoryNames() {
        return this.category ? this.category.map(categoryKey => CATEGORIES[categoryKey].label) : []
    }

    /**
     * @returns {string[]} Translated names of the route grounds
     */
    getGroundNames() {
        return this.ground ? this.ground.map(groundKey => SURFACES[groundKey].label) : []
    }

    /**
     * @returns {string} Icon name for the route's main category
     */
    getIcon() {
        if (this.category.length === 1) {
            const mainCategory = CATEGORIES[this.category[0]]
            if (mainCategory && CATEGORY_ICONS[mainCategory.key]) {
                return CATEGORY_ICONS[mainCategory.key]
            }
        }
        return 'i-bike'
    }

    favor() {
        if (this.isFavorite) throw Error('This route is already a favorite')
        this.isFavorite = true
        this.favoriteCount++
    }

    unfavor() {
        if (!this.isFavorite) throw Error('This route is not a favorite')
        this.isFavorite = false
        this.favoriteCount--
    }

    /**
     * @returns {string} URL of the edit page for this route
     */
    getEditUrl() {
        return this.id ? ROUTE_EDIT_URL.replace(':routeId', '' + this.id) : null
    }

    /**
     * Get current "created" date in format prepared for saving in backend.
     * @return {string}
     */
    getCreatedInBackendFormat() {
        return this.created.format()
    }

}

/**
 * @param {Route} route
 * @returns {Route} A copy of the given route
 */
Route.copy = function (route) {
    return Object.assign(new Route, route)
}

export default Route
