/**
 * Contains ES debug information about a route.
 */
class RouteDebugData {

    /** @type {number} */
    id

    /** @type {number} */
    score

    /** @type {number} */
    backlinksCount

    /** @type {number} */
    descriptionLength

    /** @type {number} */
    commentsCount

    /** @type {number} */
    ridersCount

    /** @type {number} */
    ratingsCount

    /** @type {number} */
    flaggedCount

    /** @type {boolean} */
    isShortRoute

}

export default RouteDebugData
