export const PREMIUM_PLAN_PERIODS = [
    'monthly',
    'quarterly',
    'yearly',
    '3-years',
    'lifetime',
    'referral',
]

export const PREMIUM_HINT_COOLDOWN = 6
