import './Dropdown.scss'

import React, { useState, useEffect, useRef } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

/**
 * @typedef {Object} TriggerProps
 * @property {() => void} onClick
 */

/**
 * Base dropdown component.
 * @param {Object} props
 * @param {string} [props.className]
 * @param {(props: TriggerProps) => React.ReactNode} props.renderTrigger
 * @param {(onClose: () => void) => React.ReactNode} props.renderList
 * @param {(HTMLElement) => void} [props.onAfterOpen]
 * @param {React.ReactNode} [props.children]
 * @param {boolean} [props.right] Align the dropdown list right
 * @param {boolean} [props.wider]
 */
const Dropdown = ({ className, renderTrigger, renderList, onAfterOpen, children, right, wider }) => {
    const [isOpen, setIsOpen] = useState(false)

    let classes = 'dropdown'
    if (right) classes += ' dropdown-right'
    if (wider) classes += ' dropdown-wider'
    if (className) classes += ' ' + className

    function close() {
        setIsOpen(false)
    }

    const triggerProps = {
        onClick: () => { setIsOpen(!isOpen) },
    }

    const ref = useRef()

    useEffect(() => {
        if (isOpen && onAfterOpen) {
            onAfterOpen(ref.current)
        }
    }, [isOpen, onAfterOpen, ref.current])

    return (
        <div ref={ref} className={classes}>
            {children}
            <OutsideClickHandler onOutsideClick={close}>
                {isOpen && renderList(close)}
                {renderTrigger(triggerProps)}
            </OutsideClickHandler>
        </div>
    )
}

export default Dropdown
