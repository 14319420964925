import { EventEmitter } from '../../utility'

/**
 * A styled pseudo dropdown select field compatible with forms.
 */
export default class Select extends EventEmitter {

    /** @type {Element} */
    _element

    /** @type {Element} */
    _selectButton

    /** @type {Element} */
    _listElement

    /** @type {string} */
    _value

    /**
     * Hydrate a server rendered Select component.
     * @param {Element} element A DOM element to hydrate with the required structure
     */
    hydrate(element) {
        if (!element) throw Error('Element is ' + element + '.')

        this._element = element
        this._selectButton = element.querySelector('.select-dropdown-button')
        this._listElement = element.querySelector('.dropdown-list')

        if (!this._selectButton) {
            throw Error('The HTML to hydrate has an invalid structure.')
        }

        this._setupClickHandlers()
        this._setupListToggling()
        this._initValue()
    }

    /**
     * Setup click handlers for the dropdown list items that trigger a change event and
     * update the component state.
     */
    _setupClickHandlers() {
        const items = /** @type {NodeListOf<HTMLLIElement>} */ (this._element.querySelectorAll('.dropdown-item'))
        items.forEach(item => {
            const clickEl = (item.querySelector('a'))

            // Click on an <a> will be translated to a change of the select
            clickEl.addEventListener('click', e => {
                e.preventDefault()

                // Get selected value and only continue if it differs
                const value = item.dataset.value
                if (value === this._value) return

                // UI behavior
                this._selectButton.innerHTML = clickEl.innerHTML + '<i class="i-caret-down i-sm"></i>'
                this._element.querySelector('.active').classList.remove('active')
                item.classList.add('active')

                // Update value and dispatch event
                this._value = value
                this._dispatchEvent('change')
            })
        })
    }

    /**
     * Setup controlled toggling of the dropdown menu.
     */
    _setupListToggling() {
        // Toggle on click events
        this._element.addEventListener('click', () => {
            this._listElement.classList.toggle('hidden')
        })

        // Close on click outside
        document.addEventListener('click', e => {
            if (this._element !== e.target && !this._element.contains(/** @type {Node} */ (e.target))) {
                this._listElement.classList.add('hidden')
            }
        })
    }

    /**
     * Find the initial value.
     */
    _initValue() {
        const activeElement = /** @type {HTMLLIElement} */ (this._element.querySelector('li.active'))
        if (activeElement) {
            this._value = activeElement.dataset.value
        }
    }

    /** @type {string} */
    get value() { return this._value }

}
