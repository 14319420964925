/**
 * Calls an async function the first time it is required and caches the result.
 */
export default class LazyPromise {

    /** @type {() => Promise} */
    _asyncCallback

    /** @type {Promise} */
    _promise

    /**
     * @param {() => Promise} asyncCallback Async function to call lazily
     */
    constructor(asyncCallback) {
        this._asyncCallback = asyncCallback
    }

    /**
     * Get the promise result of the lazy function.
     */
    getPromise() {
        if (!this._promise) {
            this._promise = this._asyncCallback()
        }
        return this._promise
    }

}
