import scrolling from '../scrolling'

const modals = {}
let active = null
let initialized = false
let backdrop
let container

function init() {
    initBackdrop()
    initContainer()
    initialized = true
}

function initBackdrop() {
    backdrop = document.getElementById('modal-backdrop')
    if (backdrop) {
        backdrop.classList.remove('hidden')
    } else {
        backdrop = document.createElement('div')
        backdrop.id = 'modal-backdrop'
        document.body.append(backdrop)
    }
}

function initContainer() {
    container = document.getElementById('modals-container')
    if (!container) {
        container = document.createElement('div')
        container.id = 'modals-container'
        container.addEventListener('keyup', e => {
            if (e.key === 'Escape') close()
        })
        document.body.append(container)
    }
}

export function set(id, modal) {
    if (!initialized) init()
    modals[id] = modal
    container.append(modal.node)
}

export function get(id) {
    return modals[id]
}

export function show(id) {
    if (!modals[id] || (active && active.id === id)) return
    if (!initialized) init()
    if (active) active.close()

    scrolling.disable()

    document.body.classList.add('modal-open')
    backdrop.classList.remove('fade-out')
    backdrop.classList.add('fade-in')
    active = modals[id]
    active.show()
}

export function close() {
    if (active) {
        active.close()
        active = null

        scrolling.enable()

        document.body.classList.remove('modal-open')
        backdrop.classList.remove('fade-in')
        backdrop.classList.add('fade-out')
    }
}

export function patch(id, options = {}) {
    if (modals[id]) {
        modals[id].patch(options)
    }
}
