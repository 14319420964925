import moment from 'moment'
import { logError } from '@bikemap/js/utility/helpers'

const appName = process.env.REACT_APP_NAME || 'bikemap'

export const STORAGE_KEY_ACCESS_TOKEN_DATA = 'accessTokenData'
export const STORAGE_KEY_PROMOTION_MODAL_SHOWN_AT = 'promotionModalShownAt'
export const STORAGE_KEY_CONVERSION_BANNER_HIDDEN = 'conversionBannerHidden'
export const STORAGE_KEY_PREMIUM_CONVERSION_BANNER_HIDDEN = 'premiumConversionBannerHidden'
export const STORAGE_KEY_PREMIUM_HINT_SEEN = 'premiumHintSeen'
export const STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN = 'postRegisterPremiumHintCountdown'
export const STORAGE_KEY_DISPLAY_SETTINGS = 'displaySettings'
export const STORAGE_KEY_UNSUPPORTED_BROWSER_DISMISSED = 'unsupportedBrowserDismissed'
export const STORAGE_KEY_SUBSCRIPTION_EXPIRES_MESSAGE_SEEN = 'subscriptionExpiresMessageSeen'
export const STORAGE_KEY_SUBSCRIPTION_PAST_DUE_MESSAGE_SEEN = 'subscriptionPastDueMessageSeen'
export const STORAGE_KEY_COOKIE_PREFERENCES = 'cookiePreferences'
export const STORAGE_KEY_NATIVE_APP_INSTALL_BANNER_HIDDEN = 'nativeAppInstallBannerHidden'

const abandonedKeys = [
    'cookieConsent',
    'legalUpdate',
]

export class Storage {

    /**
     * Get item from storage.
     * @param {string} key
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @returns {string|void}
     */
    static getItem(key, isTmp = false) {
        try {
            if (isTmp) {
                return sessionStorage.getItem(appName + '-' + key)
            }
            return localStorage.getItem(appName + '-' + key)
        } catch (e) {
            logError('Get storage item', e, { key, isTmp })
        }
    }

    /**
     * Save item to storage.
     * @param {string} key
     * @param {string} value Objects or arrays can be saved as JSON strings
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @returns {true|void}
     */
    static setItem(key, value, isTmp = false) {
        try {
            if (isTmp) {
                sessionStorage.setItem(appName + '-' + key, value)
                return true
            }
            localStorage.setItem(appName + '-' + key, value)
            return true
        } catch (e) {
            logError('Set storage item', e, { key, isTmp })
        }
    }

    /**
     * Remove item from storage.
     * @param {string} key
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @return {true|void}
     */
    static removeItem(key, isTmp = false) {
        try {
            if (isTmp) {
                sessionStorage.removeItem(appName + '-' + key)
                return true
            }
            localStorage.removeItem(appName + '-' + key)
            return true
        } catch (e) {
            logError('Remove storage item', e, { key, isTmp })
        }
    }

    /**
     * Save item with current timestamp (in seconds).
     * @param {string} key
     * @param {boolean|string|number|Object} [value] Optional data to be saved alongside current timestamp
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @returns {true|void}
     */
    static setWithTimestamp(key, value, isTmp = false) {
        const timestampedValue = {
            timestamp: moment().format(),
            ...(value !== undefined ? { value } : {}),
        }
        return this.setItem(key, JSON.stringify(timestampedValue), isTmp)
    }

    /**
     * Check if item exists and it didn't expire.
     * @param {string} key Item previously set with this.setWithTimestamp()
     * @param {Object} expiresIn Value/s to subtract from current timestamp,
     *  as explained in [moment documentation](https://momentjs.com/docs/#/manipulating/subtract/)
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @returns {boolean} True if still valid
     */
    static isNotExpired(key, expiresIn, isTmp = false) {
        try {
            const item = JSON.parse(this.getItem(key, isTmp))
            // Missing item or timestamp
            if (!(item && item.timestamp)) {
                return false
            }
            // Still not expired
            return moment(item.timestamp).diff(moment().subtract(expiresIn)) > 0
        } catch (e) {
            logError('Is not expired item', e, { key, isTmp })
            return false
        }
    }

    /**
     * Get value of item that didn't expire.
     * @param {string} key Item previously set with this.setWithTimestamp()
     * @param {Object} expiresIn Value/s to subtract from current timestamp,
     *  as explained in [moment documentation](https://momentjs.com/docs/#/manipulating/subtract/)
     * @param {boolean} isTmp True if we're using temporary/session storage
     * @returns {boolean|string|number|Object} Value of saved item or false if expired
     */
    static getNotExpired(key, expiresIn, isTmp = false) {
        if (this.isNotExpired(key, expiresIn, isTmp)) {
            const item = JSON.parse(this.getItem(key, isTmp)) || {}
            return item.value
        }
        return false
    }

}

abandonedKeys.forEach(Storage.removeItem)
