import moment from 'moment'

import { PoiVote } from '../../entities'

/**
 * @param {Object} res Response data
 * @returns {PoiVote}
 */
export default function createPoiVoteFromResponse(res) {
    const vote = new PoiVote

    vote.id = res.id
    vote.poi = res.poi
    vote.user = res.user
    if (res.created) {
        vote.created = moment(res.created)
    }
    if (res.modified) {
        vote.modified = moment(res.modified)
    }
    vote.score = res.score

    return vote
}
