import { logError } from '@bikemap/js/utility'
import { counters } from '@bikemap/js/api'

// Element to be replaced with value from the API response
export const TOTAL_COUNT_CLASS = 'total-count-update'
// Element's attribute defining which counter to take from API response
export const TOTAL_COUNT_KEY_ATTR = 'data-replace-with'

/**
 * This will:
 * - try to find counters on the page (DOM elements),
 * - fetch non-cached total counters from API, and
 * - update content in DOM.
 * @param {HTMLElement} [container] optional DOM node to search inside
 * @returns {Promise<void>}
 */
const replaceCounters = async (container = null) => {
    try {
        // No need to fetch counters from API if there are no counters on the page to be replaced
        const counterElements = (container || document).getElementsByClassName(TOTAL_COUNT_CLASS)
        if (!(counterElements && counterElements.length > 0)) {
            return
        }

        // Get counters from API
        const res = await counters.read()
        if (!res) {
            return
        }

        for (let i = 0; i < counterElements.length; i++) {
            const el = counterElements.item(i)
            const counterKey = el.getAttribute(TOTAL_COUNT_KEY_ATTR)
            if (!(
                counterKey &&
                Object.prototype.hasOwnProperty.call(res, counterKey) &&
                res[counterKey].length > 0
            )) {
                continue
            }

            // Actual value replacement
            el.innerHTML = res[counterKey]
        }
    } catch (e) {
        logError('Fetch and replace total counts', e)
    }
}

export default replaceCounters
