import React, { useEffect, useState } from 'react'

import {
    ADMIN_URL, APPS_URL, HELP_CENTER_URL, LOGGED_IN_COOKIE, LOGIN_URL, LOGOUT_URL, POIBOY_URL, PREMIUM_URL,
    REGIONS_URL, ROUTE_CREATE_URL, URL_BASE,
} from '@bikemap/js/settings'
import { showLoginModal } from '@bikemap/js/integrations/authentication'
import { initWebAppUrls } from '@bikemap/js/integrations/global/web-app-links'
import { PageHeader } from '@bikemap/components/PageHeader'
import { DropdownList, DropdownOptionItem } from '@bikemap/components/Dropdown'
import { user as userService, gettext } from '@bikemap/js/services'
import { eraseCookie } from '@bikemap/js/utility'
import scrolling from '@bikemap/js/services/scrolling'
import sessionData from '@bikemap/js/services/sessionData'
import { initSupportChat } from '@bikemap/js/integrations/support'

/**
 * Page header component connected to the app's business logic.
 * @param {Object} props
 * @param {React.ReactNode} [props.children]
 * @param {() => React.ComponentType} [props.renderStaffActions]
 * @param {() => void} [onCancel]
 */
const ConnectedPageHeader = ({ children, renderStaffActions, renderNewRouteActions, onCancel }) => {
    const admin = gettext('Admin interface')
    const poiboy = gettext('POIBOY')

    const urls = {
        root: URL_BASE,
        login: LOGIN_URL,
        editor: ROUTE_CREATE_URL,
        premiumCta: PREMIUM_URL + '?utm_source=website&utm_medium=header&utm_campaign=header_go_premium_cta',
        premiumMenuItem: PREMIUM_URL + '?utm_source=website&utm_medium=user_dropdown&utm_campaign=go_premium_user_dropdown',
        help: HELP_CENTER_URL,
        logout: LOGOUT_URL,
        apps: APPS_URL,
        regions: REGIONS_URL,
    }

    const [loggedIn, setLoggedIn] = useState(true)
    const [user, setUser] = useState(null)

    useEffect(() => {
        sessionData.isLoggedIn().then(setLoggedIn)
    }, [])

    useEffect(() => {
        if (loggedIn) {
            userService.getEntity().then(setUser)
        }
    }, [loggedIn])

    const pageHeaderProps = {
        urls,
        onLoginClick: () => { showLoginModal() },
        onLogoutClick: () => {
            eraseCookie(LOGGED_IN_COOKIE)
            window.location.href = LOGOUT_URL
        },
        loggedIn,
        user,
        onHelp: () => { initSupportChat() },
        onNavOpen: () => { scrolling.disable() },
        onNavClose: () => { scrolling.enable() },
        renderStaffListGroup: () => (
            <DropdownList.Group>
                {renderStaffActions && renderStaffActions()}
                <DropdownOptionItem href={ADMIN_URL} icon="i-admin" label={admin} />
                <DropdownOptionItem href={POIBOY_URL} icon="i-location-edit" label={poiboy} />
            </DropdownList.Group>
        ),
        renderNewRouteActions,
        onInit: initWebAppUrls,
        onCancel,
    }

    return (
        <PageHeader {...pageHeaderProps}>
            {children}
        </PageHeader>
    )
}

export default ConnectedPageHeader
