import moment from 'moment'

/**
 * Represents a real-time POI.
 */
class Poi {

    /** @type {number} */
    id

    /** @type {number} */
    user

    /** @type {moment.Moment} */
    created = moment()

    /** @type {moment.Moment} */
    modified

    /** @type {number} */
    category

    /** @type {number} */
    scoreNeg

    /** @type {number} */
    scorePos

    /** @type {string} */
    address

    /** @type {moment.Moment} */
    ttl

    /** @type {import('@bikemap/js/map').LngLat} */
    coords

    /** @type {string} */
    description = ''

    /** @type {string} */
    image

    /** @type {boolean} */
    reviewed

    /** @type {boolean} */
    active

    /** @type {boolean} */
    isSpam

    /** @type {number} */
    commentCount

    /** @type {number} */
    mergedInto

    /**
     * Get the coordinates as GeoJSON.
     * @param {import('@bikemap/js/map').LngLat} [value] Optional arbitrary value
     */
    getGeometry(value) {
        return {
            type: 'Point',
            coordinates: value || this.coords,
        }
    }

    /**
     * Serialize the coords property.
     * @param {import('@bikemap/js/map').LngLat} [value] Optional arbitrary value
     * @returns {string}
     */
    getSerializedCoords(value) {
        return JSON.stringify(this.getGeometry(value))
    }

}

/**
 * @param {Poi} poi
 * @returns {Poi}
 */
Poi.copy = function (poi) {
    return Object.assign(new Poi, poi)
}

export default Poi
