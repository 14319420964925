import { createFormErrorHandler, defaultPost } from '../../utility'
import { gettext, modals, notifications } from '../../services'
import { initPasswordForm } from './password'
import { initRegistrationForm } from './registration'
import { initLoginFormBehavior } from './login'
import { LOGIN_URL } from '../../settings'
import replaceCounters from '@bikemap/js/integrations/counters'

function showSignupModal(fetchedOptions) {
    modals.show('signup', Object.assign({}, fetchedOptions, {
        title: gettext('Welcome to Bikemap'),
        lead: gettext('Enter your information for your new Bikemap account'),
        focus: fetchedOptions.email ? '#id_displayname' : '#reg_id_email',
        form: false,
        init: modal => {
            initRegistrationForm(modal.body.querySelector('#register'))
        },
    }), true)
}

function showPasswordModal(fetchedOptions) {
    modals.show('password', Object.assign({}, fetchedOptions, {
        className: 'full',
        title: gettext('Welcome back!'),
        focus: '#id_password',
        form: false,
        init: modal => {
            initPasswordForm(modal.body.querySelector('#password-login'), fetchedOptions.password_placeholder)
        },
    }), true)
}

function handleLoginModalSubmit(e, modal) {
    modal.setLoading()
    const form = modal.body.querySelector('#login')

    defaultPost(LOGIN_URL, new FormData(form))
        .then(createFormErrorHandler(form))
        .then(res => {
            const data = res.callback_params
            if (data) {
                if (data.modal_name === 'multi_login' || data.reload) {
                    window.location.href = data.url
                }
                if (data.modal_id === '#sign-up') showSignupModal(data)
                if (data.modal_id === '#password') showPasswordModal(data)
            }
        })
        .finally(() => modal.setLoading(false))
}

/**
 * Show the login modal.
 * @param {Object} [patchOptions] Optional patch options for the modal to override the default
 */
export function showLoginModal(patchOptions = {}) {
    const defaultOptions = {
        header: '<div class="modal-logo">' +
                    '<svg class="bikemap-logo"><use xlink:href="#svg-bikemap-logo" /></svg>' +
                '</div>',
        title: null,
        onShow: (e, modal) => {
            replaceCounters(modal.node)
        },
    }

    const modalOptions = Object.assign({}, defaultOptions, patchOptions)

    if (patchOptions) {
        modalOptions.onClose = (e, modal) => modal.patch(defaultOptions)
    }

    if (modals.get('login')) {
        modals.show('login', Object.keys(patchOptions).length ? modalOptions : undefined)
    } else {
        modals.load('login', {
            url: LOGIN_URL + '?next_url=' + encodeURI(location.pathname),
        }, Object.assign(modalOptions, {
            form: false,
            focus: '#id_identity', // otherwise input will lose focus immediately
            init: modal => initLoginFormBehavior(modal.body.querySelector('#login')),
            onSubmit: handleLoginModalSubmit,
            onClose: (e, modal) => {
                const emailField = modal.body.querySelector('#id_identity')
                emailField.value = ''
            },
        }))
    }

    dispatchEvent(new CustomEvent('authmodal.open'))
}

function continueWithPassword(e, modal) {
    modal.setLoading()
    defaultPost(modal.content.action, new FormData(modal.content))
        .then(res => {
            const data = res.callback_params
            if (data) showPasswordModal(data)
        })
        .catch(res => notifications.error(res.statusText))
        .finally(() => modal.setLoading(false))
}

/**
 * Show a modal to select the auth method for an account.
 * @param {Object} options
 * @param {number|string} options.profile The user profile ID
 * @param {string} options.facebook The URL for Facebook
 * @param {string} options.google The URL for Google
 * @param {string} options.apple The URL for Apple
 * @param {string} options.username The username of the profile
 * @param {string} options.action The URL for email login
 */
export function showLoginMethodModal({ profile, facebook, google, apple, username, action }) {
    const getAuthMethodButton = (name, url, text) => (
        '<a class="btn btn-' + name + ' btn-block" href="' + url + '" rel="nofollow">' +
            '<i class="i-' + name + '"></i><span>' + text + '</span>' +
        '</a>'
    )

    let body = ''

    if (facebook) {
        body += getAuthMethodButton('facebook', facebook, gettext('Continue with Facebook'))
    }
    if (google) {
        body += getAuthMethodButton('google', google, gettext('Continue with Google'))
    }
    if (apple) {
        body += getAuthMethodButton('apple', apple, gettext('Continue with Apple'))
    }
    if (username) {
        body += '<button class="btn btn-default btn-block">' +
                    '<i class="i-email"></i><span>' + gettext('Continue with your mail') + '</span>' +
                '</a>' +
                '<input type="hidden" name="identity" value="' + username + '">'

        const nextUrlMatches = location.search.match(/next=([^&]+)/)
        if (nextUrlMatches && nextUrlMatches[1]) {
            body += '<input type="hidden" name="next_url" value="' + decodeURIComponent(nextUrlMatches[1]) + '">'
        }
    }

    modals.show('login-method-' + profile, {
        title: gettext('Choose authentication method'),
        body,
        form: { action },
        onSubmit: continueWithPassword,
    })
}
