import React from 'react'
import { render } from 'react-dom'

import { TranslationProvider } from '@bikemap/components/_translation'
import { MultiSearch } from '@bikemap/js/integrations/geolocation'
import { gettext } from '@bikemap/js/services'
import { logError } from '@bikemap/js/utility'

import ConnectedPageHeader from './components/ConnectedPageHeader.jsx'

/**
 * @typedef {Object} PageHeaderInitializationConfig
 * @property {() => import("react").ComponentType} [renderPageHeaderContent] Render alternative content into the page header
 * @property {() => import("react").ComponentType} [renderStaffActions] Render additional user menu items for staff users
 * @property {() => import("react").ComponentType} [renderNewRouteActions] Render additional items in the new route dropdown
 */

/**
 * Initialize the fully functional PageHeader component.
 * @param {PageHeaderInitializationConfig} [config]
 */
export function initPageHeader(config = {}) {
    const { renderPageHeaderContent, renderStaffActions, renderNewRouteActions } = config
    const container = document.getElementById('page-header')
    if (container) {
        render(
            (
                <TranslationProvider translate={gettext}>
                    <ConnectedPageHeader renderStaffActions={renderStaffActions}
                        renderNewRouteActions={renderNewRouteActions}>
                        {renderPageHeaderContent ? renderPageHeaderContent() : <MultiSearch />}
                    </ConnectedPageHeader>
                </TranslationProvider>
            ),
            container
        )
    } else {
        logError('No page header container found')
    }
}

/**
 * Initialize the page header with a standard cancel button.
 * @param {Object} [options={}]
 * @param {string} [options.url] An optional, specific URL the cancel button should link to
 * @param {() => void} [options.onCancel] Handler function to be called on cancel before redirect
 */
export function initCancelPageHeader(options = {}) {
    const { url, onCancel } = options
    const cancelButtons = document.querySelectorAll('#page-header .js-cancel-page')

    if (onCancel) {
        cancelButtons.forEach(button => {
            button.addEventListener('click', () => { onCancel() })
        })
    }

    if (url) {
        cancelButtons.forEach(btn => {
            btn.setAttribute('href', url)
        })
    } else if (canGoBackToDifferentBikemapPage()) {
        cancelButtons.forEach(button => {
            button.addEventListener('click', handleCancelClick)
        })
    }
}

/**
 * Whether the previous page in browser history is another page on our site, which
 * doesn't redirect to the current page.
 * @returns {boolean}
 */
function canGoBackToDifferentBikemapPage() {
    return (
        history.length > 1 &&
        (
            !document.referrer ||
            (
                referrerIsDifferentPage() &&
                !referrerWillRedirectBack() &&
                (document.referrer.includes('bikemap.net') || document.referrer.includes(location.hostname))
            )
        )
    )
}

/**
 * @returns {boolean}
 */
function referrerIsDifferentPage() {
    return (
        document.referrer &&
        !window.location.href.startsWith(document.referrer)
    )
}

/**
 * @returns {boolean}
 */
function referrerWillRedirectBack() {
    const referrerNextMatch = document.referrer && document.referrer.match('[?&]next=([^&]+)')
    return (
        referrerNextMatch &&
        window.location.href.includes(decodeURIComponent(referrerNextMatch[1]))
    )
}

function handleCancelClick(e) {
    e.preventDefault()
    history.back()
}
