import { USER_LOCATION_URL } from '@bikemap/js/settings'
import { defaultGet, LazyPromise } from '@bikemap/js/utility'
import { user } from '@bikemap/js/api'
import sessionData from './sessionData'

/**
 * @typedef {Object} LocationGuess
 * @property {number[]} location Location as [lat, lng]
 * @property {number} zoom Zoom level
 */

/**
 * Service for information about the active user.
 */
class UserService {

    _lazyUserEntity = new LazyPromise(this._fetchUserEntity.bind(this))

    _lazyLocation = new LazyPromise(this._fetchLocation.bind(this))

    /** @type {LocationGuess|null} */
    _location = null

    /**
     * Current synchronous information about the (guessed) user location.
     * @type {LocationGuess|null}
     */
    get location() { return this._location }

    /**
     * Get the current user's own user entity if logged in.
     * @returns {Promise<import("@bikemap/js/entities").User|null>}
     */
    async getEntity() {
        if (await sessionData.isLoggedIn()) {
            return this._lazyUserEntity.getPromise()
        }
        return null
    }

    /**
     * Get the (guessed) user location.
     * @returns {Promise<LocationGuess>}
     */
    getLocation() {
        return this._lazyLocation.getPromise()
    }

    /**
     * Shorthand to determine whether the current user is an authenticated premium user.
     * @returns {Promise<boolean>}
     */
    async getIsPremium() {
        const userEntity = await this.getEntity()
        return !!(userEntity && userEntity.isPremium)
    }

    /**
     * Fetch data about the current user from server.
     * @returns {Promise<import("@bikemap/js/entities").User>}
     */
    async _fetchUserEntity() {
        const [userEntity, { isStaff }] = await Promise.all([
            user.read('self', 'referral-code,referral-rewards-loop'),
            sessionData.getSessionData(),
        ])

        // Complete user entity with data not returned by the API endpoint
        userEntity.isStaff = isStaff

        return userEntity
    }

    /**
     * Fetch a guessed user location from server.
     * @returns {Promise<LocationGuess>}
     */
    async _fetchLocation() {
        this._location = await defaultGet(USER_LOCATION_URL)
        return this._location
    }

}

export default new UserService
