/**
 * Base class providing event emitter functionality. A previous solution as mixin
 * didn't work well with JSDoc.
 */
export default class EventEmitter {

    /**
     * @callback eventHandler
     * @param {CustomEvent} e The event object
     */

    /** @type {Object} */
    _eventListeners = {}

    /**
     * Add an event listener for a certain type of event.
     * @param {string} type The event type
     * @param {eventHandler} handler The event handler, called whenever the event occurs
     */
    addEventListener(type, handler) {
        if (!this._eventListeners[type]) {
            this._eventListeners[type] = [handler]
        } else {
            this._eventListeners[type].push(handler)
        }
    }

    /**
     * Remove an event listener by its handler function.
     * @param {string} type The event type
     * @param {eventHandler} handler The event handler function, that should be removed
     */
    removeEventListener(type, handler) {
        if (this._eventListeners[type]) {
            this._eventListeners[type].filter(h => h !== handler)
        }
    }

    /**
     * Dispatch an event type to call all registered handlers.
     * @param {string} type The event type
     * @param {Object|null} [event] An event Object
     * @param {Object} [detail] Data to pass with the event. Used only if event property is empty.
     */
    _dispatchEvent(type, event = null, detail = {}) {
        if (this._eventListeners[type]) {
            this._eventListeners[type].forEach(handler => handler(event || new CustomEvent(type, { detail })))
        }
    }

}
