import './SelectDropdownButton.scss'

import React from 'react'

/**
 * Button with input-like design indicating dropdown functionality.
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick]
 */
const SelectDropdownButton = ({ children, onClick }) => (
    <button className="select-dropdown-button" onClick={onClick}>
        {children}
        <i className="i-caret-down i-sm" />
    </button>
)

export default SelectDropdownButton
