import { LOGIN_URL } from '@bikemap/js/settings'
import { createFormErrorHandler, defaultPost, handleRedirect } from '../../utility'

/**
 * Handler for the submit event of login forms, that makes the call.
 * @param {Event} e Submit event
 */
function handleLoginSubmit(e) {
    e.preventDefault()

    const loginForm = /** @type {HTMLFormElement} */ (e.target)
    /** @type {HTMLButtonElement} */
    const submitBtn = loginForm.querySelector('[type="submit"]')
    submitBtn.disabled = true
    submitBtn.classList.add('loading-spinner')

    defaultPost(LOGIN_URL, new FormData(loginForm))
        .then(createFormErrorHandler(loginForm))
        .then(handleRedirect)
        .finally(() => {
            submitBtn.disabled = false
            submitBtn.classList.remove('loading-spinner')
        })
}

/**
 * Initialize the common behavior of an email login form.
 * @param {HTMLFormElement} form The login form element
 * @param {boolean} primary Login form is the primary content of the page
 */
export function initLoginFormBehavior(form, primary) {
    /** @type {HTMLButtonElement} */
    const submitBtn = form.querySelector('[type="submit"]')
    /** @type {HTMLInputElement} */
    const input = form.querySelector('#id_identity')

    if (primary) {
        input.focus()
    }

    // Disable submit button when input is empty
    submitBtn.disabled = !input.value.length
    input.addEventListener('input', e => {
        submitBtn.disabled = !input.value.length
    })
}

/**
 * Initialize functionality of an email login form with the given ID,
 * if it exists.
 * @param {string} id ID of the login <form> element
 * @param {boolean} [secondary=true] Login form is the primary content of the page
 */
export function initLoginFormById(id, primary = true) {
    const form = document.getElementById(id)
    if (form) {
        initLoginFormBehavior(/** @type {HTMLFormElement} */ (form), primary)
        form.addEventListener('submit', handleLoginSubmit)
    }
}
