import { logError } from '@bikemap/js/utility/helpers'
import ApiService from '../ApiService'

/**
 * @typedef {Object} Notification
 * @property {number} id
 * @property {string} title
 * @property {string} [body]
 * @property {string} [identifier]
 * @property {string} [created]
 * @property {number} [points]
 * @property {string} [inviteeProfilePicture]
 */

/**
 * @typedef {'gamification'|'referral'} NotificationType
 */

class NotificationsApiService extends ApiService {

    /** @type {import("..").Endpoint} */
    listEndpoint

    /**
     * @param {PoiCommentEndpoints} endpoints
     */
    constructor(endpoints) {
        super(endpoints.detail)
        this.listEndpoint = endpoints.list

        this.list = this.list.bind(this)
        this.delete = this.delete.bind(this)
    }

    /**
     * Get the current list of notifications from the backend.
     * @param {number} [page]
     * @param {number} [pageSize]
     * @param {NotificationType} type
     * @returns {Promise<Notification[]|[]>}
     */
    async list(page, pageSize, type) {
        try {
            const res = await this.listEndpoint.get({
                queryParams: {
                    page: page || 1,
                    page_size: pageSize || 10,
                    type,
                },
            })

            if (res && res.results && Array.isArray(res.results) && res.results.length > 0) {
                const withRequiredFields = res.results.filter(notification => !!(notification.id && notification.title))
                return withRequiredFields.map(notification => {
                    return {
                        id: notification.id,
                        title: notification.title,
                        body: notification.body,
                        identifier: notification.identifier,
                        created: notification.created,
                        points: notification.points,
                        inviteeProfilePicture: notification.invitee_profile_picture,
                    }
                })
            }

            return []
        } catch (e) {
            logError('List backend notifications', e, { type })
            return []
        }
    }

    /**
     * Delete notification.
     * @param {number} id Notification ID
     * @param {NotificationType} type
     * @returns {Promise<boolean>}
     */
    async delete(id, type) {
        try {
            const res = await this.endpoint.delete({
                params: { id },
                queryParams: {
                    type,
                },
            })
            return !!res
        } catch (e) {
            logError('Delete backend notification', e, { id })
            return false
        }
    }

}

export default NotificationsApiService
