import gettext from '@bikemap/js/services/gettext'

export const CATEGORIES = {
    1: {
        label: gettext('Road bike'),
        key: 'roadbike',
        analytics: 'road bike',
    },
    2: {
        label: gettext('MTB'),
        key: 'mountainbike',
        analytics: 'mtb',
    },
    3: {
        label: gettext('City bike'),
        key: 'bike',
        analytics: 'city bike',
    },
}

export const SURFACES = {
    1: {
        label: gettext('Paved'),
        key: 'paved',
        analytics: 'paved',
    },
    2: {
        label: gettext('Unpaved'),
        key: 'unpaved',
        analytics: 'unpaved',
    },
    3: {
        label: gettext('Gravel'),
        key: 'gravel',
        analytics: 'gravel',
    },
}
