/*
Utilities related to cookies.
*/

/**
 * @param {string} name Cookie name
 * @param {string} value Cookie value
 * @param {number} days Number of days the cookie will be valid
 */
export function createCookie(name, value, days) {
    let expires
    if (days) {
        var date = new Date
        date.setTime(date.getTime() + days * 86400000) // In ms, 24h * 60min * 60s * 1000
        expires = ';expires=' + date.toUTCString()
    } else {
        expires = ''
    }

    document.cookie = name + '=' + value + expires + ';path=/'
}

/**
 * @param {string} name Name of the cookie
 * @returns {string|null} Value if the cookie exists or null otherwise
 */
export function readCookie(name) {
    return ('; ' + document.cookie).split('; ' + name + '=').pop().split(';').shift()
}

/**
 * @param {string} name Name of the cookie
 */
export function eraseCookie(name) {
    createCookie(name, '', -1)
}
