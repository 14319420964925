import { STATIC_URL } from '../settings'

/*
General global helper functions.
*/

/**
 * Try to get user location from geolocation API.
 * @returns {Promise<Object>}
 */
export function getClientLocation() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject)
    })
}

/**
 * Get the full path of an image.
 * @param filename File name or path relative to [staticBaseUrl]/img/
 * @returns {string} Full path to the image
 */
export function getImagePath(filename) {
    return STATIC_URL + 'img/' + filename
}

/**
 * Copy the given text to the clipboard.
 * @param {string} text The text to copy
 */
export async function copyToClipboard(text) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

/**
 * Extract base name and extension from file name or path.
 * @param {string} src Full file name or path
 * @return {[string|null, string|null]} Returns array containing base file name without extension and extension.
 */
export function getFileNameParts(src) {
    const defaultResponse = [null, null]
    try {
        const parts = src.match(/(.+?)(\.([^.]+)?$)/)
        const base = parts[1]
        const ext = parts[3]
        if ((base + '.' + ext) === src) {
            return [base, ext]
        }
        return defaultResponse
    } catch (e) {
        return defaultResponse
    }
}

/**
 * Central place to log errors to console log.
 * Can be even more useful later if we start using some error tracking (e.g., Sentry).
 * @param {string|null} [message] Short error description
 * @param {string|object|null} [error] Can be empty when we know just error message
 * @param {object|null} [details] Additional data to log
 * @returns {void}
 */
export function logError(message = '', error = null, details = null) {
    // If error not passed, create a new one to get stack trace and other useful info
    const getError = error || new Error('Bikemap unknown')

    // Simple console log
    console.error('[Bikemap]' + (message ? ' ' + message : '') + '\n', getError)
    if (details) {
        console.error('[Bikemap] [ErrorDetails] ', details)
    }
}

/**
 * Parse XML and return it as object/array structure.
 * @param {string} xml
 * @param {array} forceArrayTags Which XML tags to return as array instead of object
 * @return {Object}
 * @throws {Error}
 */
export function parseXml(xml, forceArrayTags) {
    if (!window.DOMParser) {
        throw new Error('Could not parse received XML')
    }
    const dom = (new DOMParser()).parseFromString(xml, 'text/xml')

    function parseNode(xmlNode, result) {
        if (xmlNode.nodeName === '#text') {
            const val = xmlNode.nodeValue
            if (val.trim()) {
                result['#text'] = val
            }
            return
        }

        const jsonNode = {}
        const existing = result[xmlNode.nodeName]
        if (existing) {
            if (!Array.isArray(existing)) {
                result[xmlNode.nodeName] = [existing, jsonNode]
            } else {
                result[xmlNode.nodeName].push(jsonNode)
            }
        } else {
            if (forceArrayTags && forceArrayTags.indexOf(xmlNode.nodeName) !== -1) {
                result[xmlNode.nodeName] = [jsonNode]
            } else {
                result[xmlNode.nodeName] = jsonNode
            }
        }
        if (xmlNode.attributes) {
            for (const attribute of xmlNode.attributes) {
                jsonNode[attribute.nodeName] = attribute.nodeValue
            }
        }

        // Process each XML tag (node) recursively
        for (const node of xmlNode.childNodes) {
            parseNode(node, jsonNode)
        }
    }

    const result = {}
    for (const node of dom.childNodes) {
        parseNode(node, result)
    }
    return result
}

/**
 * Try to get value from XML node.
 * @param {object} node XML node containing values.
 * @param {string} fieldName
 * @param {any} defaultResponse
 * @return {string}
 */
export function getParsedXmlValue(node, fieldName, defaultResponse = '') {
    try {
        if (node[fieldName] && node[fieldName]['#text']) {
            return node[fieldName]['#text']
        }
        return defaultResponse
    } catch (e) {
        return defaultResponse
    }
}
