import './DropdownItem.scss'

import React from 'react'

/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @param {string} [props.icon]
 * @param {boolean} [props.selected]
 */
const DropdownItem = ({ children, className, icon, selected }) => (
    <li className={'dropdown-item' + (selected ? ' selected' : '') + (className ? ' ' + className : '')}>
        {!!icon && <i className={icon + ' text-secondary i-sm'} />}
        {children}
    </li>
)

export default DropdownItem
