import ApiService from '@bikemap/js/api/ApiService'

export default class DeepLinkApiService extends ApiService {

    /**
     * Fetch a deep link for certain parameters.
     * @param {string} url
     * @param {Object} [params]
     * @param {boolean} [params.redirect]
     * @param {"facebook"|"twitter"} [params.socialShare]
     * @param {string} [params.utmSource]
     * @param {string} [params.utmMedium]
     * @param {string} [params.utmCampaign]
     * @param {boolean} [params.efr]
     * @param {string} [params.sd]
     * @param {string} [params.st]
     * @param {string} [params.si]
     * @returns {Promise<string>}
     */
    getUrl = async (url, params = {}) => {
        const { socialShare, utmSource, utmMedium, utmCampaign, efr, redirect, ...rest } = params

        const queryParams = {
            url,
            social_share: socialShare,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
            ...rest,
        }

        if (redirect) {
            queryParams.redirect = 'True'
        }

        if (efr) {
            queryParams.efr = 1
        }

        const res = await this.endpoint.get({
            queryParams,
        })

        return res.deep_link
    }

}
