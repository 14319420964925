import moment from 'moment'
import '@bikemap/js/legacy/resend-confirmation-mail'
import { notifications } from '@bikemap/js/services'
import {
    Storage,
    STORAGE_KEY_PREMIUM_HINT_SEEN,
    STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN,
    STORAGE_KEY_SUBSCRIPTION_EXPIRES_MESSAGE_SEEN,
    STORAGE_KEY_SUBSCRIPTION_PAST_DUE_MESSAGE_SEEN,
} from '@bikemap/js/utility'
import { PREMIUM_HINT_COOLDOWN, STATIC_URL } from '@bikemap/js/settings'
import { notification, notifications as notificationsApi } from '@bikemap/js/api'
import sessionData from '@bikemap/js/services/sessionData'

/**
 * Get messages from the back end and show them as notifications.
 */
export async function initBackendMessages() {
    const messages = await notification.list(window.location.pathname)

    if (await sessionData.isLoggedIn()) {
        incrementPremiumHintCooldown()
    } else {
        Storage.removeItem(STORAGE_KEY_PREMIUM_HINT_SEEN)
    }

    if (messages) {
        messages.forEach(showMessage)
    }

    if (await sessionData.isLoggedIn()) {
        const referralMessages = await notificationsApi.list(1, 3, 'referral')
        if (referralMessages && referralMessages.length > 0) {
            referralMessages.forEach(showReferralMessage)
        }
    }
}

/**
 * Increment an existing premium cooldown in local storage.
 */
function incrementPremiumHintCooldown() {
    const cooldown = Storage.getNotExpired(STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN, { days: 5 })
    if (cooldown !== false) {
        Storage.setWithTimestamp(STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN, cooldown + 1)
    }
}

/**
 * Show a message from the back end as notification.
 * @param {import("@bikemap/js/services/notifications").NotificationOptions} message
 */
function showMessage(message) {
    switch (message.name) {
        case 'premium-trial':
            return showPremiumUpsellMessage(message)
        case 'subscription-expires':
            return showSubscriptionExpiresMessage(message)
        case 'subscription-past-due':
        case 'subscription-past-due-stripe':
            return showSubscriptionPastDueMessage(message)
        default:
            notifications.show(message)
    }
}

/**
 * @param {import("@bikemap/js/services/notifications").NotificationOptions} message
 */
async function showPremiumUpsellMessage(message) {
    const cooldown = Storage.getNotExpired(STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN, { days: 5 })
    if (cooldown !== false && cooldown < PREMIUM_HINT_COOLDOWN) {
        return // don't show if in cooldown
    }

    showMessagePeriodically(message, STORAGE_KEY_PREMIUM_HINT_SEEN, { days: 5 })

    // Restart premium cooldown as soon as premium notification is shown
    Storage.removeItem(STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN)
}

/**
 * @param {import("@bikemap/js/services/notifications").NotificationOptions} message
 */
function showSubscriptionExpiresMessage(message) {
    showMessagePeriodically(message, STORAGE_KEY_SUBSCRIPTION_EXPIRES_MESSAGE_SEEN, { days: 1 })
}

/**
 * @param {import("@bikemap/js/services/notifications").NotificationOptions} message
 */
function showSubscriptionPastDueMessage(message) {
    showMessagePeriodically(message, STORAGE_KEY_SUBSCRIPTION_PAST_DUE_MESSAGE_SEEN, { days: 1 })
}

/**
 * Show a message as notification only if a given time period has passed since it has
 * been seen (closed) the last time.
 * @param {import("@bikemap/js/services/notifications").NotificationOptions} message
 * @param {string} storageKey
 * @param {Object} period Moment Object, as explained in [the docs](https://momentjs.com/docs/#/parsing/object/)
 */
function showMessagePeriodically(message, storageKey, period) {
    if (Storage.isNotExpired(storageKey, period)) {
        return // already shown within the given period
    }

    const notification = notifications.show(message)

    notification.addCloseHandler(() => {
        // Mark as seen
        Storage.setWithTimestamp(storageKey)
    })
}

/**
 * Show referral notification from the backend.
 * @param {import("@bikemap/js/api/services/NotificationsApiService").Notification} message
 */
function showReferralMessage(message) {
    /** @type {import("@bikemap/js/services/notifications").NotificationOptions} */
    const options = {
        title: message.title,
        message: message.body,
        imageUrl: message.inviteeProfilePicture || (STATIC_URL + 'img/profiles/avatar-illu.svg'),
        imageBadgeUrl: STATIC_URL + 'img/profiles/premium-badge-outlined.svg',
        footerLeft: message.created ? moment(message.created).fromNow() : '',
        footerRight: message.points ? ('+' + message.points + '<i class="dot"></i>') : '',
        type: message.identifier === 'referral-success-is-premium' ? 'primary' : 'premium',
        delay: 0,
        onClose: () => notificationsApi.delete(message.id, 'referral'),
    }
    notifications.show(options)
}
