const PRODUCTION_DOMAIN = 'www.bikemap.net'
const DEVELOPMENT_DOMAIN = 'development.bikemap.net'

/**
 * @typedef {"PRODUCTION"|"DEVELOPMENT"|"LOCAL"} EnvironmentType
 */

export const ENV = (
    (location.href.includes(PRODUCTION_DOMAIN) && 'PRODUCTION') ||
    (location.href.includes(DEVELOPMENT_DOMAIN) && 'DEVELOPMENT') ||
    'LOCAL'
)
