import './CheckboxField.scss'

import React from 'react'

/**
 * A checkbox wrapped in a label.
 * @param {Object} props
 * @param {string} [props.className] Additional classes
 * @param {boolean} props.value Current values
 * @param {string} props.name Name of the form element
 * @param {(value: boolean, name: string) => void} props.onChange
 * @param {string} [props.label] Label text of the form field
 * @param {React.ReactNode} [props.children] Content of the label
 * @param {boolean} [props.disabled]
 */
const CheckboxField = ({
    className, onChange, name, value, label, children, disabled,
}) => {
    let classes = 'checkbox-field'
    if (disabled) classes += ' disabled'
    if (className) classes += ' ' + className

    const id = name + '-field'

    return (
        <label className={classes} htmlFor={id}>
            <i className="i-check" />
            <input id={id} name={name} type="checkbox" checked={value} disabled={disabled}
                onChange={e => onChange(e.target.checked, name)} />
            {children || label}
        </label>
    )
}

export default CheckboxField
