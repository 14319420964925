import {
    USER_PROFILE_URL, USER_ROUTES_URL, USER_ROUTE_COLLECTIONS_URL, USER_SETTINGS_URL,
    USER_MEMBERSHIP_URL, PREMIUM_PLAN_PERIODS,
} from '../settings'
import { logError } from '../utility'

/**
 * Represents a user with all it's properties and utility methods.
 */
class User {

    /** @type {number} */
    id

    /** @type {string} */
    slug

    /** @type {string} */
    name

    /** @type {string} */
    avatar

    /** @type {string} */
    cover

    /** @type {boolean} */
    isPremium

    /** @type {boolean} */
    isReferralPremium

    /** @type {string} */
    biography

    /** @type {number} */
    favoriteRoutesCount

    /** @type {number} */
    plannedRoutesCount

    /** @type {string} */
    premiumPlan

    /** @type {boolean} */
    isStaff

    /** @type {string} */
    externalId

    /** @type {string} */
    referralCodeLink

    /** @type {Object} */
    referralRewardsLoop = {
        /** @type {number} */
        maxLoopDuration: undefined,
        /** @type {string} */
        startDate: undefined,
        /** @type {string} */
        freePremiumExpirationDate: undefined,
    }

    /**
     * @returns {string|null} URL of the profile page for this user
     */
    getProfileUrl() {
        return this._getSlugUrl(USER_PROFILE_URL)
    }

    /**
     * @returns {string|null} URL of the user's My Routes view
     */
    getRoutesUrl() {
        return this._getSlugUrl(USER_ROUTES_URL)
    }

    /**
     * @returns {string|null} URL of the user's route collections view
     */
    getRouteCollectionsUrl() {
        return this._getSlugUrl(USER_ROUTE_COLLECTIONS_URL)
    }

    /**
     * @returns {string|null} URL of the user's settings
     */
    getSettingsUrl() {
        return this._getSlugUrl(USER_SETTINGS_URL)
    }

    /**
     * @returns {string|null} URL of the user's membership settings
     */
    getMembershipUrl() {
        return this._getSlugUrl(USER_MEMBERSHIP_URL)
    }

    /**
     * @returns {string|null} Preiod name of the premium plan if subscribed.
     */
    getPlanPeriod() {
        if (this.premiumPlan) {
            for (const period of PREMIUM_PLAN_PERIODS) {
                if (this.premiumPlan.includes(period)) {
                    return period
                }
            }
            logError('Subscription period could not be detected.')
        }
        return null
    }

    /**
     * @param {string} urlPattern URL with ":slug" placeholder
     * @returns {string|null} URL with inserted slug if slug is available
     */
    _getSlugUrl(urlPattern) {
        return this.slug ? urlPattern.replace(':slug', this.slug) : null
    }

}

export default User
