import { onUserInteraction } from '@bikemap/js/utility/events'
import { setLoginClickHandler, triggerLoginViaUrlHash } from '@bikemap/js/integrations/authentication'
import { initGoogleTagManager } from '@bikemap/js/integrations/analytics'

import { initCookieconsent } from '@bikemap/js/integrations/cookieconsent'
import { initWebAppUrls } from './web-app-links'
import { initBackendMessages } from './messages'
import { initConditionalContent } from './conditional-content'
import { initLazyLoading } from './lazy-loading'
import { initNativeAppInstallPrompt } from './native-app-install-prompt.jsx'
import sessionData from '@bikemap/js/services/sessionData'
import { getPreferencesFromUrlParams } from './url-preferences'

/**
 * Initialize everything that is needed on each page.
 */
export async function initGlobal() {
    sessionData.updateCookies() // to ensure CSRF token is up to date

    getPreferencesFromUrlParams()
    initLazyLoading()
    onUserInteraction(initCookieconsent)
    initWebAppUrls()
    initGoogleTagManager()
    onUserInteraction(initBackendMessages)
    initConditionalContent()
    initNativeAppInstallPrompt()

    if (!(await sessionData.isLoggedIn())) {
        triggerLoginViaUrlHash()
        setLoginClickHandler()
    }
}

export { initPageHeader, initCancelPageHeader } from './page-header'

export { initPageFooter } from './page-footer'

export { initPageContent } from './page-content'

export default initGlobal
