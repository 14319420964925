import ApiService from '@bikemap/js/api/ApiService'

export default class CountersApiService extends ApiService {

    /**
     * Fetch non-cached total counters from API
     * @returns {Promise<Object>}
     */
    read = () => this.endpoint.get({
        queryParams: {
            formatted: 1,
        },
    })

}
