import isEmpty from 'lodash-es/isEmpty'

/**
 * Prepend all object keys with our standard prefix (`data-analytics-`).
 * @param {Object} analytics
 * @return {Object}
 */
export function formatAnalyticsData(analytics) {
    if (isEmpty(analytics)) {
        return {}
    }
    const prefixed = Object.keys(analytics).map(key => ({
        ['data-analytics-' + key]: analytics[key],
    }))
    return Object.assign({}, ...prefixed)
}
