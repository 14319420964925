// time to wait for further input before callback
const INPUT_TIMEOUT = 1000

/**
 * @callback eventHandler
 * @param {Event} event The original input or blur event
 */

/**
 * Adds event listeners for blur and debounced input to an input field.
 * @param {Element} input The input element
 * @param {eventHandler} handler The event handler function
 * @param {boolean} [shouldHandleBlur=true] Set to false, if blur events should not be handled
 */
const addInputHandler = (input, handler, shouldHandleBlur = true) => {
    let debounceCounter = 0

    const handleBlur = e => {
        debounceCounter++
        handler(e)
    }

    const handleChange = e => {
        const debounceNumber = ++debounceCounter
        setTimeout(() => {
            if (debounceCounter === debounceNumber) {
                handler(e)
            }
        }, INPUT_TIMEOUT)
    }

    if (shouldHandleBlur) input.addEventListener('blur', handleBlur)
    input.addEventListener('input', handleChange)
}

export default addInputHandler
