/*
This is just a wrapper to provide correct linting and typechecking by importing gettext as a module.
*/

// Global django gettext function
// @ts-ignore
const globalGettext = window.gettext || (m => m)

/**
 * Translate a message.
 * @param {string} msgid Message ID, which is also the fallback
 * @returns {string} The translated message
 */
export default msgid => globalGettext(msgid)
