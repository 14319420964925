import './DropdownList.scss'

import React from 'react'

/**
 * @param {Object} props
 * @param {React.ReactNodeArray} props.children
 */
const DropdownList = ({ children }) => (
    <ul className="dropdown-list">
        {children}
    </ul>
)

/**
 * @param {Object} props
 * @param {React.ReactNodeArray} props.children
 */
DropdownList.Group = ({ children }) => (
    <li className="dropdown-list-group">
        <ul>{children}</ul>
    </li>
)

export default DropdownList
