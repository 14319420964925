import ApiService from '../ApiService'
import { createRouteDebugDataFromResponse, createRouteFromResponse, createUserFromResponse } from '../factories'
import { mapToBinaryId, setMinMax, setDistanceMinMax } from '../helpers'

/**
 * @typedef {Object} RouteEndpoints
 * @property {import("..").Endpoint} detail
 * @property {import("..").Endpoint} list
 * @property {import("..").Endpoint} favor
 * @property {import("..").Endpoint} unfavor
 * @property {import("..").Endpoint} spamStatus
 * @property {import("..").Endpoint} spamRoutes
 * @property {import("..").Endpoint} markAllUserRoutesAsSpam
 */

/**
 * @typedef {Object} RouteFilterParams
 * @property {string} [q] Custom query string
 * @property {[number, number]} [distance] Distance range filter (km or miles)
 * @property {[number, number]} [ascent] Ascent range filter
 * @property {number[]} [categories] Categories filter
 * @property {number[]} [surfaces] Surface types filter
 * @property {boolean} [onlyLoop] Only loop routes filter
 * @property {number} [location] ID of geoname to search within
 * @property {import('@bikemap/js/map').LngLatBounds} [bounds] Map bounds to search within (if no location)
 * @property {import('@bikemap/js/map').LngLat} [center] Center coordinates (if no location or bounds)
 * @property {number[]} [relativeAscent] Ascent range relative to distance
 * @property {number[]} [relativeDescent] Descent range relative to distance
 */

/**
 * @typedef {import("../ApiService").ListParams & RouteFilterParams} RouteListParams
 */

/**
 * @typedef {Object} RouteListResultAddition
 * @property {string} [geoname] Name of the location, if location in params
 * @property {import('@bikemap/js/map').LngLat|import('@bikemap/js/map').LngLatBounds} [bounds] Bounds or center of the geoname, if location in params
 *
 * @typedef {import("../ApiService").ListResult & RouteListResultAddition} RouteListResult
 */

class RouteApiService extends ApiService {

    /** @type {import("..").Endpoint} */
    listEndpoint

    /** @type {import("..").Endpoint} */
    favorEndpoint

    /** @type {import("..").Endpoint} */
    unfavorEndpoint

    /** @type {import("..").Endpoint} */
    spamStatusEndpoint

    /** @type {import("..").Endpoint} */
    spamRoutesEndpoint

    /** @type {import("..").Endpoint} */
    markAllUserRoutesAsSpamEndpoint

    /**
     * @param {RouteEndpoints} endpoints
     */
    constructor({
        detail,
        list,
        favor,
        unfavor,
        spamStatus,
        spamRoutes,
        markAllUserRoutesAsSpam,
    }) {
        super(detail)
        this.listEndpoint = list
        this.favorEndpoint = favor
        this.unfavorEndpoint = unfavor
        this.spamStatusEndpoint = spamStatus
        this.spamRoutesEndpoint = spamRoutes
        this.markAllUserRoutesAsSpamEndpoint = markAllUserRoutesAsSpam

        this.read = this.read.bind(this)
        this.readDetailed = this.readDetailed.bind(this)
        this.list = this.list.bind(this)
        this.favor = this.favor.bind(this)
        this.unfavor = this.unfavor.bind(this)
        this.readSpamStatus = this.readSpamStatus.bind(this)
        this.updateSpamStatus = this.updateSpamStatus.bind(this)
        this.listSpamRoutes = this.listSpamRoutes.bind(this)
        this.markAllOfUserAsSpam = this.markAllOfUserAsSpam.bind(this)
    }

    /**
     * Get detailed information about a route.
     * @param {number} routeId ID of the route
     * @returns {Promise<import("../../entities").Route>}
     */
    async read(routeId) {
        const res = await this.endpoint.get({
            params: { routeId },
        })
        return createRouteFromResponse(res)
    }

    /**
     * @typedef {Object} DetailedRouteResult
     * @property {import("../../entities").Route} route The route itself
     * @property {import("../../entities").User} user The user who created / owns the route
     * @property {number[]} collectionIds IDs of the route collections of the current user that contain the route
     */

    /**
     * Get detailed information about a route, the user entity of its creator and the collections the current
     * user saved it in.
     * @param {number} routeId ID of the route
     * @returns {Promise<DetailedRouteResult>}
     */
    async readDetailed(routeId) {
        const res = await this.endpoint.get({
            params: { routeId },
            queryParams: {
                include: 'routecollections',
            },
        })
        return {
            route: createRouteFromResponse(res),
            user: createUserFromResponse(res.user),
            collectionIds: res.routecollections || [],
        }
    }

    /**
     * Fetch a filtered, paginated list of all routes.
     * @param {RouteListParams} params
     * @returns {Promise<RouteListResult>}
     */
    async list(params) {
        const res = await this.listEndpoint.get({
            queryParams: {
                ...this._prepareListParams(params),
                ...this._prepareFilterParams(params),
            },
        })

        const entitiesConfig = {
            routes: createRouteFromResponse,
            users: result => createUserFromResponse(result.user),
        }
        // Map debug data if present
        if (res.results[0] && res.results[0].content_object.debug) {
            entitiesConfig.debugData = result => createRouteDebugDataFromResponse(result.id, result.debug)
        }

        return this._mapRouteListResult(res, entitiesConfig)
    }

    /**
     * Add a route to the favorites.
     * @param {number} routeId ID of the route
     * @returns {Promise<number>} New favorite count
     */
    async favor(routeId) {
        const res = await this.favorEndpoint.post(new FormData, {
            params: { routeId },
        })
        return res.favorite_count
    }

    /**
     * Remove a route from the favorites.
     * @param {number} routeId ID of the route
     * @returns {Promise<number>} New favorite count
     */
    async unfavor(routeId) {
        const res = await this.unfavorEndpoint.post(new FormData, {
            params: { routeId },
        })
        return res.favorite_count
    }

    /**
     * @typedef {Object} RouteSpamStatus
     * @property {boolean} isSpam
     * @property {boolean} isReviewed
     */

    /**
     * Get spam status of a route.
     * @param {number} routeId ID of the route
     * @returns {Promise<RouteSpamStatus>}
     */
    async readSpamStatus(routeId) {
        const res = await this.spamStatusEndpoint.get({
            params: { routeId },
        })
        return {
            isSpam: res.is_spam,
            isReviewed: res.is_reviewed,
        }
    }

    /**
     * Update spam status of a route (as staff).
     * @param {number} routeId ID of the route
     * @param {RouteSpamStatus} spamStatus
     * @returns {Promise<RouteSpamStatus>}
     */
    async updateSpamStatus(routeId, { isSpam, isReviewed }) {
        const body = new FormData
        body.append('is_spam', isSpam)
        body.append('is_reviewed', isReviewed)
        const res = await this.spamStatusEndpoint.patch(body, {
            params: { routeId },
        })
        return {
            isSpam: res.is_spam,
            isReviewed: res.is_reviewed,
        }
    }

    /**
     * Get all routes with a spam status.
     * @param {RouteSpamStatus} filters
     * @param {number} [pageSize]
     * @param {array} [excludeRouteIds]
     * @returns {Promise<(RouteSpamStatus & { routeId: number })[]>}
     */
    async listSpamRoutes({ isSpam, isReviewed }, pageSize, excludeRouteIds) {
        const requestArgs = {
            queryParams: {
                is_spam: isSpam ? 'True' : 'False',
                is_reviewed: isReviewed ? 'True' : 'False',
                page_size: pageSize || 20,
            },
        }
        if (Array.isArray(excludeRouteIds) && excludeRouteIds.length) {
            requestArgs.queryParams.exclude_route_id = excludeRouteIds
        }
        const res = await this.spamRoutesEndpoint.get(requestArgs)
        return res.results.map(result => ({
            isSpam: result.is_spam,
            isReviewed: result.is_reviewed,
            routeId: result.route_id,
        }))
    }

    /**
     * Mark all routes of a user as spam.
     * @param {number} userId
     * @returns {Promise<{ message: string, errorCode?: number}>}
     */
    async markAllOfUserAsSpam(userId) {
        const res = await this.markAllUserRoutesAsSpamEndpoint.post(new FormData, {
            queryParams: {
                user_id: userId,
            },
        })
        return {
            message: res.message,
            errorCode: res.error_code,
        }
    }

    /**
     * Prepare an object with all route filter params in the backend format.
     * @param {RouteFilterParams & Object} params
     * @returns {Object}
     */
    _prepareFilterParams({
        q, distance, ascent, categories, surfaces, bounds, location, relativeAscent,
        relativeDescent, onlyLoop, center,
    }) {
        const filterParams = { q, ascent, location }

        if (!location) {
            if (!bounds && !center) {
                throw Error('Location, bounds or center must be provided to query routes.')
            }
            filterParams.bounds = (bounds || center).join(',')
        }

        if (categories) {
            filterParams.categories = categories.map(mapToBinaryId)
        }
        if (surfaces) {
            filterParams.surfaces = surfaces.map(mapToBinaryId)
        }

        if (!bounds && !location && !center) {
            // We must explicitly tell to not use location filters
            filterParams.no_location_filters = true
        }

        // Params with different keys
        filterParams.only_loop = onlyLoop
        setMinMax(filterParams, 'ascent', relativeAscent)
        setMinMax(filterParams, 'descent', relativeDescent)
        setDistanceMinMax(filterParams, distance)

        // Hard coded params
        filterParams.score_nearby = 'false'
        filterParams.route_type = 'user'

        return filterParams
    }

    /**
     * Maps the response to a route list request to the result object.
     * @param {Object} res
     * @param {Object} entitiesConfig
     * @returns {RouteListResult}
     */
    _mapRouteListResult({ count, results, geoname, bounds }, entitiesConfig) {
        const listResult = {
            ...this._mapFilteredListResult(results, count, entitiesConfig),
            geoname,
            bounds: null,
        }

        if (bounds) {
            const [lat1, lng1, lat2, lng2] = bounds
            if (bounds.length === 4) {
                listResult.bounds = [lng1, lat1, lng2, lat2]
            } else if (bounds.length === 2) {
                listResult.bounds = [lng1, lat1]
            }
        }

        return listResult
    }

}

export default RouteApiService
