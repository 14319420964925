import { SESSION_DATA_URL, LOGGED_IN_COOKIE } from '@bikemap/js/settings'
import { defaultPost, LazyPromise, readCookie } from '@bikemap/js/utility'

/**
 * @typedef {Object} SessionData
 * @property {string} [zendesk]
 * @property {boolean} [isStaff]
 */

/**
 * Service for information from the server about the user's session.
 */
class SessionDataService {

    _lazySessionData = new LazyPromise(this._fetchSessionData.bind(this))

    /**
     * Get information from the server about the user's session.
     * @returns {Promise<SessionData>}
     */
    getSessionData() {
        return this._lazySessionData.getPromise()
    }

    /**
     * Make sure session related cookies are updated.
     * @returns {Promise}
     */
    async updateCookies() {
        await this._lazySessionData.getPromise()
    }

    /**
     * Get latest info whether the session is authenticated.
     * @returns {Promise<boolean>}
     */
    async isLoggedIn() {
        await this._lazySessionData.getPromise()
        return this.getCurrentLoggedInState()
    }

    /**
     * Current local info whether the session is authenticated. Rather await isLoggedIn()
     * if possible, as this may be outdated.
     * @returns {boolean}
     */
    getCurrentLoggedInState() {
        return readCookie(LOGGED_IN_COOKIE) === '1'
    }

    /**
     * Call the session_data ajax view, which provides user data not yet available
     * via API and updates cookies.
     * @returns {Promise<SessionData>}
     */
    async _fetchSessionData() {
        const body = new FormData
        body.append('pathCalling', window.location.pathname)
        const data = await defaultPost(SESSION_DATA_URL, body)

        return {
            zendesk: data.zendesk,
            isStaff: data.is_staff,
        }
    }

}

export default new SessionDataService
