import { createFormErrorHandler, defaultPost, handleRedirect } from '@bikemap/js/utility'
import InitPasswordField from './InitPasswordField'

/**
 * Initializes all the functionality of a password form.
 * @param {HTMLFormElement} form The password form to initialize
 * @param {string=} placeholder Custom placeholder
 */
export function initPasswordForm(form, placeholder = '') {

    const passwordField = new InitPasswordField(form, placeholder)
    passwordField.render()

    form.addEventListener('submit', e => {
        e.preventDefault()

        /** @type {HTMLButtonElement} */
        const submitBtn = form.querySelector('[type="submit"]')
        submitBtn.disabled = true
        submitBtn.classList.add('loading-spinner')

        defaultPost(form.getAttribute('action'), new FormData(form))
            .then(createFormErrorHandler(form))
            .then(handleRedirect)
            .finally(() => {
                submitBtn.disabled = false
                submitBtn.classList.remove('loading-spinner')
            })
    })
}
