import polyline from '@mapbox/polyline'
import React from 'react'

import { GEONAME_DETAIL_URL, WEB_APP_URLS } from '../../settings'
import { HighlightedText } from '../../components'
import { gettext, user } from '../../services'
import { getWebAppParams } from '../global/web-app-links'

const t = {
    SEARCH_IN: gettext('All routes in'),
    NAVIGATE_TO: gettext('Navigate to'),
}

/**
 * @param {import("../../utility/network/autocomplete").GeonameSuggestion} suggestion
 * @param {string} queryString
 * @returns {import("../../../components/form/DropdownOptionItem").DropdownOptionValues}
 */
export const getGeonameOption = ({ geonameId, longName, parentLocation }, queryString) => ({
    value: geonameId,
    label: (
        <>
            <HighlightedText text={longName} highlight={queryString} />
            {parentLocation && <> <small>{parentLocation}</small></>}
        </>
    ),
    icon: 'i-location',
    href: GEONAME_DETAIL_URL.replace(':geonameId', '' + geonameId),
})

/**
 * @param {import("../../utility/network/autocomplete").GeonameSuggestion} suggestion
 * @returns {import("../../../components/form/DropdownOptionItem").DropdownOptionValues}
 */
export const getSearchOption = ({ geonameId, longName, lng, lat }) => ({
    value: geonameId,
    label: <>{t.SEARCH_IN} <strong>{longName}</strong></>,
    icon: 'i-search',
    href: WEB_APP_URLS.WEB_APP_REGION_URL.replace(':id', geonameId) + getWebAppParams(),
})

/**
 * @param {import("../../utility/network/autocomplete").GeonameSuggestion} suggestion
 * @returns {import("../../../components/form/DropdownOptionItem").DropdownOptionValues}
 */
export const getPlannerOption = ({ geonameId, longName, lng, lat }) => {
    const { location } = user.location
    const waypoints = /** @type {[number, number][]} */ ([
        location,
        [lat, lng],
    ])

    return {
        value: geonameId,
        label: <>{t.NAVIGATE_TO} <strong>{longName}</strong></>,
        icon: 'i-directions',
        href: WEB_APP_URLS.WEB_APP_PLAN_URL + getWebAppParams() + '&waypoints=' + encodeURIComponent(polyline.encode(waypoints)),
    }
}
