import ApiService from '../ApiService'
import { createUserFromResponse } from '../factories'

class UserApiService extends ApiService {

    /**
     * Get detailed information about a user.
     * @param {number|'self'} [userId]
     * @param {string} [include] Additional fields to request
     * @returns {Promise<import("../../entities").User>}
     */
    async read(userId, include) {
        const res = await this.endpoint.get({
            params: {
                userId: userId || 'self',
            },
            queryParams: include ? { include } : {},
        })
        return createUserFromResponse(res)
    }

    /**
     * Update properties of the own user profile.
     * @param {FormData} changes
     * @returns {Promise<import("../../entities").User>}
     */
    async update(changes) {
        const res = await this.endpoint.patch(changes, {
            params: {
                userId: 'self',
            },
        })
        return createUserFromResponse(res)
    }

    /**
     * Delete user
     * @param {number|'self'} [userId]
     * @param {string|false} [forceImmediateDeletion]
     * @returns {Promise}
     */
    async delete(userId, forceImmediateDeletion) {
        await this.endpoint.delete({
            params: {
                userId: userId || 'self',
            },
            queryParams: {
                force_immediate_deletion: forceImmediateDeletion || 'false',
            },
        })
    }

}

export default UserApiService
