import Notification from './Notification'
import gettext from '../gettext'

/**
 * Default time until notifications fade in milliseconds.
 * @type {number}
 */
const DEFAULT_DELAY = 10000

const ERROR_MESSAGE = gettext('There was an unexpected error. We are sorry! Please try again!')
const ERROR_TITLE = gettext('Error')

let container
const notifications = []

function init() {
    container = document.createElement('div')
    container.id = 'notifications-container'
    document.body.appendChild(container)
    // Immediately append notifications added before
    notifications.forEach(n => container.appendChild(n.node))
}

document.addEventListener('DOMContentLoaded', init)

/**
 * @typedef {Object} NotificationOptions
 * @property {string} [options.name] Used to find an according init function when triggered from server
 * @property {string} [options.imageUrl] Image URL to be shown next to title and message
 * @property {string} [options.imageBadgeUrl] Badge URL to be shown on top of image
 * @property {string} [options.title] Title (heading) of the notification
 * @property {string} [options.icon] Name of the icon or URL of an image to show next to the title
 * @property {string} [options.icon_type] Set this to "img" if the icon is set as image URL
 * @property {string} [options.message] Text or HTML to show in the notification
 * @property {string} [options.footerLeft] Bottom left content
 * @property {string} [options.footerRight] Bottom right content
 * @property {string} [options.type] Set a notification type with a different style
 * @property {function} [options.onClose] Handler function, called when the notification disappears
 * @property {number} [options.delay] Number of milliseconds before closing automatically, 0 for infinite
 */

/**
 * Show a notification.
 * @param {NotificationOptions} options
 * @returns {Notification}
 */
export function show(options = {}) {
    const notification = new Notification(options)
    // Container may not be initialized yet
    if (container) {
        container.appendChild(notification.node)
    }
    const i = notifications.push(notification)
    notification.addCloseHandler(() => delete notifications[i])
    if (options.delay !== 0) {
        setTimeout(() => notification.close(), options.delay || DEFAULT_DELAY)
    }
    return notification
}

/**
 * Close all notifications.
 */
export function close() {
    notifications.forEach(n => n.close())
}

/**
 * Shorthand method for an error notification.
 * @param {string} [message] Notification content
 * @param {string} [title] Notification title
 */
export function error(message = ERROR_MESSAGE, title = ERROR_TITLE) {
    show({
        title,
        icon: 'i-warning-sign',
        message,
        type: 'danger',
    })
}

export default { show, close, error }
