import React from 'react'

import DropdownItem from './DropdownItem.jsx'

/**
 * @typedef {Object} DropdownOptionItemAdditionalProps
 * @property {boolean} [props.selected]
 * @property {(value: number|string) => void} [props.onClick]
 * @property {(el) => void} [props.childRef]
 * @property {React.KeyboardEventHandler} [props.onKeyDown]
 * @property {() => void} [props.onBlur]
 * @property {boolean} [nofollow]
 * @property {string} [dataTestId]
 */

/**
 * Renders a dropdown item with button or link, depending on the props.
 * @param {import("..").DropdownOptionValues & DropdownOptionItemAdditionalProps} props
 */
const DropdownOptionItem = ({
    value, label, icon, href, selected, onClick, childRef, onKeyDown, onBlur, nofollow, dataTestId,
}) => {
    const childProps = {
        onClick: e => {
            if (onClick) {
                e.preventDefault()
                onClick(value)
            }
        },
        ref: childRef,
        onKeyDown,
        onBlur,
    }

    if (dataTestId) {
        childProps['data-testid'] = dataTestId
    }

    return (
        <DropdownItem icon={icon} selected={selected}>
            {href ? (
                <a href={href} rel={nofollow ? 'nofollow' : null} {...childProps}>{label}</a>
            ) : (
                <button type="button" {...childProps}>{label}</button>
            )}
        </DropdownItem>
    )
}

export default DropdownOptionItem
