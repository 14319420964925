import moment from 'moment'

/**
 * Represents feedback to a real-time POI.
 */
class PoiComment {

    /** @type {number} */
    id

    /** @type {number} */
    poi

    /** @type {number} */
    user

    /** @type {moment.Moment} */
    created = moment()

    /** @type {moment.Moment} */
    modified

    /** @type {string} */
    text = ''

    /** @type {string} */
    image

}

export default PoiComment
