import moment from 'moment'

import ApiService from '../ApiService'

class SubscriptionPauseApiService extends ApiService {

    constructor(endpoint) {
        super(endpoint)
        this.status = this.status.bind(this)
        this.start = this.start.bind(this)
        this.end = this.end.bind(this)
    }

    /**
     * Get the status regarding a subscription pause for the active user.
     * @returns {Promise<{pausedUntil, pauseAvailable, isPaused}>} Subscription pause status
     */
    async status() {
        const res = await this.endpoint.get()
        return {
            pausedUntil: res.paused_until ? moment(res.paused_until) : null,
            pauseAvailable: res.pause_available,
            isPaused: res.is_paused,
        }
    }

    /**
     * Start a subscription pause.
     * @returns {Promise<moment.Moment>} End of the pause
     */
    async start() {
        const body = new FormData
        body.append('pause_duration', moment.duration(2, 'M').asDays() + ' 00:00:00')
        const res = await this.endpoint.post(body)
        return moment(res.pause_until)
    }

    /**
     * End the current subscription pause.
     * @returns {Promise<moment.Moment>} End of subscription
     */
    async end() {
        const res = await this.endpoint.delete()
        return moment(res.valid_until)
    }

}

export default SubscriptionPauseApiService
