import React from 'react'

/**
 * Renders the text with every case-insensitive occurrence of a highlight string
 * wrapped in <strong> tags.
 * @param {Object} props
 * @param {string} props.text
 * @param {string} props.highlight
 */
const HighlightedText = ({ text, highlight }) => {
    const textLow = text.toLowerCase()
    const highlightLow = highlight.toLowerCase()
    const highlightLength = highlight.length
    const nodes = []

    let startIndex = 0 // search from here
    let index // start index of the curret occurrence
    // Iterate occurrences
    while ((index = textLow.indexOf(highlightLow, startIndex)) > -1) {
        // Add the part before as plain text
        nodes.push(text.substring(startIndex, index))
        // Add the part that matched as strong element
        nodes.push(<strong key={index}>{text.substr(index, highlightLength)}</strong>)
        // Continue searching after the occurrence
        startIndex = index + highlightLength
    }
    // Add the remaining part as plain text
    nodes.push(text.substring(startIndex))

    return <>{nodes}</>
}

export default HighlightedText
