import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import CookieBanner from './ConnectedCookieBanner.jsx'
import { gettext } from '@bikemap/js/services'
import { TranslationProvider } from '@bikemap/components/_translation'
import { getCookiePreferences } from './preferences'

export * from './preferences'

let cookieconsent

/**
 * Shows the cookieconsent if necessary.
 */
export function initCookieconsent() {
    cookieconsent = document.getElementById('cookieconsent')

    if (cookieconsent && !getCookiePreferences()) {
        showCookieConsent()
    }

    const cookieBannerTriggers = document.querySelectorAll('.cookie-banner-trigger')
    cookieBannerTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault()
            showCookieConsent()
        })
    })
}

function showCookieConsent() {
    render((
        <TranslationProvider translate={gettext}>
            <CookieBanner onClose={hideCookieconsent} />
        </TranslationProvider>
    ), cookieconsent)
    cookieconsent.classList.add('visible')
}

function hideCookieconsent() {
    cookieconsent.classList.remove('visible')
    setTimeout(() => {
        unmountComponentAtNode(cookieconsent)
    }, 500)
}
