import React from 'react'
import { Button } from '../Button'
import { MinimalButton } from '../MinimalButton'
import { translated } from '../_translation'
import { AppIcon } from './AppIcon.jsx'

import styles from './AndroidBanner.module.scss'

/**
 * Global banner to promote installing the Android app.
 * @param {Object} props
 * @param {string} props.linkUrl
 * @param {() => void} props.onCtaClick
 * @param {() => void} props.onClose
 */
const AndroidBanner = ({
    linkUrl,
    onCtaClick,
    onClose,
    gettext,
}) => {
    const headline = gettext('Bikemap App')
    const subtitle = gettext('Cycling Navigation & Map')
    const linkText = gettext('on Google Play')
    const ctaText = gettext('Install')

    return (
        <div className={styles.root}>
            <MinimalButton className={styles.close} icon='i-close' onClick={onClose} />
            <div className={styles['app-icon-holder']}>
                <AppIcon />
            </div>
            <div className={styles.content}>
                <h4>{headline}</h4>
                <p>{subtitle}</p>
                <a href={linkUrl}>{linkText}</a>
            </div>
            <Button variant='primary' width='narrower' onClick={onCtaClick}>
                {ctaText}
            </Button>
        </div>
    )
}

export default translated(AndroidBanner)
