import moment from 'moment'

/**
 * Represents a vote on a real-time POI.
 */
class PoiVote {

    /** @type {number} */
    id

    /** @type {number} */
    poi

    /** @type {number} */
    user

    /** @type {moment.Moment} */
    created = moment()

    /** @type {moment.Moment} */
    modified

    /** @type {boolean} */
    score

}

export default PoiVote
