import context from '@bikemap/js/services/context'
import { ENV } from './environment.js'

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAk-iuqru8H3sm55Dy8jcYK_75DvBEpHwQ'

export const MAPTILER_API_KEY = ENV === 'LOCAL' ? 'V981G6iGmi0S5j8NCw7e' : 'Gp1e8G0cMZyO3v6Ejn2k'

export const ARCGIS_API_KEY = (
    ENV === 'PRODUCTION'
        ? 'AAPK52a8deb2fab14cc3b2531d4c29081d2dGibOlMYNDj_y82u6RHfOUL6uI41ZSuewf9ukOFZHlrsgJUG6Ravc3zpSRIxIMflJ'
        : 'AAPK2f2f810382684b7fab0ebfe3c809ed25WKLlzLLI1s1k9f6VaK8JvLHWscZlEPPksDQgdmpWdPSA_WJVBNCROAP8gHK25ZEj'
)

export const CLIENT_ID = (
    ENV === 'PRODUCTION'
        ? 'HjatjjiIHMqRkv5CCf3R0f8gpmnJUGVBCsALQexy'
        : (
            ENV === 'DEVELOPMENT' || context.api('developmentAccess')
                ? 'EWfa28HLnIIUhos0r9R1y34NdrSAnCrJmrYKXsER'
                : 'HFCXNYhnJpGfRwnZWL7w6YOjBjiF5vejxtCXwTIU'
        )
)

export const CLIENT_SECRET = (
    ENV === 'PRODUCTION'
        ? 'c2iI0VM6bEZgNbuY0kjudHf3FXoEKTqpMf7DHywd5NnHvd99o13OBaVugFbEHem8DiIJYL7VWrKlLaqnOF6lhepuIa54yImZMw20xJqjbpodX1GCzZEINyQB5Zq3wsvK'
        : (
            ENV === 'DEVELOPMENT' || context.api('developmentAccess')
                ? '4WpvR5Z6qhnibehDuJWb144YuTKhH1wRavBDxFvc7ATEuNoyukK255NRUpkzWLLXkw1NbubheMzn4c15u1EV0d4bxFPEoOsGx0gViqayWaoYuBpWhnmLL8aiGthgZ5HO'
                : 'hcByIU1pqeaZIHrTLocGv3nPhpCdTVfvIW5YRHkgFzlz9pGT0fLLR50dcaMZW9wHxUzZLptJKJta2vzcNgKg6CNdnUpluIL9lBMYu9c0UREvCke2EO3HbXup6SyVJKPQ'
        )
)
