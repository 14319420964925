import { SET_UNIT_URL } from '@bikemap/js/settings'
import { readCookie } from '@bikemap/js/utility'

/**
 * Change unit preference and reload.
 * @param {'metric'|'imperial'} unit
 */
export function changeUnit(unit) {
    const body = new FormData
    body.append('unit', unit)
    body.append('next', location.href)
    body.append('csrfmiddlewaretoken', readCookie('csrftoken'))

    fetch(SET_UNIT_URL, {
        method: 'POST',
        body,
    }).then(res => {
        location.href = res.url
    })
}
