import { logError } from '@bikemap/js/utility/helpers'
import ApiService from '../ApiService'

/**
 * @typedef {Object} Notification
 * @property {string} [name]
 * @property {string} [title]
 * @property {string} [icon]
 * @property {string} [message]
 * @property {string} [type]
 * @property {number} [delay]
 */

class NotificationApiService extends ApiService {

    /**
     * Get the current list of notifications from the backend.
     * @param {string} [path] to fetch notifications for a certain page path
     * @returns {Promise<Notification[]>}
     */
    async list(path) {
        try {
            const res = await this.endpoint.get({
                queryParams: { path },
            })

            return res.map(notification => {
                const { name, title, icon, message, type, delay } = notification
                return {
                    name,
                    title,
                    icon,
                    message,
                    type,
                    delay,
                }
            })
        } catch (e) {
            logError('Problem fetching backend notifications', e)
        }
    }

}

export default NotificationApiService
