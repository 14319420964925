import React, { useEffect, useState } from 'react'

import { SearchField } from '../../components'
import { user } from '../../services'
import { AUTOCOMPLETE_DEBOUNCE_TIME } from '../../settings'
import { autocomplete } from '../../utility'
import { getGeonameOption, getPlannerOption, getSearchOption } from './helpers'

/**
 * Autocomplete field for searching locations that fetches suggestion links to region pages, search and route planner.
 */
const MultiSearch = () => {
    user.getLocation()

    const [value, setValue] = useState('')
    const [options, setOptions] = useState([])
    const [isQuerying, setIsQuerying] = useState(false)

    const fetchOptions = () => {
        setIsQuerying(true)
        autocomplete(value).then(results => {
            setOptions(mapResultsToOptions(results))
            setIsQuerying(false)
        })
    }

    useEffect(() => {
        let timeout
        if (value.length >= 3) {
            timeout = window.setTimeout(fetchOptions, AUTOCOMPLETE_DEBOUNCE_TIME)
        } else if (options.length) {
            setOptions([])
        }
        return () => clearTimeout(timeout)
    }, [value])

    const mapResultsToOptions = results => {
        results.splice(4)

        const options = results.map(suggestion => getGeonameOption(suggestion, value))

        const firstResult = results[0]
        if (firstResult) {
            options.push(getSearchOption(firstResult))

            if (user.location) {
                options.push(getPlannerOption(firstResult))
            }
        }

        return options
    }

    const searchFieldProps = {
        value,
        onChange: value => { setValue(value) },
        onReset: () => { setValue('') },
        options,
        loading: isQuerying,
        className: 'multi-search',
    }

    return <SearchField {...searchFieldProps} />
}

export default MultiSearch
