/*
Mapping between query params string and object representation.
*/

/**
 * Map URL query parameters to an object. All values will be decoded strings or arrays of
 * decoded strings, if the key occurs multiple times.
 * @param {string} [paramsString] Query params string, starting with "?"
 * @returns {Object} Object representing the query params
 */
export function deserializeQueryParams(paramsString = location.search) {
    // Get an array of "key=value" strings
    const separateStrings = paramsString.replace(/^\?/g, '').split('&')

    // Build the params object
    const paramsObject = {}
    separateStrings.forEach(param => {
        if (!param) return
        // Split param string into key and value
        const [key, value] = param.split('=')
        if (paramsObject[key]) {
            // Param already set, so make it an array and add the value
            if (Array.isArray(paramsObject[key])) {
                paramsObject[key].push(decodeURIComponent(value))
            } else {
                paramsObject[key] = [paramsObject[key], value]
            }
        } else {
            // Set single param
            paramsObject[key] = decodeURIComponent(value)
        }
    })

    return paramsObject
}

/**
 * Map an object to URL query parameters.
 * @param {Object} paramsObject Object of query parameters
 * @returns {string} Query params string
 */
export function serializeQueryParams(paramsObject) {
    const separateQueryParams = []

    for (const key in paramsObject) {
        const value = paramsObject[key]

        // Convert different types to string
        if (Array.isArray(value)) {
            // For arrays, add the key multple times
            value.forEach(item => separateQueryParams.push(key + '=' + encodeURIComponent(item)))
        } else if (typeof value === 'boolean') {
            // Convert booleans to 0/1
            separateQueryParams.push(key + '=' + (value ? 1 : 0))
        } else if (value !== undefined && value !== null && value !== '') {
            // Take other existing values as they are
            separateQueryParams.push(key + '=' + encodeURIComponent(value))
        }
    }

    // Return either the query params or an empty string
    return (separateQueryParams.length) ? '?' + separateQueryParams.join('&') : ''
}

/**
 * Try to find provided param in URL.
 * @param {string} param
 * @returns {string|undefined}
 */
export function getFromUrl(param) {
    const url = new URL(window.location.toString())
    const val = url.searchParams.get(param)
    return val || undefined
}

/**
 * Remove a param from URL.
 * @param {string} param
 */
export function removeFromUrl(param) {
    const url = new URL(window.location.toString())
    url.searchParams.delete(param)
    window.history.replaceState(null, '', url)
}

/**
 * Try to find provided param in URL and also remove it if found.
 * @param {string} param
 * @returns {string|undefined}
 */
export function extractFromUrl(param) {
    try {
        const value = getFromUrl(param)
        if (value) {
            removeFromUrl(param)
        }
        return value
    } catch {
        return undefined
    }
}
