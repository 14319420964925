import { user as userService } from '@bikemap/js/services'
import sessionData from '@bikemap/js/services/sessionData'
import { logError } from '@bikemap/js/utility'
import { getCookiePreference } from '../cookieconsent/preferences'

import { hasDataLayer } from './helpers'

/**
 * Initial updates to the GTM dataLayer after page load.
 */
export async function initGoogleTagManager() {
    const analyticsCookiesEnabled = getCookiePreference('statistics')

    if (hasDataLayer()) {
        if (analyticsCookiesEnabled) {
            grantGTMAnalyticsStorage()
        }

        if (await sessionData.isLoggedIn()) {
            const user = await userService.getEntity()

            window.dataLayer.push({
                user: analyticsCookiesEnabled ? user.externalId : 'anonymous',
                plan: user.getPlanPeriod() || 'free',
                event: 'userData',
            })
        }
    }
}

/**
 * Update the consent mode of Google Tag Manager to allow analytics cookies, which is "denied"
 * by default on each page load.
 */
export function grantGTMAnalyticsStorage() {
    if (window.gtag) {
        window.gtag('consent', 'update', {
            analytics_storage: 'granted',
        })
    } else {
        logError('gtag function undefined')
    }
}
