import React from 'react'

import './MinimalButton.scss'

/**
 * A minimalistic inline Button. All <button> attributes can be passed as props.
 * @param {Object} props
 * @param {string} [props.children] Child components
 * @param {string} [props.className] Additional classes
 * @param {"primary"|"dark"|"text"|"danger"} [props.variant] Variant of the button
 * @param {string} [props.icon] Name of an icon to insert into the button
 * @param {string} [props.iconAfter] Name of an icon to insert at the end of the button
 * @param {"button"|"submit"|"reset"} [props.type="button"] Button type attribute
 * @param {boolean} [props.align] Align the inner text instead of the whole button
 * @param {React.MouseEventHandler<HTMLButtonElement>} [props.onClick] Click event handler
 * @param {boolean} [props.disabled] Disabled attribute
 */
const MinimalButton = props => {
    const { children, className, variant, icon, iconAfter, type, align } = props

    let classes = 'minimal-button'
    if (variant) classes += ' minimal-button-' + variant
    if (align) classes += ' align'
    if (className) classes += ' ' + className

    const buttonProps = {
        ...props,
        className: classes,
        type: type || 'button',
    }

    // Don't pass these props to the button:
    delete buttonProps.icon
    delete buttonProps.iconAfter
    delete buttonProps.align

    return (
        <button {...buttonProps}>
            {icon && <i className={icon} />}
            {children && <span>{children}</span>}
            {iconAfter && <i className={iconAfter} />}
        </button>
    )
}

export default MinimalButton
