import {
    addInputHandler, insertFieldError, createFormErrorHandler, defaultPost, handleRedirect,
    Storage, STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN,
} from '@bikemap/js/utility'
import InitPasswordField from './InitPasswordField'
import { CHECK_EMAIL_AVAILABLE_URL } from '@bikemap/js/settings'

/**
 * Set up debounced validation for the email field.
 * @param {HTMLInputElement} input Email input field
 * @param {HTMLButtonElement} submitBtn Submit button of the registration form
 */
function initEmailValidation(input, submitBtn) {
    let previousEmail
    let requestCount = 0
    addInputHandler(input, () => {
        if (input.value !== previousEmail) {
            // Value really changed
            previousEmail = input.value

            // Set loading state
            submitBtn.disabled = true
            submitBtn.classList.add('loading-spinner')

            // Validation request
            const requestNumber = ++requestCount
            const body = new FormData
            body.append('fieldValue', input.value)
            defaultPost(CHECK_EMAIL_AVAILABLE_URL, body)
                .then(res => {
                    if (requestNumber !== requestCount) return

                    /** @type {HTMLElement} */
                    const errorMessage = input.parentNode.querySelector('.form-field-error')

                    if (res.success) {
                        input.classList.remove('error')
                        errorMessage && errorMessage.remove()
                        submitBtn.disabled = false
                    } else {
                        input.classList.add('error')
                        if (!errorMessage) {
                            insertFieldError(input, res.message)
                        } else {
                            errorMessage.textContent = res.message
                        }
                    }
                })
                .catch(() => {
                    // Enable submit, so user can try again
                    if (requestNumber === requestCount) {
                        submitBtn.disabled = false
                    }
                })
                .finally(() => {
                    // Remove loading spinner
                    (requestNumber === requestCount) && submitBtn.classList.remove('loading-spinner')
                })
        }
    })
}

/**
 * Initialize a registration form.
 * @param {HTMLFormElement} form The registration form element
 */
export function initRegistrationForm(form) {
    /** @type {HTMLButtonElement} */
    const submitBtn = form.querySelector('[type="submit"]')
    /** @type {HTMLInputElement} */
    const emailInput = form.querySelector('input[type="email"]')
    /** @type {HTMLInputElement} */
    const nameInput = form.querySelector('input[name="displayname"]')

    initEmailValidation(emailInput, submitBtn)

    const passwordField = new InitPasswordField(form)
    passwordField.render()
    // This will re-render PasswordField component with new forbidden words (email and name)
    passwordField.addFieldListeners(emailInput, 'email')
    passwordField.addFieldListeners(nameInput, 'displayname')

    form.addEventListener('submit', e => {
        e.preventDefault()
        Storage.setWithTimestamp(STORAGE_KEY_POST_REGISTER_PREMIUM_HINT_COUNTDOWN, 0)

        // Set loading state
        submitBtn.disabled = true
        submitBtn.classList.add('loading-spinner')

        // Registration request
        defaultPost(form.getAttribute('action'), new FormData(form))
            .then(createFormErrorHandler(form))
            .then(handleRedirect)
            .finally(() => {
                // Remove loading spinner
                submitBtn.disabled = false
                submitBtn.classList.remove('loading-spinner')
            })
    })
}
