import ApiService from '../ApiService'
import { createPoiFromResponse, createUserFromResponse } from '../factories'

/**
 * @typedef {Object} PoiEndpoints
 *
 * @property {import("..").Endpoint} detail
 * @property {import("..").Endpoint} list
 * @property {import("..").Endpoint} review
 * @property {import("..").Endpoint} resetTtl
 * @property {import("..").Endpoint} markSpam
 * @property {import("..").Endpoint} merge
 */

/**
 * @typedef {Object} PoiListParams
 *
 * @property {string} rules Filtering rules JSON
 * @property {string} [sort] Sort by this field
 * @property {"ASC"|"DESC"} [order] Order direction, if sort is set
 * @property {number} [page] Page of results to fetch
 * @property {number} [pageSize] Number of results per page
 */

/**
 * @typedef {Object} PoiResult
 *
 * @property {import("../../entities").Poi} poi
 * @property {import("../../entities").User} creator
 */

class PoiApiService extends ApiService {

    /** @type {import("..").Endpoint} */
    listEndpoint

    /** @type {import("..").Endpoint} */
    reviewEndpoint

    /** @type {import("..").Endpoint} */
    resetTtlEndpoint

    /** @type {import("..").Endpoint} */
    markSpamEndpoint

    /** @type {import("..").Endpoint} */
    mergeEndpoint

    /**
     * @param {PoiEndpoints} endpoints
     */
    constructor(endpoints) {
        super(endpoints.detail)
        this.listEndpoint = endpoints.list
        this.reviewEndpoint = endpoints.review
        this.resetTtlEndpoint = endpoints.resetTtl
        this.markSpamEndpoint = endpoints.markSpam
        this.mergeEndpoint = endpoints.merge

        this.list = this.list.bind(this)
        this.read = this.read.bind(this)
        this.update = this.update.bind(this)
        this.delete = this.delete.bind(this)
        this.markReviewed = this.markReviewed.bind(this)
        this.unmarkReviewed = this.unmarkReviewed.bind(this)
        this.resetTtl = this.resetTtl.bind(this)
        this.markSpam = this.markSpam.bind(this)
        this.unmarkSpam = this.unmarkSpam.bind(this)
        this.merge = this.merge.bind(this)
    }

    /**
     * Query to the POI list endpoint.
     * @param {PoiListParams} listParams Params for the list query
     * @returns {Promise<import("../ApiService").ListResult>}
     */
    async list({ rules, sort, order, page, pageSize }) {
        // Only set relevant query params
        const queryParams = {
            page: page || 1,
            rules: rules,
        }

        // If an order field is set, add the combined order_by param
        if (sort) {
            queryParams.order_by = (order === 'DESC' ? '-' : '') + sort
        }

        if (pageSize) {
            queryParams.page_size = pageSize
        }

        const { results, count } = await this.listEndpoint.get({ queryParams })

        return this._mapListResult(results, count, {
            pois: createPoiFromResponse,
            users: result => createUserFromResponse(result.user),
        })
    }

    /**
     * Get detailed information about a realtime POI.
     * @param {number} poiId ID of the POI
     * @returns {Promise<PoiResult>} POI details
     */
    async read(poiId) {
        const res = await this.endpoint.get({
            params: { poiId },
        })
        return {
            poi: createPoiFromResponse(res),
            creator: createUserFromResponse(res.user),
        }
    }

    /**
     * Update a POI.
     * @param {number} poiId ID of the POI
     * @param {FormData} changes Changes to the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async update(poiId, changes) {
        const res = await this.endpoint.patch(changes, {
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Mark POI as spam.
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async markSpam(poiId) {
        const res = await this.markSpamEndpoint.post(null, {
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Unmark POI as spam.
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async unmarkSpam(poiId) {
        const res = await this.markSpamEndpoint.delete({
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Delete a POI.
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async delete(poiId) {
        const res = await this.endpoint.delete({
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Mark a POI as reviewed.
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async markReviewed(poiId) {
        const res = await this.reviewEndpoint.post(null, {
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Unmark a POI as reviewed.
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async unmarkReviewed(poiId) {
        const res = await this.reviewEndpoint.delete({
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Reset a POI's TTL to its initial value (based on its categories TTL).
     * @param {number} poiId ID of the POI
     * @returns {Promise<import("../../entities").Poi>}
     */
    async resetTtl(poiId) {
        const res = await this.resetTtlEndpoint.post(null, {
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

    /**
     * Merge a list of other POIs into a POI.
     * @param {number} poiId ID of the POI to keep
     * @param {number[]} idsToMerge IDs of POIs to merge
     * @returns {Promise<import("../../entities").Poi>}
     */
    async merge(poiId, idsToMerge) {
        const body = new FormData
        body.append('pois', JSON.stringify(idsToMerge))

        const res = await this.mergeEndpoint.post(body, {
            params: { poiId },
        })
        return createPoiFromResponse(res)
    }

}

export default PoiApiService
