import React from 'react'
import { renderSVG } from '../utility'

/**
 * Reference to an SVG which is included in the HTML.
 * @param {Object} props
 * @param {string} props.id ID of the SVG element to reference
 * @param {string} [props.className] Classes for the <svg> element
 */
class SVG extends React.Component {

    constructor(props) {
        super(props)
        this.container = React.createRef()
    }

    componentDidMount() {
        const { id, className } = this.props

        const svg = renderSVG(id)
        if (className) svg.setAttribute('class', className)
        this.container.current.append(svg)
    }

    render() {
        return <div className="svg-container" ref={this.container} />
    }

}

export default SVG
