/*
Utilities for global events.
*/

/**
 * Run provided function once after minimal interaction from user.
 * @param {Function} onFirstInteraction
 * @returns {boolean}
 */
export function onUserInteraction(onFirstInteraction) {
    let fired = false
    const kickTrigger = () => {
        if (fired === true) {
            return false
        }

        onFirstInteraction()

        fired = true
        return true
    }

    addUserInteractionEventListener(kickTrigger, { once: true })
}

/**
 * Add an event listener to several event types that represent a user interaction.
 * @param {function} listener
 * @param {Object} options
 */
export function addUserInteractionEventListener(listener, options) {
    document.addEventListener('focus', listener, options)
    document.addEventListener('mousemove', listener, options)
    document.addEventListener('scroll', listener, { passive: true, ...options })
    document.addEventListener('touchstart', listener, { passive: true, ...options })
}

/**
 * Remove an event listener from several event types that represent a user interaction.
 * @param {function} listener
 */
export function removeUserInteractionEventListener(listener) {
    document.removeEventListener('focus', listener)
    document.removeEventListener('mousemove', listener)
    document.removeEventListener('scroll', listener)
    document.removeEventListener('touchstart', listener)
}

/**
 * Run provided function after minimal user interaction with specific element.
 * Groups various events so that callback function fires only for one of the events.
 * @param {HTMLElement} [el] DOM element to which to add events
 * @param {Function} onInteraction
 */
export function onElementInteraction(el, onInteraction) {
    if (el.getAttribute('data-events-set')) {
        return
    }

    let isHandled = false
    const kickTrigger = () => {
        if (isHandled === true) {
            return false
        }

        onInteraction()

        isHandled = true
        setTimeout(() => {
            isHandled = false
        }, 3000)

        return true
    }

    el.addEventListener('focus', kickTrigger)
    el.addEventListener('mouseover', kickTrigger)
    el.addEventListener('mousedown', kickTrigger)
    el.addEventListener('touchstart', kickTrigger, { passive: true })

    el.setAttribute('data-events-set', '1')
}
