import Endpoint from './Endpoint'

import CountersApiService from './services/CountersApiService'
import RouteApiService from './services/RouteApiService'
import PoiApiService from './services/PoiApiService'
import PoiCategoryApiService from './services/PoiCategoryApiService'
import PoiCommentApiService from './services/PoiCommentApiService'
import SubscriptionPauseApiService from './services/SubscriptionPauseApiService'
import PoiVoteApiService from './services/PoiVoteApiService'
import UserApiService from './services/UserApiService'
import NotificationApiService from './services/NotificationApiService'
import NotificationsApiService from './services/NotificationsApiService'
import MapStyleApiService from './services/MapStyleApiService'
import DeepLinkApiService from './services/DeepLinkApiService'
import IntegrationsApiService from './services/IntegrationsApiService'

/*
Services that call the Bikemap API endpoints and prepare the data for further use.
All API paths should be defined in this file in order to parse info about all used endpoint versions.
*/

export const counters = new CountersApiService(
    new Endpoint('v4/totalcounts/'),
)

export const deepLink = new DeepLinkApiService(
    new Endpoint('v4/deeplink/'),
)

export const integrations = new IntegrationsApiService(
    new Endpoint('v5/integrations/:provider/'),
)

export const route = new RouteApiService({
    detail: new Endpoint('v4/routes/:routeId/'),
    list: new Endpoint('v4/search/'),
    favor: new Endpoint('v4/routes/:routeId/favor/'),
    unfavor: new Endpoint('v4/routes/:routeId/unfavor/'),
    spamStatus: new Endpoint('v5/routes/:routeId/spam_status/'),
    spamRoutes: new Endpoint('v5/spam/routes/'),
    markAllUserRoutesAsSpam: new Endpoint('v5/spam/mark-all-user-routes-as-spam/'),
})

export const poi = new PoiApiService({
    detail: new Endpoint('v5/poi_search/:poiId/'),
    list: new Endpoint('v5/poi_search/'),
    review: new Endpoint('v5/poi_search/:poiId/review/'),
    resetTtl: new Endpoint('v5/poi_search/:poiId/reset_ttl/'),
    markSpam: new Endpoint('v5/poi_search/:poiId/spam/'),
    merge: new Endpoint('v5/poi_search/:poiId/merge/'),
})

export const poiCategory = new PoiCategoryApiService(
    new Endpoint('v5/poi_categories/'),
)

export const poiComment = new PoiCommentApiService({
    detail: new Endpoint('v5/poi_comments/:commentId/'),
    list: new Endpoint('v5/poi_search/:poiId/comments/'),
    markSpam: new Endpoint('v5/poi_comments/:commentId/spam/'),
})

export const poiVote = new PoiVoteApiService(
    new Endpoint('v5/poi_search/:poiId/votes/')
)

export const subscriptionPause = new SubscriptionPauseApiService(
    new Endpoint('v4/subscription/pause/'),
)

export const user = new UserApiService(
    new Endpoint('v5/users/:userId/'),
)

export const notification = new NotificationApiService(
    new Endpoint('v5/webnotifications/'),
)

export const notifications = new NotificationsApiService({
    detail: new Endpoint('v5/notifications/:id/'),
    list: new Endpoint('v5/notifications/'),
})

export const mapStyle = new MapStyleApiService(
    new Endpoint('v4/map_styles/'),
)

export { Endpoint }
