import show from './show'
import { handleRedirect } from '../../utility'

/**
 * Fetches data from the server (URL provided in request options) and creates a new modal
 * mixing the provided options with the options coming from the server. Existing modals
 * are always replaced.
 * @param {String} id the modal ID
 * @param {Object} requestOptions fetch options for the request
 * @param {Object} modalOptions modal options
 * @return {Promise} promise from the fetch call
 */
export default function load(id, requestOptions, modalOptions) {
    const { url, headers } = requestOptions
    return fetch(url, {
        headers: Object.assign({
            'X-Requested-With': 'XMLHttpRequest',
        }, headers),
        credentials: 'same-origin',
    })
        .then(res => {
            if (res.ok) return res.json()
            return Promise.reject(res)
        })
        .then(handleRedirect)
        .then(res => show(id, Object.assign({}, res, modalOptions), true))
}
