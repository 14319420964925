import './PasswordField.scss'

import React, { useState, useRef, useEffect } from 'react'

import { FormField } from '../FormField'
import { translated } from '../_translation'

/**
 * @typedef {("login"|"register"|"reset"|"reset-repeat"|"old-password"|"merge")} PasswordReferer
 * Type of the password depending on where it is used.
 */

/**
 * Get custom form field name based on referer.
 * @param {PasswordReferer} referer
 * @return {string}
 */
const getName = (referer) => {
    switch (referer) {
        case 'old-password':
            return 'old_password'
        case 'reset':
            return 'new_password1'
        case 'reset-repeat':
            return 'new_password2'
        default:
            return 'password'
    }
}

/**
 * @typedef {object} PasswordValidations
 * @property {boolean} lengthOk If it contains at least 8 characters
 * @property {boolean} lowercaseOk If there is at least one lowercase letter
 * @property {boolean} uppercaseOk If there is at least one uppercase letter
 * @property {boolean} numericOk If there is at least one number
 * @property {boolean} similarOk If it doesn't contain a forbidden word (e.g. email, name)
 * @property {boolean} resetMatchOk If value is same as in sibling (reset/reset-repeat) field
 */

/**
 * Validate password value.
 * @param {string} val New value
 * @param {array} forbiddenWords List of words that can not be part of the password
 * @param {PasswordReferer} referer
 * @param {object} siblings Object containing passwords from sibling components
 * @return {PasswordValidations}
 */
const validate = (val, forbiddenWords, referer, siblings) => {
    // Get the referer of the opposite reset/reset-repeat field
    const sibling = referer === 'reset-repeat' ? 'reset' : 'reset-repeat'
    // Value of opposite password field
    const siblingVal = Object.prototype.hasOwnProperty.call(siblings, sibling) ? siblings[sibling] : null

    return {
        lengthOk: val.length >= 8,
        lowercaseOk: val !== val.toUpperCase(),
        uppercaseOk: val !== val.toLowerCase(),
        numericOk: /\d/.test(val),
        similarOk: !forbiddenWords || !forbiddenWords.some(f => val.toLowerCase().includes(f.toLowerCase())),
        resetMatchOk: val === siblingVal,
    }
}

/**
 * Password field with:
 * - toggle to text field for password preview,
 * - password validation hints.
 * @param {Object} props
 * @param {PasswordReferer} [props.referer]
 * @param {string=} [props.placeholder]
 * @param {string=} [props.autocomplete]
 * @param {boolean=} [props.autofocus]
 * @param {array=} [props.forbiddenWords]
 * @param {function=} [props.onAfterChange]
 * @param {function=} [props.getAllPasswords]
 * @param {boolean} [props.required=true]
 */
const PasswordField = ({
    gettext,
    referer,
    placeholder,
    autocomplete,
    autofocus,
    forbiddenWords,
    onAfterChange,
    getAllPasswords,
    required = true,
}) => {
    const validationLength = gettext('at least 8 characters')
    const validationLowercase = gettext('at least 1 lowercase character')
    const validationUppercase = gettext('at least 1 uppercase character')
    const validationNumeric = gettext('at least 1 numeric character')
    const validationSimilar = gettext('can\'t be similar to your name or email address')
    const validationMatch = gettext('two entered passwords match')

    /**
     * Get custom form field label based on referer.
     * @param {PasswordReferer} referer
     * @return {string}
     */
    const getLabel = (referer) => {
        switch (referer) {
            case 'register':
                return gettext('Choose a password')
            case 'old-password':
                return gettext('Old password')
            case 'reset':
                return gettext('Your new password')
            case 'reset-repeat':
                return gettext('Please repeat your new password')
            default:
                return gettext('Password')
        }
    }

    const [value, setValue] = useState('')
    const [isVisible, setIsVisible] = useState(false)
    const [areValidationsVisible, setAreValidationsVisible] = useState(false)
    const [validations, setValidations] = useState({})

    const inputRef = useRef()

    useEffect(() => {
        if (autofocus) {
            inputRef.current.focus()
        }
    }, [autofocus])

    // No need to show validation for existing password
    const isValidationNeeded = !(referer === 'login' || referer === 'old-password' || referer === 'merge')

    const name = getName(referer)

    const inputProps = {
        name,
        value,
        type: isVisible ? 'text' : 'password',
        className: referer === 'merge' ? 'mt' : null,
        required,
        placeholder: placeholder || null,
        autoComplete: autocomplete || null,
        autoFocus: !!autofocus,
        ref: inputRef,
        onFocus: e => {
            if (isValidationNeeded) {
                const siblings = typeof getAllPasswords === 'function' ? getAllPasswords() : {}
                setValidations(validate(e.target.value, forbiddenWords, referer, siblings))
            }
            setAreValidationsVisible(true)
        },
        onBlur: () => {
            setAreValidationsVisible(false)
        },
        onChange: e => {
            const val = e.target.value
            setValue(val)

            // Also run parent handler, so we can read current value from sibling component
            if (typeof onAfterChange === 'function') {
                onAfterChange(e, referer)
            }

            if (isValidationNeeded) {
                const siblings = typeof getAllPasswords === 'function' ? getAllPasswords() : {}
                setValidations(validate(val, forbiddenWords, referer, siblings))
            }
        },
    }

    const checkboxCls = isChecked => 'text-primary ' + (isChecked ? 'i-checkbox-checked' : 'i-checkbox-outline')

    return (
        <FormField
            name={name}
            label={getLabel(referer)}
            noLabel={referer === 'merge'}
            isRequired={required}
            renderElement={elementProps => (
                <div className='input-labeled-password-field'>
                    <input
                        {...elementProps}
                        {...inputProps}
                        data-testid={'password-field-' + referer}
                    />
                    <div className='toggle-trigger' onClick={() => setIsVisible(!isVisible)}>
                        <i className={'text-secondary ' + (isVisible ? 'i-invisible' : 'i-visible')}/>
                    </div>
                    {!!(isValidationNeeded && areValidationsVisible) && (
                        <div className='password-field-validation-helper'>
                            <ul>
                                <li>
                                    <i className={checkboxCls(validations.lengthOk)}/>
                                    <span>{validationLength}</span>
                                </li>
                                <li>
                                    <i className={checkboxCls(validations.lowercaseOk)}/>
                                    <span>{validationLowercase}</span>
                                </li>
                                <li>
                                    <i className={checkboxCls(validations.uppercaseOk)}/>
                                    <span>{validationUppercase}</span>
                                </li>
                                <li>
                                    <i className={checkboxCls(validations.numericOk)}/>
                                    <span>{validationNumeric}</span>
                                </li>
                                {!!(forbiddenWords && forbiddenWords.length > 0) && (
                                    <li>
                                        <i className={checkboxCls(validations.similarOk)}/>
                                        <span>{validationSimilar}</span>
                                    </li>
                                )}
                                {!!(referer === 'reset' || referer === 'reset-repeat') && (
                                    <li>
                                        <i className={checkboxCls(validations.resetMatchOk)}/>
                                        <span>{validationMatch}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        />
    )
}

export default translated(PasswordField)
