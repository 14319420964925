import Modal from './Modal'
import * as modalManager from './modalManager'

/**
 * Show the modal with the given id. If options are passed and the modal doesn't exist already, a new
 * one is created. If the options differ from the previous options, an existing modal is patched.
 * @param {String} id modal ID
 * @param {Object} [options] options for creating or patching
 * @param {boolean} [forceReplace] always create a new one instead of patching
 * @return the shown modal or false, if only the id is provided and the modal doesn't exist
 */
export default function show(id, options, forceReplace) {
    let modal = modalManager.get(id)
    if (modal && !forceReplace) {
        if (options) {
            modal.patch(options)
        }
    } else if (options) {
        modal = new Modal(id, options)
        modalManager.set(id, modal)
    } else {
        return false
    }
    modalManager.show(id)
    return modal
}
