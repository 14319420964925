import Endpoint from './Endpoint'

/**
 * @typedef {Object} ListParams
 * @property {number} [page] Page number
 * @property {string} [sort] Sort by this field
 * @property {"ASC"|"DESC"} [order] Sort order, if sort is set
 * @property {number} [pageSize] Number of results per page
 */

/**
 * @typedef {Object} ListResult
 * @property {number[]} results List of IDs on the list results page
 * @property {Object} entities Contains indices of related entities
 * @property {number} count Total number of results
 */

/**
 * Common basic functionality of all API services.
 */
class ApiService {

    /** @type {Endpoint} */
    endpoint

    /**
     * @param {Endpoint} endpoint Injected endpoint
     */
    constructor(endpoint) {
        // In case we handle all endpoints in the subclass we don't assign it here
        if (endpoint instanceof Endpoint) {
            this.endpoint = endpoint
        }
    }

    /**
     * Prepare an object with all list params in the backend format.
     * @param {ListParams & Object} params
     * @returns {Object}
     */
    _prepareListParams(params) {
        const { page, sort, order } = params
        const listParams = { page }

        // Params with different keys
        listParams.page_size = params.pageSize

        if (sort) {
            listParams.sort = sort
            if (order) {
                listParams.sort_order = order
            }
        }

        return listParams
    }

    /**
     * Map response data to an array containing the IDs and index objects with the entities.
     * @param {{ id: number }[]} data Raw results array from API call
     * @param {number} count Total number of results
     * @param {Object} entitiesConfig Provide a mapping function for each entities key
     * @param {(data) => Object} [mapResult] Mapping function for each result entry
     * @returns {ListResult}
     */
    _mapListResult(data, count, entitiesConfig, mapResult) {
        const entities = {}
        for (const key in entitiesConfig) {
            entities[key] = {}
        }

        const results = count ? data.map(result => {
            result = mapResult ? mapResult(result) : result
            for (const key in entitiesConfig) {
                const entity = entitiesConfig[key](result)
                if (entity.id !== undefined) {
                    entities[key][entity.id] = entity
                }
            }
            return result.id
        }) : []

        return { results, entities, count }
    }

    /**
     * Map response data of a filtered list endpoint to a ListResult.
     * @param {{ id: number }[]} data Raw results array from API call
     * @param {number} count Total number of results
     * @param {Object} entitiesConfig Provide a mapping function for each entities key
     * @returns {ListResult}
     */
    _mapFilteredListResult(data, count, entitiesConfig) {
        return this._mapListResult(data, count, entitiesConfig, result => result.content_object)
    }

}

export default ApiService
