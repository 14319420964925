import React from 'react'

/**
 * Select as a row of buttons.
 * @param {Object} props
 * @param {string} [props.className] Additional classes
 * @param {{value: number, name: React.ReactNode}[]} props.options Available options to select
 * @param {number} props.value Current value
 * @param {string} [props.name] Name of the form element
 * @param {(value: number, name: string) => void} props.onChange Change handler
 * @param {string} [props.id] DOM element ID
 * @param {boolean} [props.minimal] Makes it narrower and shorter
 */
const SelectRow = ({ className, options, value, name, onChange, id, minimal }) => {
    let classes = 'select-row'
    if (className) classes += ' ' + className
    if (minimal) classes += ' minimal'

    return (
        <ul className={classes} id={id}>
            {options.map((option, i) => (
                <li key={i}>
                    {(option.value === value) ? (
                        <button className="selected" aria-selected="true">
                            {option.name}
                        </button>
                    ) : (
                        <button onClick={e => onChange(option.value, name)}>
                            {option.name}
                        </button>
                    )}
                </li>
            ))}
        </ul>
    )
}

export default SelectRow
