export const HYDRATE_STATE = 'HYDRATE_STATE'

/**
 * Hydrate state from external sources. All keys in the objects will update the state.
 * @param {Object} state
 */
export const hydrateState = state => ({
    type: HYDRATE_STATE,
    state,
})
