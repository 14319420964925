// import { UNIT } from '@bikemap/js/settings'

/**
  * Helper for adding min/max as separate values to the endpoint query params.
  * @param {Object} queryParams Endpoint query params
  * @param {string} baseKey Key without "_min" or "_max"
  * @param {number[]} minMax Combined min & max values
  */
export function setMinMax(queryParams, baseKey, minMax) {
    if (minMax) {
        const [min, max] = minMax
        if (min && min > 0) {
            queryParams[baseKey + '_min'] = min
        }
        if (max && max < 100) {
            queryParams[baseKey + '_max'] = max
        }
    }
}

/**
 * Convert distance depending on the unit.
 * @param {number} distance Distance in kilometers or miles
 * @returns {number} Distance for API request
 */
function getDistance(distance) {
    // Convert miles to kilometers, so we can always send meters to the API
    // const distanceInKm = (UNIT === 'metric') ? distance : (distance * 1.609344)
    // return Math.round(distanceInKm * 1000)

    // TODO: WEB-461 Since this is now sending either meters or "milimiles" (miles * 1000),
    //  we need to coordinate with backend to find proper solution.
    //  Probably to always use meters and `outdoorish_unit` cookie - so code above
    return Math.round(distance * 1000)
}

/**
  * Helper for adding distance min/max as separate values to the API endpoint query params.
  * If min or max are not set we don't send those params,
  * but if they are - we convert received km or miles to meters.
  * @param {Object} queryParams Endpoint query params
  * @param {number[]} minMax Combined min & max values (km or miles)
  * @returns {void}
  */
export function setDistanceMinMax(queryParams, minMax) {
    if (minMax) {
        const [min, max] = minMax
        if (min && min > 0) {
            queryParams.distance_min = getDistance(min)
        }
        if (max && max > 0) {
            queryParams.distance_max = getDistance(max)
        }
    }
}

/**
 * Helper for mapping a list of 1,2,3 to 1,2,4.
 * @param {number} id
 */
export const mapToBinaryId = id => (id === 3) ? 4 : id
