import './UserInfo.scss'

import React from 'react'

/**
 * Displays the user's name and one additional info.
 * @param {Object} props
 * @param {string} props.name Display name of the user
 * @param {string} [props.info] Info line
 * @param {string} [props.className] Additional classes
 */
const UserInfo = ({ name, info, className }) => (
    <div className={'user-info' + (className ? ' ' + className : '')}>
        <div className="user-name">{name}</div>
        { !!info && <p className="info">{info}</p> }
    </div>
)

export default UserInfo
