import { HYDRATE_STATE } from '../hydration'
import {
    QUERY_LOCATION, UPDATE_LOCATION, UPDATE_GEONAME_INFO, RESET_LOCATION, UPDATE_GEONAME_SUGGESTIONS,
} from './actions'

/**
 * @typedef {Object} GeolocationState State of the geolocation integration
 *
 * @property {number} [location] ID of geoname to search within
 * @property {string} [geoname] Name of the current location
 * @property {string} [geonameQuery] Query against the geoname autocomplete endpoint
 * @property {import("../../utility/network/autocomplete").GeonameSuggestion[]} geonameSuggestions
 * @property {import('@bikemap/js/map').LngLat} [center] Center of the location
 */

/** @type {GeolocationState} */
const initialState = {
    location: null,
    geoname: null,
    geonameQuery: '',
    geonameSuggestions: [],
    center: null,
}

/**
 * Updates the state according to a dispatched action.
 * @param {GeolocationState} state Current state
 * @param {Object} action Handled action
 * @returns {GeolocationState} New state object
 */
export default function geonameReducer(state = initialState, action) {
    switch (action.type) {
        case HYDRATE_STATE:
            return {
                ...state,
                ...action.state,
            }

        case QUERY_LOCATION:
            return {
                ...state,
                geonameQuery: action.geonameQuery,
            }

        case UPDATE_LOCATION:
            return {
                ...state,
                location: action.geonameId,
                geonameQuery: '',
                geonameSuggestions: [],
                center: action.center,
            }

        case UPDATE_GEONAME_INFO:
            return {
                ...state,
                geoname: action.geoname,
            }

        case RESET_LOCATION:
            return {
                ...state,
                location: null,
                geoname: null,
                geonameQuery: '',
                center: null,
                geonameSuggestions: [],
            }

        case UPDATE_GEONAME_SUGGESTIONS:
            return {
                ...state,
                geonameSuggestions: action.suggestions,
            }

        default:
            return state
    }
}
