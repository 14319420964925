import { BLOG_CMS_API_URL, LANGUAGE } from '@bikemap/js/settings'
import { logError } from '@bikemap/js/utility/helpers.js'

/**
 * Fetch page content from a specific page on the Bikemap blog as an interim solution until there is a general CMS.
 * Any element on the page with a class `js-page-content` will be filled with content of the page with the ID
 * specified in the `data-page-id-en` or `data-page-id-de` attribute.
 */
export function initPageContent() {
    document.querySelectorAll('.js-page-content').forEach(container => {
        const pageId = container.getAttribute(LANGUAGE === 'de' ? 'data-page-id-de' : 'data-page-id-en')
        if (pageId) {
            insertContent(container, pageId)
        } else {
            logError('No page id specified to fetch content from')
        }
    })
}

/**
 * @param {HTMLElement} container
 * @param {number} pageId
 */
async function insertContent(container, pageId) {
    const res = await fetch(BLOG_CMS_API_URL.replace(':id', pageId))
    if (!res.ok) {
        logError('Could not fetch content from page id ' + pageId)
        return
    }
    const json = await res.json()
    if (json.content && json.content.rendered) {
        container.innerHTML = json.content.rendered
    } else {
        logError('No content found for page id ' + pageId)
        if (json.guid && json.guid.rendered) {
            container.innerHTML = 'Could not load content. Try <a href="' + json.guid.rendered + '">the original page</a>.'
        }
    }
}
