import { RouteDebugData } from '../../entities'

/**
 * @param {number} routeId ID of the route it belongs to
 * @param {Object} res Response data
 * @returns {RouteDebugData}
 */
export default function createRouteDebugDataFromResponse(routeId, res) {
    const debugData = new RouteDebugData

    debugData.id = routeId
    debugData.score = res.score
    debugData.backlinksCount = res.backlinks_count
    debugData.descriptionLength = res.description_length
    debugData.commentsCount = res.comments_count
    debugData.ridersCount = res.riders_count
    debugData.ratingsCount = res.rating_count
    debugData.flaggedCount = res.flagged_count
    debugData.isShortRoute = !!res.short_route

    return debugData
}
