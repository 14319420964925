import React from 'react'

import { SelectDropdown } from '../SelectDropdown'
import { FormField } from '../FormField'

/**
 * @param {Object} props
 * @param {string} [props.className]
 * @param {import("../DropdownList").DropdownOptionValues[]} props.options
 * @param {number|string} props.value
 * @param {string} props.name
 * @param {string} props.label
 * @param {(value: number|string, name?: string) => void} props.onChange
 * @param {boolean} [props.noLabel]
 */
const SelectDropdownField = ({
    value, name, label, onChange, noLabel, className, options,
}) => (
    <FormField className={'select-field' + (className ? ' ' + className : '')} name={name} label={label}
        noLabel={noLabel} renderElement={elementProps => (
            <SelectDropdown {...elementProps} options={options} value={value} onChange={onChange} />
        )} />
)

export default SelectDropdownField
