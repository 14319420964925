export const QUERY_LOCATION = 'QUERY_LOCATION'

/**
 * Query a location.
 * @param {string} [geonameQuery] Query string for geoname autocomplete
 */
export const queryLocation = geonameQuery => ({
    type: QUERY_LOCATION,
    geonameQuery,
})

export const UPDATE_LOCATION = 'UPDATE_LOCATION'

/**
 * Update location state.
 * @param {number} geonameId ID of the geoname
 * @param {import('@bikemap/js/map').LngLat} center Center of the location
 */
export const updateLocation = (geonameId, center) => ({
    type: UPDATE_LOCATION,
    geonameId,
    center,
})

export const RESET_LOCATION = 'RESET_LOCATION'

/**
 * Reset location filter and querytext.
 */
export const resetLocation = () => ({
    type: RESET_LOCATION,
})

export const UPDATE_GEONAME_INFO = 'UPDATE_GEONAME_INFO'

/**
 * Update info about location geoname.
 * @param {string} geoname Name of the location
 * @param {import('@bikemap/js/map').LngLat|import('@bikemap/js/map').LngLatBounds} coordinates Bounds of the area or center coordinates
 */
export const updateGeonameInfo = (geoname, coordinates) => ({
    type: UPDATE_GEONAME_INFO,
    geoname,
    bounds: (coordinates.length === 4) ? coordinates : null,
    center: (coordinates.length === 2) ? coordinates : null,
})

export const UPDATE_GEONAME_SUGGESTIONS = 'UPDATE_GEONAME_SUGGESTIONS'

/**
 * Update info about location geoname.
 * @param {import('../../utility/network/autocomplete').GeonameSuggestion[]} suggestions List of suggestions
 */
export const updateGeonameSuggestions = suggestions => ({
    type: UPDATE_GEONAME_SUGGESTIONS,
    suggestions,
})
