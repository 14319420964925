import './SelectDropdown.scss'

import React from 'react'

import { Dropdown, DropdownItem, DropdownList, DropdownOptionItem } from '../Dropdown'
import SelectDropdownButton from './SelectDropdownButton.jsx'

/**
 * @typedef {Object} TriggerProps
 * @property {() => void} onClick
 * @property {React.ReactNode} children
 */

/**
 * A styled pseudo dropdown select field compatible with forms.
 * @param {Object} props
 * @param {string} [props.id]
 * @param {string} [props.className]
 * @param {import("../DropdownList").DropdownOptionValues[]} props.options
 * @param {number|string} props.value
 * @param {string} [props.name]
 * @param {(value: number|string, name?: string) => void} props.onChange
 * @param {(props: TriggerProps) => React.ReactNode} [props.renderTrigger]
 * @param {boolean} [props.withPlaceholder] Selected option is rendered on top as placeholder
 */
const SelectDropdown = ({ id, className, options, value, name, onChange, renderTrigger, withPlaceholder }) => {
    const selectedLabel = options.find(option => option.value === value).label

    const dropdownProps = {
        className: 'select-dropdown' + (className ? ' ' + className : ''),
        renderTrigger: props => renderTrigger ? renderTrigger({
            ...props,
            children: selectedLabel,
        }) : (
            <SelectDropdownButton {...props}>{selectedLabel}</SelectDropdownButton>
        ),
        renderList: onClose => {
            const handleClick = value => {
                onChange(value, name)
                onClose()
            }

            return (
                <DropdownList>
                    {withPlaceholder && (
                        <DropdownItem>
                            <button onClick={onClose} className="dropdown-placeholder">
                                {selectedLabel}
                            </button>
                        </DropdownItem>
                    )}
                    {options.map((option, i) => (withPlaceholder && option.value === value) ? null : (
                        <DropdownOptionItem key={i} {...option} onClick={handleClick} selected={option.value === value} />
                    ))}
                </DropdownList>
            )
        },
    }

    return (
        <Dropdown {...dropdownProps}>
            <input readOnly type='hidden' id={id} name={name} value={value} />
        </Dropdown>
    )
}

export default SelectDropdown
